import { useAuth0 } from '@auth0/auth0-react';

import { getEnv } from '@app/config/env';

import getOrganizationMock from './mock';
import getOrganizationService from './service';
import {
  Invitation,
  InvitationResponse,
  Organization,
  OrganizationApi,
  UserOrganizationsResponse,
} from './types';

const useOrganizationApi = (): OrganizationApi => {
  const { getAccessTokenSilently } = useAuth0();

  if (getEnv().mockApiCalls) {
    return getOrganizationMock();
  }

  return getOrganizationService({ getAccessTokenSilently });
};

export const organizationResponseToOrganization = (
  organizationResponse: UserOrganizationsResponse
): Organization => ({
  displayName: organizationResponse.display_name,
  domain: organizationResponse.domain,
  id: organizationResponse.id,
  name: organizationResponse.name,
  metadata: organizationResponse.metadata,
  picture: organizationResponse.branding?.logo_url,
});

export const invitationResponseToInvitation = (
  invitationResponse: InvitationResponse
): Invitation => ({
  expiresAt: invitationResponse.auth0_invitation_object.expires_at,
  id: invitationResponse.id,
  invitee: {
    email: invitationResponse.invitee.email,
    id: invitationResponse.invitee.user_id,
    userHasAccount: invitationResponse.invitee.user_has_account,
  },
  inviter: {
    email: invitationResponse.inviter.email,
    id: invitationResponse.inviter.user_id,
    name: invitationResponse.auth0_invitation_object.inviter.name,
  },
  organization: {
    displayName:
      invitationResponse.auth0_invitation_object.organization_display_name,
    id: invitationResponse.auth0_invitation_object.organization_id,
  },
  url: invitationResponse.auth0_invitation_object.invitation_url,
});

export default useOrganizationApi;
