import { TextField } from '@mui/material';
import { KeyboardEventHandler, forwardRef, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useUserInputContext } from '@pages/group-members/hooks/useUserInputContext';

import UserInputEmailChip from './user-input-email-chip';

interface UserInputProps {
  value: Array<{ label: string; id?: string }>;
  onChange: (newValue: Array<{ label: string; id?: string }>) => void;
  onUserFromOutside: (userHasAccount: boolean, label: string) => void;
}

const UserInput = forwardRef<HTMLInputElement, UserInputProps>(
  ({ onChange, value, onUserFromOutside }, ref) => {
    const [inputValue, setInputValue] = useState('');
    const [focused, setFocused] = useState(false);
    const [error, setError] = useState(false);

    const { setIsAvailable } = useUserInputContext();

    const { t } = useTranslation();

    const handleDelete = (userToDelete: string) => {
      onChange(value.filter(({ label }) => label !== userToDelete));
    };

    const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
      const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

      if (event.key === 'Enter' && inputValue.trim()) {
        event.preventDefault();
        if (
          emailRegex.test(inputValue.trim()) &&
          value.every(({ label }) => label !== inputValue.trim())
        ) {
          setIsAvailable(false);
          onChange([...value, { label: inputValue.trim() }]);
          setInputValue('');
          setError(false);
        } else {
          setError(true);
        }
      } else if (event.key === 'Backspace' && inputValue === '') {
        event.preventDefault();
        setInputValue(value.at(-1)?.label ?? '');
        onChange(value.slice(0, -1));
      }
    };

    const handleLoad = useCallback((label: string, id: string) => {
      onChange(value.filter((user) => user.label !== label));
      onChange([...value, { label, id }]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <TextField
        ref={ref}
        label={t('groups.newGroupModal.users.label')}
        helperText={t('groups.newGroupModal.users.message')}
        variant="standard"
        error={error}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        value={inputValue}
        InputLabelProps={{
          shrink: focused || !!inputValue || value.length > 0,
        }}
        className="!mb-md flex-wrap"
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        InputProps={{
          startAdornment: (
            <div
              className="my-sm flex flex-1 flex-wrap"
              data-testid="users-input-chips"
            >
              {value.map(({ label }) => (
                <UserInputEmailChip
                  key={label}
                  label={label}
                  onDelete={handleDelete}
                  onUserFromOutside={onUserFromOutside}
                  onLoad={(id) => handleLoad(label, id)}
                />
              ))}
            </div>
          ),
        }}
      />
    );
  }
);

export default UserInput;
