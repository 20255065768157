import { useAuth0 } from '@auth0/auth0-react';
import { Button, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { ChangeEventHandler, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import useAlert from '@app/hooks/useAlert';
import useUser from '@app/hooks/useUser';
import TrashIcon from '@assets/shared/trash.svg?react';
import UserAvatar from '@components/user-avatar';

import useRemoveUserPicture from '../hooks/useRemoveUserPicture';
import useUpdateUserPicture from '../hooks/useUpdateUserPicture';

const ProfilePhoto = () => {
  const { t } = useTranslation();
  const { user: auth0User } = useAuth0();
  const { data: user } = useUser(auth0User?.sub);
  const queryClient = useQueryClient();
  const { mutateAsync: updateUserPicture } = useUpdateUserPicture();
  const { mutateAsync: removePicture } = useRemoveUserPicture();
  const { Alert, display } = useAlert();
  const profilePicture = useRef<HTMLInputElement>(null);

  if (!user) {
    return null;
  }

  const handleUserUploadPicture: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.files) {
      [...e.target.files].forEach((file) => {
        void (async () => {
          try {
            await updateUserPicture({ picture: file, userId: user.user_id });
            await queryClient.invalidateQueries({
              queryKey: ['user', user.user_id],
            });
          } catch (er) {
            display();
          }
        })();
      });
    }
  };

  const handleRemoveUserPicture = async () => {
    try {
      await removePicture({ userId: user.user_id });
      await queryClient.invalidateQueries({
        queryKey: ['user', user.user_id],
      });
    } catch (e) {
      display();
    }
  };

  return (
    <div className="my-lg">
      <input
        type="file"
        id="user-profile"
        onChange={handleUserUploadPicture}
        ref={profilePicture}
        className="hidden"
        accept="image/*"
        data-testid="user-profile"
      />
      <Typography variant="h2" className="!text-xl !font-normal">
        {t('profile.profilePhoto.title')}
      </Typography>
      <div className="mt-sm gap-x-md flex items-center">
        <UserAvatar className="!h-24 !w-24" user={user} />
        <div className="flex flex-col gap-y-2">
          <Button
            variant="contained"
            onClick={() => profilePicture.current?.click()}
          >
            {t('profile.profilePhoto.change')}
          </Button>
          <Button
            variant="outlined"
            startIcon={
              <TrashIcon className="text-primary" width={12} height={12} />
            }
            onClick={handleRemoveUserPicture}
          >
            {t('profile.profilePhoto.remove')}
          </Button>
        </div>
      </div>
      <p className="mt-sm text-sm font-medium tracking-wider text-black/60">
        <Trans>
          Recommended photo size is 256 <>&times;</> 256 px
        </Trans>
      </p>
      <Alert severity="error">{t('profile.profilePhoto.error')}</Alert>
    </div>
  );
};

export default ProfilePhoto;
