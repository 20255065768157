import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import NotFoundImage from '@assets/error/not-found.svg?react';
import ErrorHelpReport from '@components/error-help-report';
import Logo from '@components/logo';

const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="py-screen flex flex-1 flex-col">
      <header className="flex justify-center">
        <Logo />
      </header>
      <div className="gap-x-lg py-lg flex flex-1 items-center justify-between px-[max(15vw,40px)]">
        <div className="flex-1">
          <h1 className="mb-lg text-5xl font-bold">
            {t('error.notFound.title')}
          </h1>
          <p className="text-3xl font-medium">{t('error.notFound.text')}</p>
          <p className="mt-lg text-lg font-bold">
            {t('error.notFound.possibleReasons.title')}
          </p>
          <ol className="mt-sm list-inside list-disc">
            <li className="text-lg">
              {t('error.notFound.possibleReasons.one')}
            </li>
            <li className="text-lg">
              {t('error.notFound.possibleReasons.two')}
            </li>
          </ol>
          <div className="my-lg gap-x-sm flex">
            <Button
              variant="contained"
              onClick={() => navigate('/files')}
              className="!w-32"
            >
              {t('error.notFound.buttons.home')}
            </Button>
          </div>
          <ErrorHelpReport />
        </div>
        <div className="flex flex-1 flex-col items-end">
          <NotFoundImage width={380} height={380} />
        </div>
      </div>
    </div>
  );
};

export default NotFound;
