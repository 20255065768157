import { useAuth0 } from '@auth0/auth0-react';
import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router';

const ProtectedRoute = ({ children }: PropsWithChildren) => {
  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated) {
    return <Navigate to="/pre-login" replace />;
  }

  return children;
};

export default ProtectedRoute;
