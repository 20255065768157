import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import EmptyIcon from '@assets/tags/empty.svg?react';

const Empty = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <EmptyIcon />
      <p className="mt-md text-lg">{t('tags.empty.text')}</p>
      <p className="mb-lg text-2xl font-medium">{t('tags.empty.title')}</p>
      <Link to="/files">
        <Button variant="outlined" className="!px-lg">
          {t('tags.empty.button')}
        </Button>
      </Link>
    </div>
  );
};

export default Empty;
