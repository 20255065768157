import { Box, Button, Popover, PopoverProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface DeleteTagPopoverProps extends PopoverProps {
  onDelete: () => void;
}

const DeleteTagPopover = ({ onDelete, ...props }: DeleteTagPopoverProps) => {
  const { t } = useTranslation();

  const handleDelete = () => {
    props.onClose?.({}, 'escapeKeyDown');
    onDelete();
  };

  return (
    <Popover {...props}>
      <Box className="p-md px-lg">
        <p className="mb-sm text-lg">
          {t('tags.tagDetails.dangerZone.deleteTag.popover.title')}
        </p>
        <div className="gap-x-sm flex">
          <Button
            variant="outlined"
            onClick={(e) => props.onClose?.(e, 'escapeKeyDown')}
          >
            {t('tags.tagDetails.dangerZone.deleteTag.popover.cancel')}
          </Button>
          <Button variant="contained" color="error" onClick={handleDelete}>
            {t('tags.tagDetails.dangerZone.deleteTag.popover.delete')}
          </Button>
        </div>
      </Box>
    </Popover>
  );
};

export default DeleteTagPopover;
