import { UserResponse } from '@app/services/users/types';
import { User } from '@filot/types/user';

export const userMock: User = {
  nickname: 'mockedUser123',
  name: 'John Doe',
  picture: 'https://example.com/profile-pic.jpg',
  updated_at: '2024-05-14T12:00:00Z',
  iss: 'https://auth.example.com',
  aud: 'example-client-id',
  iat: 1620976463,
  exp: 1620980063,
  sub: 'user-subject-id',
  sid: 'session-id-123',
};

export const userCompleteMock: UserResponse = {
  created_at: '2024-02-15T13:49:14.067Z',
  email: 'mock.user@filot.io',
  email_verified: false,
  identities: [
    {
      connection: 'Username-Password-Authentication',
      provider: 'auth0',
      user_id: 'mockUserId',
      isSocial: false,
    },
  ],
  name: 'Mr Mock User',
  nickname: 'mock.user',
  picture: 'https://i0.wp.com/cdn.auth0.com/avatars/mu.png?',
  updated_at: '2024-08-19T03:07:31.087Z',
  user_id: 'auth0|mockUserId',
  multifactor: ['guardian'],
  multifactor_last_modified: '2024-02-15T13:49:32.922Z',
  user_metadata: {
    roles: ['non-organized-user', 'user'],
  },
  app_metadata: {
    authorization: {
      groups: [],
      roles: [],
      permissions: [],
    },
    roles: ['non-organized-user', 'user'],
    license_type: 'REGULAR',
    max_storage: 400,
    role_id: 'role id',
    storage_usage: 100,
  },
  last_ip: 'last:mock:ip',
  last_login: '2024-08-19T03:07:28.609Z',
  logins_count: 262,
  family_name: 'User',
  given_name: 'Mr Mock',
  idp_tenant_domain: '',
  organization: {
    branding: null,
    display_name: 'Organization',
    enabled_connections: [],
    id: 'org id',
    metadata: {
      current_number_of_members: '3',
      max_organization_members: '3',
    },
    name: 'organiazztion',
  },
  role: { description: 'role', id: 'role id', name: 'Role' },
};

export const user2CompleteMock: UserResponse = {
  created_at: '2024-02-15T13:49:14.067Z',
  email: 'mock.user2@filot.io',
  email_verified: false,
  identities: [
    {
      connection: 'Username-Password-Authentication',
      provider: 'auth0',
      user_id: 'mockUserId2',
      isSocial: false,
    },
  ],
  name: 'Mr Mock User 2',
  nickname: 'mock.user2',
  picture: 'https://i0.wp.com/cdn.auth0.com/avatars/mu.png?',
  updated_at: '2024-08-19T03:07:31.087Z',
  user_id: 'auth0|mockUserId2',
  multifactor: ['guardian'],
  multifactor_last_modified: '2024-02-15T13:49:32.922Z',
  user_metadata: {
    roles: ['non-organized-user', 'user'],
  },
  app_metadata: {
    authorization: {
      groups: [],
      roles: [],
      permissions: [],
    },
    roles: ['non-organized-user', 'user'],
    license_type: 'REGULAR',
    max_storage: 400,
    role_id: 'role id',
    storage_usage: 100,
  },
  last_ip: 'last:mock:ip',
  last_login: '2024-08-19T03:07:28.609Z',
  logins_count: 262,
  family_name: 'User',
  given_name: 'Mr Mock 2',
  idp_tenant_domain: '',
  organization: {
    branding: null,
    display_name: 'Organization',
    enabled_connections: [],
    id: 'org id',
    metadata: {
      current_number_of_members: '3',
      max_organization_members: '3',
    },
    name: 'organiazztion',
  },
  role: { description: 'role', id: 'role id', name: 'Role' },
};
