import { useMutation } from '@tanstack/react-query';

import useFilesApi from '@app/services/files/useFilesApi';
import useGroupsApi from '@app/services/groups/useGroupsApi';

const useRenameFile = (groupId?: string) => {
  const { renameFile } = useFilesApi();
  const { renameFile: groupRenameFile } = useGroupsApi();

  const mutation = useMutation<
    void,
    Error,
    { fileId: string; newName: string; newPath: string }
  >({
    mutationFn: (variables) => {
      if (groupId) {
        return groupRenameFile({ groupId, ...variables });
      }

      return renameFile(variables);
    },
    mutationKey: ['renameFile'],
  });

  return mutation;
};

export default useRenameFile;
