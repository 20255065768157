import { Button } from '@mui/material';
import { RefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Group } from '@app/services/groups/types';
import BackArrow from '@assets/groups/back_arrow.svg?react';
import PencilIcon from '@assets/groups/edit.svg?react';
import AddIcon from '@assets/shared/add.svg?react';
import PressableIcon from '@components/pressable-icon';
import useGroupPermissions from '@pages/groups/hooks/useGroupPermissions';

import GroupFilesBreadcrumb from './group-files-breadcrumb';

interface GroupFilesHeaderProps {
  group: Group;
  onBack: () => void;
  onBulkEdit: () => void;
  onAddFilesClick: () => void;
  groupsPageRef: RefObject<HTMLDivElement>;
}

const GroupFilesHeader = ({
  group,
  onBack,
  onBulkEdit,
  onAddFilesClick,
  groupsPageRef,
}: GroupFilesHeaderProps) => {
  const { t } = useTranslation();
  const { canAddFiles, canEditFiles } = useGroupPermissions({ group });
  const buttonsRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className="mb-md flex justify-between">
      <div className="flex w-full items-center">
        <PressableIcon
          onClick={onBack}
          icon={<BackArrow title="back" className="text-black" />}
        />
        <GroupFilesBreadcrumb
          groupsPageRef={groupsPageRef}
          buttonsRef={buttonsRef}
          group={group}
        />
      </div>
      <div
        ref={buttonsRef}
        className="gap-x-sm flex shrink-0 items-center justify-end"
      >
        {canAddFiles() ? (
          <Button
            onClick={onAddFilesClick}
            variant="contained"
            startIcon={
              <AddIcon className="text-white" width={12} height={12} />
            }
            className="!px-md !shadow-none"
          >
            {t('groups.groupFiles.addFiles')}
          </Button>
        ) : null}
        {canEditFiles() ? (
          <Button
            onClick={onBulkEdit}
            variant="outlined"
            startIcon={
              <PencilIcon className="text-primary" width={16} height={16} />
            }
            className="!px-md"
          >
            {t('groups.groupFiles.bulkEdit')}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default GroupFilesHeader;
