import { getEnv } from '@app/config/env';
import { getInstance, getRangedAccessToken } from '@app/utils/http-instance';
import { ServiceOptions, SuccessfulResponse } from '@filot/types/api';

import getUserMock from './mock';
import { UsersApi, UserResponse } from './types';

const getUsersService = ({
  url,
  getAccessTokenSilently,
}: ServiceOptions): UsersApi => {
  const {
    filot: { users },
  } = getEnv();

  return {
    ...getUserMock(),
    get: async (id) => {
      const accessToken = await getRangedAccessToken(
        'users',
        getAccessTokenSilently
      );

      const instance = getInstance();

      const { data } = await instance.get<SuccessfulResponse<UserResponse>>(
        `${url ?? users.domain}/api/v1/users/${id}/get`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return data;
    },

    getUserByEmail: async (email) => {
      const accessToken = await getRangedAccessToken(
        'users',
        getAccessTokenSilently
      );
      const instance = getInstance();

      const { data } = await instance.get<SuccessfulResponse<UserResponse>>(
        `${url ?? users.domain}/api/v1/users/email/${email}/get`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return data;
    },

    update: async ({ name, phoneNumber, id }) => {
      const accessToken = await getRangedAccessToken(
        'users',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.put(
        `${url ?? users.domain}/api/v1/users/${id}/modify`,
        {
          name,
          user_metadata: {
            phone_number: phoneNumber,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    postLogin: async () => {
      const accessToken = await getRangedAccessToken(
        'users',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.post(
        `${url ?? users.domain}/api/v1/users/post_login`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    removePicture: async ({ userId }) => {
      const accessToken = await getRangedAccessToken(
        'users',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.put(
        `${url ?? users.domain}/api/v1/users/${userId}/modify`,
        { picture: null },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },
  };
};

export default getUsersService;
