import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';
import { twMerge } from 'tailwind-merge';

interface ErrorHelpReportProps {
  className?: string;
}

const ErrorHelpReport = ({ className }: ErrorHelpReportProps) => {
  const { t } = useTranslation();

  const textClasses = useMemo(
    () => twMerge('text-xs font-light underline', className),
    [className]
  );

  return (
    <Link
      to="https://filot.io/report-a-bug/"
      target="_blank"
      className={textClasses}
    >
      {t('components.errorHelpReport.report')}
    </Link>
  );
};

export default ErrorHelpReport;
