import { PropsWithChildren, ReactNode, useState } from 'react';

interface ChangeOnHoverProps {
  hoverElement: ReactNode;
}

const ChangeOnHover = ({
  children,
  hoverElement,
}: PropsWithChildren<ChangeOnHoverProps>) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <span
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {isHovering ? hoverElement : children}
    </span>
  );
};

export default ChangeOnHover;
