import { useQuery } from '@tanstack/react-query';

import useRootFolderApi, {
  fileResponseToFile,
} from '@app/services/files/useFilesApi';

const useRootFolder = () => {
  const { getRootContent } = useRootFolderApi();

  const query = useQuery({
    queryFn: () => getRootContent(),
    queryKey: ['root-folder'],
    select: (data) => (!data ? null : fileResponseToFile(data.data)),
    staleTime: Infinity,
  });

  return query;
};

export default useRootFolder;
