import { Checkbox, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import { useCallback, useMemo, useRef } from 'react';
import { twMerge } from 'tailwind-merge';

import useAlert from '@app/hooks/useAlert';
import useClickAndDrag from '@app/hooks/useClickAndDrag';
import useDoubleClickHandler from '@app/hooks/useDoubleClickHandler';
import useSyncFile from '@app/hooks/useSyncFile';
import { File } from '@app/services/files/types';
import FileContextMenu from '@components/file-card-context-menu';
import MoveableToFolder from '@components/moveable-to-folder';
import { MoveableElements } from '@pages/files/hooks/useMoveableToFolder';

import FileIcon from './file-icon';

interface FileCardProps<T> {
  file: T;
  onFileMove?: (elements: MoveableElements) => void;
  onFolderHover?: (elements: MoveableElements) => void;
  onFileClick?: (file: T) => void;
  onFileDoubleClick?: (file: T) => void;
  selected?: boolean;
  tourId?: string;
}

const FileCard = <T extends File>({
  file,
  onFileMove,
  onFolderHover,
  onFileClick,
  onFileDoubleClick,
  selected = false,
  tourId,
}: FileCardProps<T>) => {
  const { handleMouseDown, handleMouseUp, handleClick } = useClickAndDrag();
  const syncMutationControls = useSyncFile();
  const syncAlertControls = useAlert({ timeout: null });

  const fileCardRef = useRef<HTMLDivElement | null>(null);

  const { fileName, type, id } = file;

  const cardStyle = useMemo(
    () =>
      twMerge(
        'w-full cursor-pointer bg-gray-100 p-sm transition duration-300 hover:bg-gray-300 flex flex-1 items-center',
        selected ? 'bg-slate-200 hover:bg-slate-300' : ''
      ),
    [selected]
  );

  const handleClickEvent = useCallback(() => {
    onFileClick?.(file);
  }, [file, onFileClick]);

  const handleDoubleClickEvent = useCallback(() => {
    onFileDoubleClick?.(file);
  }, [file, onFileDoubleClick]);

  const [onClick, onDoubleClick] = useDoubleClickHandler({
    delay: 280,
    onClick: handleClick(handleClickEvent),
    onDoubleClick: handleClick(handleDoubleClickEvent),
  });

  const rawCard = useMemo(
    () => (
      <Card
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        data-testid="file-card"
        data-tour={tourId}
        title={fileName}
        ref={fileCardRef}
        className="fileCard flex h-14 flex-1"
      >
        <div className={cardStyle}>
          <span>
            {selected ? (
              <Checkbox className="!m-0 !p-0" checked size="small" />
            ) : (
              <FileIcon type={type} width={24} height={24} />
            )}
          </span>
          <Typography className="!ml-2 overflow-hidden text-ellipsis whitespace-nowrap">
            {fileName}
          </Typography>
        </div>
      </Card>
    ),
    [cardStyle, fileName, onClick, onDoubleClick, selected, tourId, type]
  );

  return (
    <>
      {onFolderHover && onFileMove ? (
        <span onClick={onClick} onDoubleClick={onDoubleClick}>
          <MoveableToFolder
            onFolderHover={onFolderHover}
            onFileMove={onFileMove}
            elementId={id}
            elementName={fileName}
            onDragStart={handleMouseDown}
            onDragEnd={handleMouseUp}
          >
            {rawCard}
          </MoveableToFolder>
        </span>
      ) : (
        rawCard
      )}
      <FileContextMenu
        file={file}
        anchorElement={fileCardRef.current}
        syncAlertControls={syncAlertControls}
        syncMutationControls={syncMutationControls}
        onPreview={handleDoubleClickEvent}
      />
    </>
  );
};

export default FileCard;
