import { AvatarGroup, TableCell, TableRow } from '@mui/material';

import useFileDetails from '@app/hooks/useFileDetails';
import FileIcon from '@components/file-icon';
import UserAvatar from '@components/user-avatar';

interface TagDetailsFilesTableRowProps {
  fileId: string;
  onClick: () => void;
}

const TagDetailsFilesTableRow = ({
  fileId,
  onClick,
}: TagDetailsFilesTableRowProps) => {
  const { data } = useFileDetails(fileId);

  if (data) {
    return (
      <TableRow onClick={onClick} className="cursor-pointer">
        <TableCell className="!py-2">
          <div className="gap-x-sm flex items-center">
            <FileIcon type={data.type} width={32} height={32} />
            <p className="text-base">{data.fileName}</p>
          </div>
        </TableCell>
        <TableCell className="!py-2">
          <UserAvatar id={data.owner} />
        </TableCell>
        <TableCell className="!py-2">
          <div className="flex items-center">
            <AvatarGroup max={4}>
              {Object.keys(data.users).map((userId) => (
                <UserAvatar key={userId} id={userId} />
              ))}
            </AvatarGroup>
          </div>
        </TableCell>
      </TableRow>
    );
  }
};

export default TagDetailsFilesTableRow;
