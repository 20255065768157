import { useMutation } from '@tanstack/react-query';

import useFilesApi from '@app/services/files/useFilesApi';
import useGroupsApi from '@app/services/groups/useGroupsApi';

const useMoveFolderToTrash = (groupId?: string) => {
  const { moveFolderToTrash } = useFilesApi();
  const { moveFolderToTrash: groupMoveFolderToTrash } = useGroupsApi();

  const mutation = useMutation<void, Error, string>({
    mutationFn: (variables) => {
      if (groupId) {
        return groupMoveFolderToTrash(groupId, variables);
      }

      return moveFolderToTrash(variables);
    },
    mutationKey: ['folder-move-to-trash'],
  });

  return mutation;
};

export default useMoveFolderToTrash;
