import { useAuth0 } from '@auth0/auth0-react';
import {
  createBrowserRouter,
  createHashRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router';

import { Providers } from '@app/App';
import ProtectedRoute from '@app/protected-route';
import { isRunningOnElectron } from '@app/utils/environment';
import GlobalAlert from '@components/global-alert';
import Loading from '@components/loading';
import PostLogin from '@components/post-login';
import StoreParams from '@components/store-params';

import LoadLogin from './auth/load-login';
import Login from './auth/login';
import Prelogin from './auth/prelogin';
import NotFound from './error/components/not-found';
import Error from './error/error';
import FileVersions from './file-versions/file-versions';
import Files from './files/files';
import GroupAddFiles from './group-add-files/group-add-files';
// import GroupBulkEditing from './group-bulk-editing/group-bulk-editing';
import GroupDetails from './group-details/group-details';
import GroupFiles from './group-files/group-files';
import GroupMembers from './group-members/group-members';
import Groups from './groups/groups';
import Layout from './layout/layout';
import LoginRedirect from './login-redirect/login-redirect';
import Organizations from './organizations/organizations';
import OrganizationsBulkInvite from './organizations-bulk-inviting/organizations-bulk-inviting';
import Profile from './profile/profile';
import TagDetails from './tag-details/tag-details';
import Tags from './tags/tags';
import Trash from './trash/trash';

const routes = (
  <>
    <Route
      path={'/login-redirect'}
      element={<LoginRedirect />}
      errorElement={<Error />}
    />
    <Route
      path={'/load-login'}
      element={<LoadLogin />}
      errorElement={<Error />}
    />
    <Route path={'/login'} element={<Login />} errorElement={<Error />} />
    <Route
      path={'/pre-login'}
      element={<Prelogin />}
      errorElement={<Error />}
    />
    <Route
      element={
        <ProtectedRoute>
          <Layout />
          <GlobalAlert />
        </ProtectedRoute>
      }
      errorElement={<Error />}
    >
      <Route index element={<Files />} />
      <Route path={'files/:folderId'} element={<Files />} />
      <Route path={'files'} element={<Files />} />
      <Route path={'organizations'}>
        <Route index element={<Organizations />} />
        <Route path={'bulk-invite'} element={<OrganizationsBulkInvite />} />
      </Route>
      <Route path={'groups'}>
        <Route index element={<Groups />} />
        <Route path={':groupId'}>
          <Route index element={<GroupDetails />} />
          <Route path={'files/*'} element={<GroupFiles />} />
          <Route path={'files/add'} element={<GroupAddFiles />} />
          <Route path={'members'} element={<GroupMembers />} />
        </Route>
      </Route>
      <Route path={'tags'}>
        <Route index element={<Tags />} />
        <Route path={':tagId'} element={<TagDetails />} />
      </Route>
      <Route path={'file/:fileId/versions'} element={<FileVersions />} />
      <Route path={'trash'} element={<Trash />} />
    </Route>
    <Route
      path={'/profile'}
      element={
        <ProtectedRoute>
          <Profile />
        </ProtectedRoute>
      }
      errorElement={<Error />}
    />
    <Route
      path={'*'}
      element={
        <ProtectedRoute>
          <NotFound />
        </ProtectedRoute>
      }
      errorElement={<Error />}
    />
  </>
);

const MainAppRouter = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <StoreParams>
          <Loading />
        </StoreParams>
      </div>
    );
  }

  if (isRunningOnElectron()) {
    return (
      <Providers>
        <PostLogin />
        <RouterProvider
          router={createHashRouter(createRoutesFromElements(routes))}
        />
      </Providers>
    );
  }

  return (
    <Providers>
      <PostLogin />
      <RouterProvider
        router={createBrowserRouter(createRoutesFromElements(routes))}
      />
    </Providers>
  );
};

export default MainAppRouter;
