import { useAuth0 } from '@auth0/auth0-react';
import { Button, Typography } from '@mui/material';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import usePagination from '@app/hooks/usePagination';
import { NewGroup } from '@app/services/groups/types';
import ErrorIcon from '@assets/shared/error.svg?react';
import ErrorHelpReport from '@components/error-help-report';
import Loading from '@components/loading';
import OrganizationAcl from '@components/organization-acl';

import EmptyGroups from './components/empty-groups';
import NewGroupModal from './components/new-group-modal';
import YourGroupsTable from './components/your-groups-table';
import useCreateGroup from './hooks/useCreateGroup';
import useYourGroups from './hooks/useYourGroups';

const Groups = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth0();

  const {
    mutateAsync,
    isError: isErrorCreatingGroup,
    isPending: isCreatingGroup,
    reset,
  } = useCreateGroup();
  const {
    data,
    isError,
    isLoading,
    refetch,
    isRefetching,
    page,
    rowsPerPage,
    total,
    onRowsPerPageChange,
    onPageChange,
  } = usePagination({ page: 1, rowsPerPage: 5 }, useYourGroups, user?.sub);
  const [isNewGroupModalOpen, setIsNewGroupModalOpen] = useState(false);

  const handleGroupCreation = async (form: NewGroup) => {
    const startDate = form.startDate.startOf('day').toISO();
    const expirationDate = form.expirationDate?.endOf('day').toISO() ?? null;
    if (startDate) {
      const newGroup = await mutateAsync({
        ...form,
        startDate,
        expirationDate,
      });
      navigate(newGroup.id);
      setIsNewGroupModalOpen(false);
      await refetch();
    }
  };

  const handleModalClose = () => {
    reset();
    setIsNewGroupModalOpen(false);
  };

  const Content = () => {
    if (isLoading || (isError && isRefetching)) {
      return (
        <div>
          <Typography className="!mb-md !text-3xl" variant="h2">
            {t('groups.title')}
          </Typography>
          <div className="flex h-72 items-center justify-center">
            <Loading />
          </div>
        </div>
      );
    }

    if (isError) {
      return (
        <div className="flex flex-1 flex-col">
          <Typography className="!mb-md !text-3xl" variant="h2">
            {t('groups.title')}
          </Typography>
          <div className="flex flex-1 flex-col items-center justify-center">
            <ErrorIcon />
            <Typography className="!my-lg !mr-1 !text-lg">
              <Trans i18nKey="groups.error">
                Oops! We could not load your groups.
                <span
                  className="cursor-pointer text-lg underline"
                  onClick={() => refetch()}
                >
                  Try again?
                </span>
              </Trans>
            </Typography>
            <ErrorHelpReport />
          </div>
        </div>
      );
    }

    if (data && data.length > 0) {
      return (
        <div>
          <div className="mb-md flex flex-1 items-center justify-between">
            <Typography className="!text-3xl" variant="h2">
              {t('groups.title')}
            </Typography>
            <OrganizationAcl>
              <Button
                variant="contained"
                onClick={() => setIsNewGroupModalOpen(true)}
              >
                {t('groups.empty.createGroup')}
              </Button>
            </OrganizationAcl>
          </div>
          <YourGroupsTable
            groups={data}
            onChangePage={(newPage) => onPageChange(newPage + 1)}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={onRowsPerPageChange}
            totalPages={total}
          />
        </div>
      );
    }

    return (
      <div>
        <Typography className="!mb-md !text-3xl" variant="h2">
          {t('groups.title')}
        </Typography>
        <EmptyGroups onCreateGroupClick={() => setIsNewGroupModalOpen(true)} />
      </div>
    );
  };

  return (
    <div className="px-lg py-screen flex w-full flex-1 flex-col">
      <Content />
      <NewGroupModal
        onTryAgain={reset}
        isOpen={isNewGroupModalOpen}
        onClose={handleModalClose}
        onSubmit={handleGroupCreation}
        isPending={isCreatingGroup}
        isError={isErrorCreatingGroup}
      />
    </div>
  );
};

export default Groups;
