import { ListItem, ListItemText, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { File } from '@app/services/files/types';
import { getOnlyPath } from '@app/utils/files';
import FileIcon from '@components/file-icon';

import Tags from './tags';

interface SearchResultItemProps {
  file: File;
  onFileClick?: (file: File) => void;
  onFileDoubleClick?: (file: File) => void;
}

const SearchResultItem = ({
  file,
  onFileClick,
  onFileDoubleClick,
}: SearchResultItemProps) => {
  const { t } = useTranslation();

  return (
    <motion.div
      layout
      exit={{ translateY: -100, opacity: 0 }}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      whileTap={{ scale: 0.95 }}
    >
      <ListItem
        data-testid="result-item"
        className="mb-4 !cursor-pointer !justify-between border border-gray-300 !px-4 !py-2"
        onClick={() => onFileClick?.(file)}
        onDoubleClick={() => onFileDoubleClick?.(file)}
      >
        <div className="flex items-center">
          <FileIcon type={file.type} className="mr-md" height={36} width={36} />
          <ListItemText className="!mr-md">
            <Typography className="!text-lg !font-semibold">
              {file.fileName}
            </Typography>
            <Typography className="max-w-[60vw] overflow-hidden overflow-ellipsis !text-sm [direction:rtl]">
              {getOnlyPath(file.path)}
            </Typography>
          </ListItemText>
          <Tags maxDisplay={6} tags={file.tags} />
        </div>
        <Typography className="!text-sm">
          {t('fileList.searchResults.lastModified', {
            date: DateTime.fromISO(file.lastModified).toFormat(
              t('formats.date.simpleDateWithTime')
            ),
          })}
        </Typography>
      </ListItem>
    </motion.div>
  );
};

export default SearchResultItem;
