import { File } from '@app/services/files/types';
import { openFile } from '@app/utils/files';

import useDownloadFile from './useDownloadFile';

const useEditFile = () => {
  const { mutateAsync: downloadFile, ...mutation } = useDownloadFile();

  return async (file: Pick<File, 'fileName' | 'id'>) => {
    const download = await downloadFile(file.id);
    const error = await openFile(download, file.fileName);

    return {
      openError: error,
      ...mutation,
    };
  };
};

export default useEditFile;
