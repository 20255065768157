import { useMutation, useQueryClient } from '@tanstack/react-query';

import { SharedFileResponse } from '@app/services/files/types';
import useFilesApi from '@app/services/files/useFilesApi';
import { SuccessfulResponse } from '@filot/types/api';

const useShareFiles = (fileType?: string) => {
  const { shareFile } = useFilesApi();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: shareFile,
    mutationKey: ['file-share'],
    onSuccess: (_data, variables) => {
      queryClient.setQueryData(
        ['files-shared', variables.fileId, fileType],
        (oldData: SuccessfulResponse<SharedFileResponse> | undefined) => {
          if (!oldData) {
            return undefined;
          }

          return {
            data: {
              ...oldData.data,
              users_with_access: {
                ...oldData.data.users_with_access,
                [variables.userId]: ['read'],
              },
            },
            message: 'success',
            success: true,
          } satisfies SuccessfulResponse<SharedFileResponse>;
        }
      );
    },
  });

  return mutation;
};

export default useShareFiles;
