import { useMutation } from '@tanstack/react-query';

import useFilesApi from '@app/services/files/useFilesApi';
import useGroupsApi from '@app/services/groups/useGroupsApi';

const useCreateFolder = () => {
  const { createFolder } = useFilesApi();
  const { createFolder: groupCreateFolder } = useGroupsApi();

  const mutation = useMutation<
    void,
    Error,
    { folderName: string; folderId: string | undefined; groupId?: string }
  >({
    mutationFn: async ({ groupId, ...variables }) => {
      if (groupId) {
        return groupCreateFolder({ ...variables, groupId });
      }

      return createFolder(variables);
    },
    mutationKey: ['create-folder'],
  });

  return mutation;
};

export default useCreateFolder;
