import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

import useAuth from '@app/hooks/useAuth';

const LoadLogin = () => {
  const { login } = useAuth();
  const { logout } = useAuth0();

  useEffect(() => {
    void logout({ openUrl: () => {} });
    void login({ web: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default LoadLogin;
