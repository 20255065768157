import { isRunningOnElectron } from './environment';

export const getOnlyPath = (path: string) => path.replace(/\/[^/]+\/?$/, '/');

export const getOnlyName = (path: string) => path.split('/').at(-1);

export const saveFile = async (file: File, fileName: string) => {
  if (isRunningOnElectron()) {
    const arrayBuffer = await file.arrayBuffer();

    return await window.file.save(arrayBuffer, fileName);
  }

  const fileURL = URL.createObjectURL(file);
  const downloadLink = document.createElement('a');
  downloadLink.href = fileURL;
  downloadLink.download = fileName;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const openFile = async (file: File, fileName: string) => {
  if (isRunningOnElectron()) {
    const arrayBuffer = await file.arrayBuffer();

    return await window.file.open(arrayBuffer, fileName);
  }

  return saveFile(file, fileName);
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    'Bytes',
    'KiB',
    'MiB',
    'GiB',
    'TiB',
    'PiB',
    'EiB',
    'ZiB',
    'YiB',
  ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
