import { Box, Button, FormControl, Modal, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useCreateTag from '@app/hooks/useCreateTag';
import useFileDetails from '@app/hooks/useFileDetails';
import { Tag } from '@app/services/files/types';
import Close from '@assets/shared/close.svg?react';
import Loading from '@components/loading';
import PressableIcon from '@components/pressable-icon';
import StopClickPropagation from '@components/stop-click-propagation';
import TagsInput from '@components/tags-input';
import useGroupContext from '@pages/group-files/hooks/useGroupContext';
import useTags from '@pages/tags/hooks/useTags';

interface TagsEditorModalProps {
  isOpen: boolean;
  onClose: () => void;
  fileId: string;
  onSubmit: (form: { tags: Tag[] }) => void;
}

const TagsEditorModal = ({
  isOpen,
  onClose,
  fileId,
  onSubmit,
}: TagsEditorModalProps) => {
  const group = useGroupContext();
  const { t } = useTranslation();
  const { data, isError, isFetching } = useFileDetails(fileId, group?.id);
  const { data: tags } = useTags({ page: 0, rowsPerPage: 15 });
  const inputValue = useRef('');
  const createTag = useCreateTag();
  const { handleSubmit, control, setValue } = useForm<{ tags: Tag[] }>({
    defaultValues: {
      tags: data?.tags ?? [],
    },
  });

  useEffect(() => {
    if (data) {
      setValue('tags', data.tags);
    }
  }, [data, setValue]);

  const submitWithCurrentValue = useCallback(
    (form: { tags: Tag[] }) => {
      if (inputValue.current !== '') {
        onSubmit({ tags: [...form.tags, createTag(inputValue.current)] });
      } else {
        onSubmit(form);
      }
    },
    [createTag, onSubmit]
  );

  const content = useMemo(() => {
    if (isFetching) {
      return <Loading />;
    }

    if (isError) {
      onClose();

      return null;
    }

    if (data) {
      return (
        <form
          onSubmit={handleSubmit(submitWithCurrentValue)}
          className="flex flex-1 flex-col justify-between"
        >
          <Typography className="!mb-md">
            {t('fileList.fileDetails.tagsEditorModal.title', {
              document: data,
            })}
          </Typography>

          <FormControl fullWidth className="!mb-md">
            <Controller
              name="tags"
              control={control}
              render={({ field }) => (
                <TagsInput
                  options={tags?.data ?? []}
                  {...field}
                  tagClass="text-base"
                  inputClass="text-base"
                  onInputValueChange={(value) => (inputValue.current = value)}
                />
              )}
            />
          </FormControl>
          <Button type="submit" variant="contained" className="!px-md self-end">
            {t('fileList.fileDetails.tagsEditorModal.add')}
          </Button>
        </form>
      );
    }

    return null;
  }, [
    control,
    data,
    handleSubmit,
    isError,
    isFetching,
    onClose,
    submitWithCurrentValue,
    t,
    tags,
  ]);

  return (
    <Modal
      className="flex"
      id="tag-editor"
      open={isOpen}
      onClose={onClose}
      sx={{ zIndex: 199 }}
      disableEnforceFocus
    >
      <StopClickPropagation>
        <Box
          className="w-tags-editor p-screen mx-auto my-auto !flex flex-col rounded bg-white shadow-md"
          data-tour="tags-step-2"
        >
          <PressableIcon
            onClick={onClose}
            icon={<Close title="close" />}
            className="!absolute self-end !p-0"
          />
          {content}
        </Box>
      </StopClickPropagation>
    </Modal>
  );
};

export default TagsEditorModal;
