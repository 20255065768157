import { ListItemText, Divider, ListItemButton } from '@mui/material';
import { ChangeEventHandler, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import useAppJoyride from '@app/hooks/useAppJoyride';
import useAppNavigate from '@app/hooks/useAppNavigate';
import useFilledIcon from '@app/hooks/useFilledIcon';
import { useSetSearch } from '@app/hooks/useSearch';
import PlatformOnly from '@components/platform-only';
import { RoutesNames } from '@filot/types/navigator';

import { version } from '../../../../package.json';

import NewButton from './new-button';

interface WrapperProps {
  children: ReactNode;
  selected?: boolean;
}

const Wrapper = ({ children, selected = false }: WrapperProps) => (
  <div
    className={`px-sm flex items-center !py-2 ${selected ? 'bg-primary/10 flex-1 rounded' : ''}`}
  >
    {children}
  </div>
);

interface NavigatorProps {
  onUploadClick: ChangeEventHandler<HTMLInputElement>;
}

const Navigator = ({ onUploadClick }: NavigatorProps) => {
  const { t } = useTranslation();
  const Icon = useFilledIcon();
  const { isSelected } = useAppNavigate();
  const navigate = useNavigate();
  const setSearch = useSetSearch();
  const { pause } = useAppJoyride();

  const navigateWithMiddlewares = (routeName: RoutesNames) => {
    setSearch('');
    setTimeout(() => {
      switch (routeName) {
        case 'home':
          navigate('files');
          break;
        case 'tags':
          pause();
          navigate('tags');
          break;
        default:
          navigate(routeName);
      }
    }, 20);
  };

  return (
    <div
      className="px-sm flex min-w-64 flex-col justify-start bg-gray-100"
      data-tour="dashboard-step-1"
    >
      <NewButton onFileUpload={onUploadClick} />
      <nav className="h-full">
        <ListItemButton
          disableGutters
          onClick={() => navigateWithMiddlewares('home')}
          className="!py-0"
        >
          <Wrapper selected={isSelected('home')}>
            <Icon
              filled={isSelected('home')}
              name="home"
              className="mr-3"
              width={16}
            />
            <ListItemText primary={t('layout.sidebar.home')} />
          </Wrapper>
        </ListItemButton>

        <PlatformOnly platform="app">
          <ListItemButton
            disableGutters
            onClick={() => navigateWithMiddlewares('organizations')}
            className="!py-0"
          >
            <Wrapper selected={isSelected('organizations')}>
              <Icon
                filled={isSelected('organizations')}
                name="organizations"
                className="mr-3"
                width={16}
                height={16}
              />
              <ListItemText primary={t('layout.sidebar.organizations')} />
            </Wrapper>
          </ListItemButton>
        </PlatformOnly>

        <PlatformOnly platform="app">
          <ListItemButton
            disableGutters
            onClick={() => navigateWithMiddlewares('groups')}
            className="!py-0"
            data-tour="groups-step-3"
          >
            <Wrapper selected={isSelected('groups')}>
              <Icon
                filled={isSelected('groups')}
                name="groups"
                className="mr-3"
                width={16}
                height={16}
              />
              <ListItemText primary={t('layout.sidebar.groups')} />
            </Wrapper>
          </ListItemButton>
        </PlatformOnly>

        {/* <ListItemButton
          disableGutters
          onClick={() => navigateWithMiddlewares('recent')}
          className="!py-0"
        >
          <Wrapper selected={isSelected('recent')}>
            <Icon
              filled={isSelected('recent')}
              name="recent"
              className="mr-3"
              width={16}
              height={16}
            />
            <ListItemText primary={t('layout.sidebar.recent')} />
          </Wrapper>
        </ListItemButton> */}

        <Divider />

        <ListItemButton
          disableGutters
          onClick={() => navigateWithMiddlewares('tags')}
          className="!py-0"
          data-tour="tags-step-3"
        >
          <Wrapper selected={isSelected('tags')}>
            <Icon
              filled={isSelected('tags')}
              name="tags"
              className="mr-3"
              width={16}
              height={16}
            />
            <ListItemText primary={t('layout.sidebar.tags')} />
          </Wrapper>
        </ListItemButton>

        <PlatformOnly platform="app">
          <ListItemButton
            disableGutters
            onClick={() => navigateWithMiddlewares('trash')}
            className="!py-0"
          >
            <Wrapper selected={isSelected('trash')}>
              <Icon
                filled={isSelected('trash')}
                name="trash"
                className="mr-3"
                width={16}
                height={16}
              />
              <ListItemText primary={t('layout.sidebar.trash')} />
            </Wrapper>
          </ListItemButton>
        </PlatformOnly>
      </nav>
      <p className="pb-sm text-center text-xs text-gray-300 transition-all duration-200 hover:text-gray-500">
        {t('layout.sidebar.version', { version })}
      </p>
    </div>
  );
};

export default Navigator;
