import { useQuery } from '@tanstack/react-query';

import useFilesApi, {
  fileResponseToFile,
} from '@app/services/files/useFilesApi';
import useGroupsApi from '@app/services/groups/useGroupsApi';

const useFileDetails = (fileId: string, groupId?: string) => {
  const { getFileDetails } = useFilesApi();
  const { getFileDetails: getGroupFileDetails } = useGroupsApi();

  const query = useQuery({
    queryFn: () => {
      if (groupId) {
        return getGroupFileDetails(groupId, fileId);
      }

      return getFileDetails(fileId);
    },
    queryKey: ['file-details', fileId, groupId],
    select: ({ data }) => fileResponseToFile(data),
  });

  return query;
};

export default useFileDetails;
