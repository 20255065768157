import { useAuth0 } from '@auth0/auth0-react';

import { getEnv } from '@app/config/env';

import getGroupsMock from './mock';
import getGroupsService from './service';
import { Group, GroupResponse, GroupsApi, UserRoles } from './types';

const useGroupsApi = (): GroupsApi => {
  const { getAccessTokenSilently } = useAuth0();

  if (getEnv().mockApiCalls) {
    return getGroupsMock();
  }

  return getGroupsService({ getAccessTokenSilently });
};

const groupResponseToGroup = (groupResponse: GroupResponse): Group => ({
  ...groupResponse,
  users: groupResponse.users as UserRoles,
  startDate: groupResponse.start_date,
  totalFiles: groupResponse.total_files,
  expirationDate: groupResponse.expiration_date,
  picture: groupResponse.icon_image,
});

const groupToGroupResponse = (group: Group): GroupResponse => ({
  ...group,
  start_date: group.startDate,
  total_files: group.totalFiles,
  expiration_date: group.expirationDate,
  icon_image: group.picture ?? '',
  description: group.description ?? '',
});

export { groupResponseToGroup, groupToGroupResponse };

export default useGroupsApi;
