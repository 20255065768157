import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AdvancedSearchFormInputs,
  AdvancedSearchFormInputsFormatted,
  Filter,
} from '@app/services/files/types';

const useAdvancedSearch = () => {
  const { t } = useTranslation();
  const parserRegex = useMemo(
    () =>
      new RegExp(
        /([a-zA-Z\u00C0-\u017F0-9]+:"[^"]+"|[a-zA-Z\u00C0-\u017F0-9-]+)(?=\s||$)/,
        'g'
      ),
    []
  );
  const advancedSearchFormInputsKeys = useMemo(
    () => ['fileName', 'after', 'before', 'path', 'type', 'tags'],
    []
  );

  const toString = useCallback(
    (
      advancedSearch: Partial<
        AdvancedSearchFormInputsFormatted | AdvancedSearchFormInputs
      >
    ) => {
      let searchText = '';
      Object.entries(advancedSearch).forEach(([key, value]) => {
        if (value && value !== 'any') {
          if (Array.isArray(value)) {
            if (value.length > 0) {
              searchText = `${searchText} ${key}:"${value.join(',')}"`;
            }
          } else if (DateTime.isDateTime(value)) {
            searchText = `${searchText} ${key}:"${value.toFormat(t('formats.date.simpleDate'))}"`;
          } else {
            searchText = `${searchText} ${key}:"${value.replace(/"/g, '')}"`;
          }
        }
      });

      return searchText.trim();
    },
    [t]
  );

  const parse = useCallback(
    (searchText: string) => {
      const searchParams: Partial<AdvancedSearchFormInputs> = {};
      searchText.match(parserRegex)?.forEach((pair) => {
        const [name, value] = pair.split(':');
        if (name && value && !advancedSearchFormInputsKeys.includes(name)) {
          return;
        }
        if (name && value) {
          if (name === 'tags') {
            searchParams[name] = value.replace(/"/g, '').split(',');
          } else {
            // @ts-expect-error Typescript does not accept `as keyof` declaration
            searchParams[name] = value.replace(/"/g, '');
          }
        } else if (searchParams.fileName) {
          searchParams.fileName = `${searchParams.fileName?.replace(/"/g, '')} ${pair.replace(/"/g, '')}`;
        } else {
          searchParams.fileName = `${pair.replace(/"/g, '')}`;
        }
      });

      return searchParams;
    },
    [advancedSearchFormInputsKeys, parserRegex]
  );

  const parseToFilters = useCallback(
    (searchText: string): Filter[] => {
      const filters: Filter[] = [];
      searchText.match(parserRegex)?.forEach((pair) => {
        const [name, value] = pair.split(':');
        if (name && value && !advancedSearchFormInputsKeys.includes(name)) {
          return;
        }

        if (name && value) {
          const alreadyCreatedFilter = filters.find(
            (filter) => filter.name === name
          );
          if (alreadyCreatedFilter) {
            alreadyCreatedFilter.value = `${alreadyCreatedFilter.value.replace(/"/g, '')} ${value.replace(/"/g, '')}`;
          } else {
            filters.push({
              name: name as keyof AdvancedSearchFormInputs,
              value: value.replace(/"/g, ''),
            });
          }
        } else {
          const fileNameFilter = filters.find(
            (filter) => filter.name === 'fileName'
          );
          if (fileNameFilter) {
            fileNameFilter.value = `${fileNameFilter.value.replace(/"/g, '')} ${pair}`;
          } else {
            filters.push({ name: 'fileName', value: pair });
          }
        }
      });

      return filters;
    },
    [advancedSearchFormInputsKeys, parserRegex]
  );

  return { toString, parse, parseToFilters };
};

export default useAdvancedSearch;
