import {
  Group,
  GroupMemberResponse,
  GroupResponse,
  NewGroupRequest,
  PermissionLevel,
} from '@app/services/groups/types';
import { Paginated, SuccessfulResponse } from '@filot/types/api';

import filesMock from '../../../../../mock/files.json';
import mock from '../../../../../mock/groups.json';
import getFilesMock from '../files/mock';
import { FileAccessLevel, FileResponse } from '../files/types';

import { GroupsApi } from './types';

const getGroupsMock = (): GroupsApi => {
  const get = async () =>
    new Promise<Paginated<GroupResponse>>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res({
            data: mock.groups as GroupResponse[],
            message: '',
            pagination: {
              links: { self: '' },
              page: 1,
              page_size: 5,
              pages: 1,
              total: 6,
            },
            success: true,
          });
        }
      }, 1000);
    });

  const getDetails = async (id: string) =>
    new Promise<SuccessfulResponse<GroupResponse>>((res, rej) => {
      const groupDetails = mock.groups?.find((group) => group.id === id);
      setTimeout(() => {
        if (!groupDetails) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res({
            success: true,
            message: 'success',
            data: groupDetails as GroupResponse,
          });
        }
      }, 1000);
    });

  const updateFilePermissions = () =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const removeFile = (options: { groupId: string; fileId: string }) =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        const groupToUpdate = mock.groups.find(
          ({ id }) => options.groupId === id
        );
        if (!groupToUpdate || Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const createGroup = (form: NewGroupRequest) =>
    new Promise<Group>((res, rej) => {
      setTimeout(() => {
        if (form.name.includes('error')) {
          rej(new Error('MOCK_ERROR'));
        } else {
          const newGroup: Group = {
            ...form,
            id: 'fdsafas',
            totalFiles: 0,
            users: { 'Gabriel Yamamoto': PermissionLevel.CLIENT },
            description: 'new group description',
            expirationDate: undefined,
            status: 'active',
          };
          (mock.groups as unknown as Group[]).push(newGroup);
          res(newGroup);
        }
      }, 1000);
    });

  const getFiles = (options: { groupId: string; path: string }) =>
    new Promise<SuccessfulResponse<FileResponse[]>>((res, rej) => {
      setTimeout(() => {
        const group = mock.groups.find(({ id }) => options.groupId === id);
        if (group) {
          res({
            data: filesMock.slice(0, 5) as FileResponse[],
            message: 'success',
            success: true,
          });
        } else {
          rej(new Error('MOCK_ERROR'));
        }
      }, 1000);
    });

  const bulkEdit = (_: {
    groupId: string;
    fileIds: string[];
    permissions: FileAccessLevel[];
  }) =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const getMembers = (_: string) =>
    new Promise<SuccessfulResponse<GroupMemberResponse[]>>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res({
            data: mock.members as GroupMemberResponse[],
            message: 'success',
            success: true,
          });
        }
      }, 1000);
    });

  const changeMemberPermission = () =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const inviteUsers = () =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const removeUser = ({ id }: { id: string; groupId: string }) =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          mock.members = mock.members.filter((member) => member.user_id !== id);
          res();
        }
      }, 1000);
    });

  const deleteGroup = (groupId: string) =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          mock.groups = mock.groups.filter((group) => group.id !== groupId);
          res();
        }
      }, 1000);
    });

  const updateGroupDetails = ({
    groupId,
    ...form
  }: {
    groupId: string;
    name?: string;
    description?: string;
    startDate?: string;
    expirationDate?: string | null;
    restorePermissions?: boolean;
  }) =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.1) {
          rej(new Error('MOCK_ERROR'));
        } else {
          let groupToEdit = (mock.groups as unknown as Group[]).find(
            (group) => group.id === groupId
          );
          if (groupToEdit) {
            groupToEdit = {
              ...groupToEdit,
              ...form,
              expirationDate: form.expirationDate ?? undefined,
            };
          }
          res();
        }
      }, 1000);
    });

  const leaveGroup = ({ id }: { id: string; newOwnerEmail?: string }) =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          mock.groups = mock.groups.filter((group) => group.id !== id);
          res();
        }
      }, 1000);
    });

  const updateGroupPicture = () =>
    new Promise<string>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res('Image uploaded successfully. URL: https://google.com');
        }
      }, 1000);
    });

  const reactivateGroup = () =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const getFileDetails = (groupId: string, fileId: string) =>
    new Promise<SuccessfulResponse<FileResponse>>((res, rej) => {
      setTimeout(() => {
        const group = mock.groups.find(({ id }) => groupId === id);
        const file = filesMock.find(({ id }) => id === fileId);
        if (group && file) {
          res({
            data: file as FileResponse,
            message: 'success',
            success: true,
          });
        } else {
          rej(new Error('MOCK_ERROR'));
        }
      }, 1000);
    });

  const copyFiles: GroupsApi['copyFiles'] = ({ groupId, fileId }) =>
    new Promise<SuccessfulResponse>((res, rej) => {
      setTimeout(() => {
        const group = mock.groups.find(({ id }) => groupId === id);
        const file = filesMock.find(({ id }) => id === fileId);
        if (group && file) {
          res({
            message: 'success',
            success: true,
            data: undefined,
          });
        } else {
          rej(new Error('MOCK_ERROR'));
        }
      }, 1000);
    });

  return {
    ...getFilesMock(),
    get,
    getDetails,
    updateFilePermissions,
    removeFile,
    createGroup,
    getFiles,
    bulkEdit,
    getMembers,
    changeMemberPermission,
    inviteUsers,
    removeUser,
    deleteGroup,
    updateGroupDetails,
    leaveGroup,
    updateGroupPicture,
    reactivateGroup,
    getFileDetails,
    copyFiles,
  };
};

export default getGroupsMock;
