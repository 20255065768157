import { createContext, useContext } from 'react';

import { Organization } from '@app/services/organizations/types';

export const OrganizationContext = createContext<Organization>({
  id: '',
  name: '',
  domain: '',
  displayName: '',
});

export const useOrganizationContext = () => useContext(OrganizationContext);
