import { Box, Button, Modal, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import useAlert from '@app/hooks/useAlert';
import useErrorHandler from '@app/hooks/useErrorHandler';
import { File } from '@app/services/files/types';
import Close from '@assets/shared/close.svg?react';
import PressableIcon from '@components/pressable-icon';
import StopClickPropagation from '@components/stop-click-propagation';

import useDeleteForever from '../hooks/useDeleteForever';

interface DeleteForeverConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  file: File;
}

const DeleteForeverConfirmationModal = ({
  isOpen,
  onClose,
  file,
}: DeleteForeverConfirmationModalProps) => {
  const { t } = useTranslation();
  const { isError, mutateAsync: deleteForever } = useDeleteForever();
  const queryClient = useQueryClient();
  const { displayGlobal } = useAlert();
  useErrorHandler(isError, {
    callback: () => {
      displayGlobal({
        text: t(
          'trash.deletedFileDetails.deleteForeverConfirmationModal.error'
        ),
        type: 'error',
      });
    },
  });

  const handleMoveToTrash = async () => {
    onClose();
    await deleteForever({ fileId: file.id });
    await queryClient.invalidateQueries({ queryKey: ['files-deleted'] });
  };

  return (
    <StopClickPropagation>
      <Modal className="flex" open={isOpen} onClose={onClose}>
        <Box className="w-small-modal p-screen mx-auto my-auto !flex flex-col rounded bg-white shadow-md">
          <PressableIcon
            onClick={onClose}
            icon={<Close title="close" />}
            className="!absolute self-end !p-0"
          />
          <Typography className="!mb-sm !text-2xl">
            {t('trash.deletedFileDetails.deleteForeverConfirmationModal.title')}
          </Typography>
          <p>
            {t(
              'trash.deletedFileDetails.deleteForeverConfirmationModal.disclaimer',
              {
                fileName: file.fileName,
              }
            )}
          </p>
          <div className="mt-md gap-x-sm flex justify-end">
            <Button variant="outlined" onClick={onClose}>
              {t(
                'trash.deletedFileDetails.deleteForeverConfirmationModal.cancel'
              )}
            </Button>
            <Button variant="contained" onClick={handleMoveToTrash}>
              {t(
                'trash.deletedFileDetails.deleteForeverConfirmationModal.move'
              )}
            </Button>
          </div>
        </Box>
      </Modal>
    </StopClickPropagation>
  );
};

export default DeleteForeverConfirmationModal;
