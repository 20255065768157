import { PropsWithChildren, useEffect } from 'react';

import * as Storage from '@app/utils/storage';

const StoreParams = ({ children }: PropsWithChildren) => {
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    params.forEach((value, key) => {
      void Storage.setItem(`param_${key}`, value);
    });
  }, []);

  return children;
};

export default StoreParams;
