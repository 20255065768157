import { useMutation, useQueryClient } from '@tanstack/react-query';

import useFilesApi from '@app/services/files/useFilesApi';

const useMoveFile = () => {
  const { moveFile } = useFilesApi();
  const queryClient = useQueryClient();

  const query = useMutation({
    mutationFn: moveFile,
    mutationKey: ['moveFile'],
    onSuccess: (_, { newFolderId }) => {
      queryClient.invalidateQueries({ queryKey: ['files', newFolderId] });
    },
  });

  return query;
};

export default useMoveFile;
