import { useMutation } from '@tanstack/react-query';

import useUsersApi from '@app/services/users/useUsersApi';

const useUpdateUserPicture = () => {
  const { updatePicture } = useUsersApi();

  const mutation = useMutation({
    mutationFn: updatePicture,
    mutationKey: ['user-update-picture'],
  });

  return mutation;
};

export default useUpdateUserPicture;
