import { useMutation } from '@tanstack/react-query';

import useGroupsApi from '@app/services/groups/useGroupsApi';

const useAddFiles = () => {
  const { copyFiles } = useGroupsApi();

  const mutation = useMutation<
    void,
    Error,
    { files: Array<{ id: string; name: string }>; groupId: string }
  >({
    mutationFn: async ({ files, groupId }) => {
      await Promise.all(
        files.map(({ id }) =>
          copyFiles({
            groupId,
            fileId: id,
          })
        )
      );
    },
    mutationKey: ['groups-add-files'],
  });

  return mutation;
};

export default useAddFiles;
