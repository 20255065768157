import { useMutation } from '@tanstack/react-query';

import useFilesApi from '@app/services/files/useFilesApi';

const useDeleteForever = () => {
  const { deleteForever } = useFilesApi();

  const mutation = useMutation({
    mutationFn: deleteForever,
    mutationKey: ['delete-forever'],
  });

  return mutation;
};

export default useDeleteForever;
