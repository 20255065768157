import { useQuery } from '@tanstack/react-query';

import { fileResponseToFile } from '@app/services/files/useFilesApi';
import useGroupsApi from '@app/services/groups/useGroupsApi';

interface UseGroupFilesOptions {
  groupId: string;
  path: string;
}

const useGroupFiles = ({ groupId, path }: UseGroupFilesOptions) => {
  const { getFiles } = useGroupsApi();
  const query = useQuery({
    queryFn: () => getFiles({ path, groupId }),
    queryKey: ['group-files', path, groupId],
    staleTime: 5 * 60 * 1000,
    select: (data) =>
      !data ? [] : data.data.map((file) => fileResponseToFile(file)),
  });

  return query;
};

export default useGroupFiles;
