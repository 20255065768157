import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import EmptyIcon from '@assets/tags/empty.svg?react';
import TablePagination from '@components/table-pagination';

import TagDetailsFilesTableRow from './tag-details-files-table-row';

interface TagDetailsFilesTableProps {
  page: number;
  onPageChange: (newPage: number) => void;
  isPlaceholderData: boolean;
  total: number;
  fileIds: string[];
  onFileClick?: (fileId: string) => void;
}

const TagDetailsFilesTable = ({
  onPageChange,
  page,
  isPlaceholderData,
  total,
  fileIds,
  onFileClick,
}: TagDetailsFilesTableProps) => {
  const { t } = useTranslation();

  if (fileIds.length === 0) {
    return (
      <div className="gap-y-sm flex flex-col items-center">
        <EmptyIcon />
        <p className="text-lg font-medium">
          {t('tags.tagDetails.filesTable.noFiles.title')}
        </p>
        <p>{t('tags.tagDetails.filesTable.noFiles.message')}</p>
      </div>
    );
  }

  return (
    <TableContainer>
      <Table aria-label="files-details-table">
        <colgroup>
          <col className="w-2/5" />
          <col className="w-1/5" />
          <col className="w-2/5" />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell>{t('tags.tagDetails.filesTable.file')}</TableCell>
            <TableCell>{t('tags.tagDetails.filesTable.owner')}</TableCell>
            <TableCell>{t('tags.tagDetails.filesTable.sharedWith')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fileIds.map((fileId) => (
            <TagDetailsFilesTableRow
              key={fileId}
              fileId={fileId}
              onClick={() => onFileClick?.(fileId)}
            />
          ))}
        </TableBody>
        {total > 5 ? (
          <TableFooter>
            <TablePagination
              onPageChange={onPageChange}
              isPlaceholderData={isPlaceholderData}
              page={page}
              total={total}
            />
          </TableFooter>
        ) : null}
      </Table>
    </TableContainer>
  );
};

export default TagDetailsFilesTable;
