import { Chip } from '@mui/material';
import { useRef } from 'react';
import { Link } from 'react-router';

import useAppJoyride from '@app/hooks/useAppJoyride';
import { Tag } from '@app/services/files/types';
import { TagWithFiles } from '@app/services/tags/types';

import TagListItemContextMenu from './tag-list-item-context-menu';

interface TagListItemProps {
  tag: TagWithFiles;
  onOpenMergeTag?: (tag: Tag) => void;
}

const TagListItem = ({ tag, onOpenMergeTag }: TagListItemProps) => {
  const ref = useRef<HTMLLIElement | null>(null);
  const { advanceStep } = useAppJoyride();

  return (
    <li ref={ref} role="listitem">
      <TagListItemContextMenu
        anchorElement={ref.current}
        onOpenMergeTag={() => onOpenMergeTag?.(tag)}
      />
      <Link
        to={tag.id}
        state={{ tag }}
        onClick={() => advanceStep(6, 3)}
        className="text-lg hover:underline"
      >
        <div
          className="gap-x-sm pl-md py-md flex items-center last:pb-0"
          data-tour="tags-step-4"
        >
          <Chip
            key={tag.id}
            color="default"
            className="!mr-2 !mt-2 !h-auto !max-w-40 overflow-ellipsis !rounded-full !px-0 !py-1"
            style={{
              backgroundColor: tag.backgroundColor,
              color: tag.textColor,
            }}
            label={tag.name}
          />
        </div>
      </Link>
    </li>
  );
};

export default TagListItem;
