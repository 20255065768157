import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import * as Storage from '@app/utils/storage';
import Loading from '@components/loading';
import Logo from '@components/logo';

import useGetTokens from './hooks/useGetTokens';
import useRedirectToAppUrlGenerator from './hooks/useRedirectToAppUrlGenerator';

const LoginRedirect = () => {
  const { t } = useTranslation();
  const { handleRedirectCallback } = useAuth0();
  const link = useRef<null | HTMLAnchorElement>(null);
  const generateUrl = useRedirectToAppUrlGenerator();
  const { getToken, loading, token, user } = useGetTokens();

  const redirectToApp = useCallback((openApp = true) => {
    if (openApp) {
      link.current?.click();
    } else {
      Storage.getItem('param_redirectUri').then((redirectUri) => {
        location.href = redirectUri ?? location.origin;
      });
    }
  }, []);

  useEffect(() => {
    void (async () => {
      const client = await Storage.getItem('param_client');
      const code = await Storage.getItem('param_code');
      const state = await Storage.getItem('param_state');

      if (client === 'web') {
        redirectToApp(false);
        void Storage.deleteItem('param_client');
        void Storage.deleteItem('param_code');
        void Storage.deleteItem('param_state');

        return;
      }

      if (!code) {
        void getToken(redirectToApp);
      } else {
        try {
          await handleRedirectCallback(
            `${location.href}?state=${state}&code=${code}`
          );
        } finally {
          void getToken(redirectToApp);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mt-[20vh] flex flex-col items-center">
      <Logo />
      {loading || !user || !token ? (
        <>
          <p className="my-md text-2xl font-medium">
            {t('loginRedirect.loading.title')}
          </p>
          <Loading />
        </>
      ) : (
        <>
          <p className="mt-md text-2xl font-medium">
            {t('loginRedirect.title')}
          </p>
          <p className="mt-sm text-lg">{t('loginRedirect.text')}</p>
          <Button
            onClick={() => redirectToApp()}
            variant="contained"
            className="!mt-lg !px-lg"
          >
            {t('loginRedirect.button')}
          </Button>
          <a ref={link} className="hidden" href={generateUrl(token, user)} />
        </>
      )}
    </div>
  );
};

export default LoginRedirect;
