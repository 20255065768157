import { useMutation } from '@tanstack/react-query';

import useFilesApi from '@app/services/files/useFilesApi';

const useRestoreFile = () => {
  const { restoreFile } = useFilesApi();

  const mutation = useMutation({
    mutationFn: restoreFile,
    mutationKey: ['restore-file'],
  });

  return mutation;
};

export default useRestoreFile;
