import { AxiosResponse } from 'axios';

import { getEnv } from '@app/config/env';
import { getRangedAccessToken, getInstance } from '@app/utils/http-instance';
import { ServiceOptions, SuccessfulResponse } from '@filot/types/api';

import getFilesMock from './mock';
import {
  DeletedFileResponse,
  FilePreviewResponse,
  FileResponse,
  FilesApi,
  NotDeletedFileResponse,
  SharedFileResponse,
} from './types';

const getFilesService = ({
  url,
  getAccessTokenSilently,
}: ServiceOptions): FilesApi => {
  const {
    filot: { files },
  } = getEnv();

  return {
    ...getFilesMock(),
    get: async ({ folderId }) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      const { data } = await instance.get<SuccessfulResponse<
        FileResponse[]
      > | null>(
        `${url ?? files.domain}/api/v1/folders/${folderId}/content/get`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return data;
    },

    getRootContent: async () => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      const { data } =
        await instance.get<SuccessfulResponse<FileResponse> | null>(
          `${url ?? files.domain}/api/v1/folders/root`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

      return data;
    },

    uploadFile: async ({ file, name, folderId }) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();
      const fileBuffer = await file.arrayBuffer();

      const { data } = await instance.requestWithFile<
        FormData,
        AxiosResponse<SuccessfulResponse<NotDeletedFileResponse>>
      >(
        `${url ?? files.domain}/api/v2/files/create`,
        { file: fileBuffer, property: 'file' },
        { name, folderId },
        'post',
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return data;
    },

    getFileDetails: async (fileId) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      const { data } = await instance.get<SuccessfulResponse<FileResponse>>(
        `${url ?? files.domain}/api/v1/files/${fileId}/get`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return data;
    },

    getFolderDetails: async (folderId) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      const { data } = await instance.get<SuccessfulResponse<FileResponse>>(
        `${url ?? files.domain}/api/v1/folders/${folderId}/get`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return data;
    },

    renameFile: async ({ fileId, newName, newPath }) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.put<SuccessfulResponse<FileResponse>>(
        `${url ?? files.domain}/api/v1/files/${fileId}/modify`,
        {
          rename: newName,
          new_path: newPath,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    moveFile: async ({ fileId, newFolderId }) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.put<SuccessfulResponse<FileResponse>>(
        `${url ?? files.domain}/api/v2/files/${fileId}/modify`,
        {
          new_folder_id: newFolderId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    createFolder: async ({ folderId, folderName }) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.post<SuccessfulResponse<FileResponse>>(
        `${url ?? files.domain}/api/v2/folders/create`,
        {
          name: folderName,
          folder_id: folderId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    moveFileToTrash: async (fileId) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.delete<SuccessfulResponse<FileResponse>>(
        `${url ?? files.domain}/api/v1/files/${fileId}/delete`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    moveFolderToTrash: async (folderId) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.delete<SuccessfulResponse<FileResponse>>(
        `${url ?? files.domain}/api/v1/folders/${folderId}/delete`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    download: async (fileId) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      const { data } = await instance.get<ArrayBuffer>(
        `${url ?? files.domain}/api/v1/files/${fileId}/download`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          responseType: 'arraybuffer',
        }
      );

      return new File([data], '');
    },

    lockFile: async ({ fileId }) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.post(
        `${url ?? files.domain}/api/v1/files/${fileId}/lock `,
        {
          lock_duration_in_minutes: 240,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    unlockFile: async ({ fileId }) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.delete(
        `${url ?? files.domain}/api/v1/files/${fileId}/unlock `,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    shareFile: async ({
      userId,
      access,
      expirationDate,
      startDate,
      fileId,
    }) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.post<SuccessfulResponse<FileResponse>>(
        `${url ?? files.domain}/api/v1/sharing/files/${fileId}/share`,
        {
          user_id: userId,
          permissions_to_grant: access,
          activates_at: startDate,
          expiration_date: expirationDate,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    getSharedFile: async (sharedFileId, sharedFileType) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();
      const filesOrFolders = sharedFileType === 'folder' ? 'folders' : 'files';

      const { data } = await instance.get<
        SuccessfulResponse<SharedFileResponse>
      >(
        `${url ?? files.domain}/api/v1/sharing/${filesOrFolders}/${sharedFileId}/get`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return data;
    },

    updateSharingPermissions: async ({ fileId, permission, userId }) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.put(
        `${url ?? files.domain}/api/v1/sharing/files/${fileId}/update`,
        {
          user_id: userId,
          permissions_to_grant: permission,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    revokeSharingPermissions: async ({ fileId, userId }) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.generic(
        `${url ?? files.domain}/api/v1/sharing/files/${fileId}/revoke`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          data: {
            users: [userId],
          },
        }
      );
    },

    getFilePreview: async (fileId) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      const { data } = await instance.get<
        SuccessfulResponse<FilePreviewResponse>
      >(`${url ?? files.domain}/api/v1/files/${fileId}/preview/get`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return data;
    },

    deleteFilePreview: async (fileId) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.delete(
        `${url ?? files.domain}/api/v1/files/${fileId}/preview/delete`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    getDeletedFiles: async () => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      const { data } = await instance.get<SuccessfulResponse<
        DeletedFileResponse[]
      > | null>(`${url ?? files.domain}/api/v1/files/deleted/get`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return data;
    },

    updateContent: async ({ content, fileId, fileName }) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();
      const file = await content.arrayBuffer();

      await instance.requestWithFile(
        `${url ?? files.domain}/api/v1/files/${fileId}/content/modify`,
        { file, property: 'file' },
        { name: fileName },
        'put',
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    extendLock: async ({ fileId }) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.post(
        `${url ?? files.domain}/api/v1/files/${fileId}/lock/extend`,
        {
          lock_duration_in_minutes: 30,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    restoreFile: async ({ fileId }) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.post(
        `${url ?? files.domain}/api/v1/files/${fileId}/restore`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    advancedSearch: async ({ after, before, fileName, path, tags, type }) => {
      const accessToken = await getRangedAccessToken(
        'files',
        getAccessTokenSilently
      );
      const instance = getInstance();

      const { data } = await instance.get<SuccessfulResponse<string[]>>(
        `${url ?? files.domain}/api/v1/files/advanced_search`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            path,
            tags: tags?.join(','),
            file_extension: type ? `.${type}` : undefined,
            name: fileName,
            modification_date_before: before?.toISODate(),
            modification_date_after: after?.toISODate(),
          },
        }
      );

      return data;
    },
  };
};

export { getFilesService };
