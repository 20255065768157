import { ButtonBase, ClickAwayListener, Typography } from '@mui/material';
import { useMemo } from 'react';
import { Link } from 'react-router';

import { Folder, useUpdateBreadcrumb } from '@app/hooks/useBreadcrumb';
import useCloseable from '@app/hooks/useCloseable';
import FolderIcon from '@assets/file-icons/folder.svg?react';

interface FolderListProps {
  folders: Folder[];
  onClose: () => void;
}

const FolderList = ({ folders, onClose }: FolderListProps) => {
  useCloseable({
    onClose,
  });
  const dispatch = useUpdateBreadcrumb();

  const previousFolders = useMemo(() => {
    const result: Folder[] = [];
    const availableFolders = folders.slice(0, -1);

    for (const folder of availableFolders) {
      if (!folder) {
        continue;
      }
      result.push(folder);
    }

    return result;
  }, [folders]);

  const handleClickAway = (e: TouchEvent | MouseEvent) => {
    if (!(e.target as Element).closest('.folderCard, .fileCard')) {
      onClose();
    }
  };

  const handleFolderClick = (folder: Folder) => {
    const index = previousFolders.findIndex((item) => item.id === folder.id);
    const newFolders = index !== -1 ? previousFolders.slice(0, index + 1) : [];
    dispatch({
      type: 'SET_FOLDERS',
      payload: newFolders,
    });
    onClose();
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <ol
        id="folder-list"
        className="absolute left-10 top-10 z-[60] max-w-48 overflow-hidden bg-white shadow-md"
      >
        {previousFolders.map(({ name, path, id }) => (
          <ButtonBase
            key={id}
            className="folderListItem !block w-full"
            id={id}
            title={path}
          >
            <Link
              to={`/files/${id}`}
              onClick={() => handleFolderClick({ name, path, id })}
              className="gap-sm !p-sm flex w-full items-center !justify-start hover:!bg-slate-100"
            >
              <FolderIcon width={12} className="min-w-3" />
              <Typography className="overflow-hidden overflow-ellipsis whitespace-nowrap">
                {name}
              </Typography>
            </Link>
          </ButtonBase>
        ))}
      </ol>
    </ClickAwayListener>
  );
};

export default FolderList;
