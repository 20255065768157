import { useMutation } from '@tanstack/react-query';

import useFilesApi from '@app/services/files/useFilesApi';
import useGroupsApi from '@app/services/groups/useGroupsApi';

const useDownloadFile = (groupId?: string) => {
  const { download } = useFilesApi();
  const { download: groupDownload } = useGroupsApi();

  const mutation = useMutation<File, Error, string>({
    mutationFn: (variables) => {
      if (groupId) {
        return groupDownload(groupId, variables);
      }

      return download(variables);
    },
    mutationKey: ['file-download'],
  });

  return mutation;
};

export default useDownloadFile;
