import * as Sentry from '@sentry/electron/renderer';
import {
  init as reactInit,
  reactRouterV6BrowserTracingIntegration,
} from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router';

import { isRunningOnElectron } from '@app/utils/environment';

import { getEnv } from './env';

export const initSentry = () => {
  if (isRunningOnElectron()) {
    Sentry.init(
      {
        environment: getEnv().mode,
        enabled: getEnv().mode !== 'development',
        dsn: 'https://24b2a1b4564c40f2579a31a6bb8daecd@o4507708409577472.ingest.us.sentry.io/4507818880794624',
        integrations: [
          reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          }),
          Sentry.replayIntegration(),
        ],

        tracesSampleRate: 1.0,

        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        ignoreErrors: ['Client Axios Error'],
      },
      reactInit
    );
  } else {
    reactInit({
      environment: getEnv().mode,
      enabled: getEnv().mode !== 'development',
      dsn: 'https://e73c23b38b4ea5dd4357c4e77e2cd5d5@o4507708409577472.ingest.us.sentry.io/4507818892066816',
      integrations: [
        reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.replayIntegration(),
      ],

      tracesSampleRate: 1.0,

      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      ignoreErrors: ['Client Axios Error'],
    });
  }
};
