import { useBreadcrumb, useUpdateBreadcrumb } from '@app/hooks/useBreadcrumb';
import { File } from '@app/services/files/types';
import { Group } from '@app/services/groups/types';
import Loading from '@components/loading';
import FileList from '@pages/files/components/file-list';
import useGroupFiles from '@pages/groups/hooks/useGroupFiles';

interface GroupFileListProps {
  group: Group;
  onFileClick: (file: File) => void;
  onFileDoubleClick: (file: File) => void;
}

const GroupFileList = ({
  group,
  onFileClick,
  onFileDoubleClick,
}: GroupFileListProps) => {
  const { folders } = useBreadcrumb();
  const dispatch = useUpdateBreadcrumb();
  const { data: files, isLoading } = useGroupFiles({
    groupId: group.id,
    path: folders.join('/'),
  });

  if (isLoading) {
    return (
      <div className="flex h-72 flex-1 items-center justify-center">
        <Loading />
      </div>
    );
  }

  const handleFolderClick = (folder: File) => {
    dispatch({
      type: 'PUSH_FOLDER',
      payload: {
        name: folder.fileName,
        id: folder.id,
        path: folder.path,
      },
    });
  };

  return (
    <FileList
      // onFileMove={onFileMove}
      // onFolderHover={onNavigate}
      files={files}
      onFileClick={onFileClick}
      onFolderClick={handleFolderClick}
      onFileDoubleClick={onFileDoubleClick}
    />
  );
};

export default GroupFileList;
