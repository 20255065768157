import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { Box, Modal, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import getDocumentRenderer from '@app/document-renderers/document';
import getHtmlRenderer from '@app/document-renderers/html';
import getImageRenderer from '@app/document-renderers/image';
import getTextRenderer from '@app/document-renderers/text';
import useCloseRequestModal from '@app/hooks/useCloseRequestModal';
import { FileToUpload } from '@app/services/files/types';
import ErrorUploading from '@assets/layout/error-uploading.svg?react';
import Close from '@assets/shared/close.svg?react';
import ErrorHelpReport from '@components/error-help-report';
import Loading from '@components/loading';
import NoPreview from '@components/no-preview';
import PressableIcon from '@components/pressable-icon';

import UploadPreviewForm from './upload-preview-form';

interface UploadPreviewModalProps {
  isOpen: boolean;
  document: Omit<FileToUpload, 'tags'> | null;
  onClose: () => void;
  onUpload: (document: FileToUpload) => void;
  isPending: boolean;
  isError: boolean;
  onTryAgain: () => void;
}

const UploadPreviewModal = ({
  isOpen,
  onClose,
  onUpload,
  document,
  isPending,
  isError,
  onTryAgain,
}: UploadPreviewModalProps) => {
  const { t } = useTranslation();
  const withCanClose = useCloseRequestModal(isPending, onTryAgain);

  const Content = () => {
    if (isPending) {
      return (
        <div className="flex flex-1 items-center justify-center">
          <Loading color="primary" />
        </div>
      );
    }

    if (isError) {
      return (
        <div className="flex flex-1 flex-col items-center justify-center">
          <ErrorUploading className="flex-3 mb-lg -mt-20" />
          <Typography className="!mb-sm !text-2xl !font-medium">
            {t('layout.uploadPreview.fail.title')}
          </Typography>
          <div className="mb-md flex">
            <Trans i18nKey="layout.uploadPreview.fail.text">
              <Typography className="!mr-1 !text-lg">Please</Typography>
              <Typography
                className="cursor-pointer !text-lg underline"
                onClick={onTryAgain}
              >
                try again
              </Typography>
              <Typography className="!text-lg">.</Typography>
            </Trans>
          </div>
          <ErrorHelpReport />
        </div>
      );
    }

    return (
      <div className="gap-md flex flex-1 overflow-auto">
        {document ? (
          <>
            <DocViewer
              className="flex-1"
              config={{
                header: { disableHeader: true },
                noRenderer: {
                  overrideComponent: NoPreview,
                },
              }}
              documents={[document].map((doc) => ({ uri: doc.previewUri }))}
              pluginRenderers={[
                ...DocViewerRenderers.filter(
                  (docRenderer) => !docRenderer.fileTypes.includes('txt')
                ),
                getTextRenderer('!min-w-0'),
                getImageRenderer(
                  'w-full justify-center self-center overflow-hidden flex'
                ),
                getDocumentRenderer('w-full justify-center flex'),
                getHtmlRenderer(),
              ]}
            />
            <UploadPreviewForm onSubmit={onUpload} document={document} />
          </>
        ) : null}
      </div>
    );
  };

  return (
    <Modal className="flex" open={isOpen} onClose={withCanClose(onClose)}>
      <Box className="p-screen mx-auto my-auto !flex h-3/4 w-3/5 flex-col rounded bg-white shadow-md">
        <PressableIcon
          onClick={withCanClose(onClose)}
          icon={<Close title="close" />}
          className="!absolute self-end !p-0"
        />
        <Typography variant="h2" className="!mb-md !text-2xl">
          {t('layout.uploadPreview.title')}
        </Typography>
        <Content />
      </Box>
    </Modal>
  );
};

export default UploadPreviewModal;
