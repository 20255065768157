import { useAuth0 } from '@auth0/auth0-react';
import {
  Avatar,
  Box,
  ButtonBase,
  Divider,
  Popover,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import useAuth from '@app/hooks/useAuth';
import useUser from '@app/hooks/useUser';
import LogoutIcon from '@assets/layout/logout.svg?react';
import PersonIcon from '@assets/layout/person.svg?react';

interface ProfilePopoverProps {
  isOpen: boolean;
  onClose: () => void;
  anchorElement: HTMLElement | null;
}

const ProfilePopover = ({
  isOpen,
  onClose,
  anchorElement,
}: ProfilePopoverProps) => {
  const auth = useAuth0();
  const { data: user } = useUser(auth.user?.sub);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleProfile = () => {
    navigate('profile');
  };

  return (
    <Popover
      anchorEl={anchorElement}
      id="profile-popover"
      open={isOpen}
      onClose={onClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      className="mt-sm"
    >
      <Box className="p-sm">
        <Avatar
          src={user?.picture ?? ''}
          className="mx-auto"
          sx={{ width: 56, height: 56 }}
        />
        <Typography
          variant="h1"
          className="!mt-sm !text-center !text-lg !font-bold"
        >
          {user?.name ?? ''}
        </Typography>
        <Typography className="!text-center !text-sm">
          {user?.email ?? ''}
        </Typography>
        <Divider className="!my-sm" />
        <ButtonBase
          className="w-full !justify-start !py-2"
          onClick={handleProfile}
        >
          <PersonIcon width={32} className="text-black/80" />
          <p className="ml-2">{t('layout.header.profileMenu.profile')}</p>
        </ButtonBase>
        <ButtonBase
          className="!mt-sm w-full !justify-start !py-2"
          onClick={() => logout()}
        >
          <LogoutIcon width={32} className="text-black/80" />
          <p className="ml-2">{t('layout.header.profileMenu.logout')}</p>
        </ButtonBase>
      </Box>
    </Popover>
  );
};

export default ProfilePopover;
