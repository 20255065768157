import { Button, ButtonProps } from '@mui/material';
import { forwardRef, useMemo } from 'react';

import Loading from './loading';

type LoadingButtonProps = ButtonProps & {
  loading?: boolean;
};

const LoadingButton = forwardRef<HTMLButtonElement, LoadingButtonProps>(
  ({ loading = false, ...props }, ref) => {
    const loadingProps = useMemo<ButtonProps>(() => {
      if (loading) {
        return {
          endIcon: <Loading color="grey" size={18} />,
          disabled: true,
        };
      }

      return {};
    }, [loading]);

    return <Button ref={ref} {...props} {...loadingProps} />;
  }
);

export default LoadingButton;
