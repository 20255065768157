import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { UserResponse } from '@app/services/users/types';
import RemoveIcon from '@assets/shared/close.svg?react';
import RetryIcon from '@assets/shared/retry.svg?react';
import WarningIcon from '@assets/shared/warning.svg?react';
import ChangeOnHover from '@components/change-on-hover';
import PressableIcon from '@components/pressable-icon';

interface ManageAccessPeoplePersonErrorLoadingProps {
  user?: undefined;
  email?: string;
  userId?: string;
  onRemoveUser: () => void;
  onRetry?: undefined;
}

interface ManageAccessPeoplePersonErrorSharingProps {
  user: UserResponse;
  email?: undefined;
  userId?: undefined;
  onRemoveUser?: undefined;
  onRetry: () => void;
}

type ManageAccessPeoplePersonErrorProps =
  | ManageAccessPeoplePersonErrorLoadingProps
  | ManageAccessPeoplePersonErrorSharingProps;

const ManageAccessPeoplePersonError = ({
  user,
  email,
  onRemoveUser,
  userId,
  onRetry,
}: ManageAccessPeoplePersonErrorProps) => {
  const { t } = useTranslation();

  if (user) {
    return (
      <Tooltip
        title={t(
          'fileList.fileDetails.manageAccessModal.form.people.errors.sharing'
        )}
        arrow
        placement="top"
      >
        <div className="flex items-center justify-center rounded-full bg-red-200 p-2">
          <ChangeOnHover
            hoverElement={
              <PressableIcon
                className="!px-1 !py-0"
                icon={
                  <RetryIcon
                    width={18}
                    height={18}
                    title="retry"
                    className="cursor-pointer text-red-600"
                  />
                }
                onClick={onRetry}
              />
            }
          >
            <WarningIcon
              width={26}
              height={26}
              title="warning"
              className="text-red-600"
            />
          </ChangeOnHover>
        </div>
      </Tooltip>
    );
  }

  return (
    <div className="py-sm px-screen flex flex-1 items-center justify-between">
      <p className="max-w-[80%] overflow-hidden text-ellipsis">
        {email ?? userId}
      </p>
      <Tooltip
        title={t(
          'fileList.fileDetails.manageAccessModal.form.people.errors.user'
        )}
        arrow
        placement="top"
      >
        <div className="flex items-center justify-center rounded-full bg-red-200 p-2">
          <ChangeOnHover
            hoverElement={
              <PressableIcon
                className="!px-1.5 !py-0"
                icon={
                  <RemoveIcon
                    width={12}
                    height={12}
                    title="remove"
                    className="cursor-pointer text-red-600"
                  />
                }
                onClick={onRemoveUser}
              />
            }
          >
            <WarningIcon
              width={24}
              height={24}
              title="warning"
              className="text-red-600"
            />
          </ChangeOnHover>
        </div>
      </Tooltip>
    </div>
  );
};

export default ManageAccessPeoplePersonError;
