import { DocRenderer, textFileLoader } from '@cyntler/react-doc-viewer';
import { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

const getTextRenderer = (className?: string) => {
  const TXTRenderer: DocRenderer = ({ mainState: { currentDocument } }) => {
    const divClass = useMemo(
      () =>
        twMerge(
          className,
          'p-screen min-h-[80vh] min-w-[40vw] max-w-[60vw] bg-white'
        ),
      []
    );

    const content = useMemo(() => {
      if (typeof currentDocument?.fileData === 'string') {
        return (
          <p className="whitespace-pre-line">{currentDocument.fileData}</p>
        );
      } else if (currentDocument?.fileData) {
        return null;
      }
    }, [currentDocument?.fileData]);

    return <div className={divClass}>{content}</div>;
  };

  TXTRenderer.fileTypes = ['txt', 'text/plain'];
  TXTRenderer.weight = 0;
  TXTRenderer.fileLoader = textFileLoader;

  return TXTRenderer;
};

export default getTextRenderer;
