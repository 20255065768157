import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAdvancedSearch from '@app/hooks/useAdvancedSearch';
import {
  AdvancedSearchFormInputsFormatted,
  File,
} from '@app/services/files/types';
import useFilesApi, {
  fileResponseToFile,
} from '@app/services/files/useFilesApi';
import useFilesSearch from '@pages/layout/hooks/useFilesSearch';

const useFilter = (files: File[] = []) => {
  const { parse } = useAdvancedSearch();
  const { t } = useTranslation();
  const { getFileDetails } = useFilesApi();
  const [filters, setFilters] = useState<
    Partial<AdvancedSearchFormInputsFormatted>
  >({});
  const { refetch, isLoading, isError } = useFilesSearch({
    filters,
  });
  const [data, setData] = useState<File[] | undefined>(undefined);

  useEffect(() => {
    void (async () => {
      if (Object.keys(filters).length > 0) {
        const { data: fileIds } = await refetch();

        if (fileIds) {
          setData(files.filter(({ id }) => fileIds.includes(id)));

          const filesNotGotYet = fileIds.filter(
            (fileId) => !files.some(({ id }) => id === fileId)
          );
          if (filesNotGotYet.length > 0) {
            filesNotGotYet.forEach((searchResultId) => {
              void (async () => {
                const { data: fileDetailsResponse } =
                  await getFileDetails(searchResultId);
                const fileDetails = fileResponseToFile(fileDetailsResponse);
                setData((currentData) =>
                  currentData ? [...currentData, fileDetails] : undefined
                );
              })();
            });
          }

          return;
        }
        setData([]);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const filterFiles = useCallback(
    (search: string) => {
      const parsed = parse(search);
      setFilters({
        ...parsed,
        after: parsed.after
          ? DateTime.fromFormat(parsed.after, t('formats.date.simpleDate'))
          : undefined,
        before: parsed.before
          ? DateTime.fromFormat(parsed.before, t('formats.date.simpleDate'))
          : undefined,
      });
    },
    [parse, t]
  );

  return { filterFiles, isLoading, isError, data };
};

export default useFilter;
