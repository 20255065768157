import { useMutation } from '@tanstack/react-query';

import { File, UploadFileRequest } from '@app/services/files/types';
import useFilesApi, {
  fileResponseToFile,
} from '@app/services/files/useFilesApi';
import useGroupsApi from '@app/services/groups/useGroupsApi';

const useUploadFile = () => {
  const { uploadFile } = useFilesApi();
  const { uploadFile: groupUploadFile } = useGroupsApi();

  const mutation = useMutation<
    File,
    Error,
    UploadFileRequest & { groupId?: string }
  >({
    mutationFn: async ({ groupId, ...variables }) => {
      if (groupId) {
        const { data: groupData } = await groupUploadFile({
          ...variables,
          groupId,
        });

        return fileResponseToFile(groupData);
      }
      const { data } = await uploadFile(variables);

      return fileResponseToFile(data);
    },
    mutationKey: ['file-upload'],
  });

  return mutation;
};

export default useUploadFile;
