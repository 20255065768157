import { useAuth0 } from '@auth0/auth0-react';

import { File, Permissions } from '@app/services/files/types';

const defaultPermissions: Permissions = {
  write: false,
  read: false,
  update_permissions: false,
  delete: false,
  create_notes: false,
  read_notes: false,
};

const useUserPermissions = ({ users }: Pick<File, 'users'>): Permissions => {
  const { user } = useAuth0();
  const userId = user?.sub;

  if (!userId || !users[userId]) {
    return defaultPermissions;
  }

  users[userId].forEach((level) => {
    defaultPermissions[level] = true;
  });

  return defaultPermissions;
};

export default useUserPermissions;
