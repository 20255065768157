import { AvatarGroup, TableCell, TableRow } from '@mui/material';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Group, PermissionLevel } from '@app/services/groups/types';
import ThreeDotsIcon from '@assets/shared/more-vert.svg?react';
import PressableIcon from '@components/pressable-icon';
import UserAvatar from '@components/user-avatar';

import GroupsContextMenu from './groups-context-menu';

interface YourGroupsTableRowProps {
  group: Group;
}

interface UserSplit {
  owner: string;
  members: string[];
}

const YourGroupsTableRow = ({ group }: YourGroupsTableRowProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pressableIconRef = useRef<HTMLDivElement | null>(null);
  const [isContextMenuVisible, setIsContextMenuVisible] = useState(false);

  const users = useMemo((): UserSplit => {
    let owner: string = '';
    const members: string[] = [];

    for (const userId in group.users) {
      if (group.users[userId] === PermissionLevel.OWNER) {
        owner = userId;
      } else {
        members.push(userId);
      }
    }

    return { owner, members };
  }, [group.users]);

  const handleGroupNavigate = () => {
    navigate(`${group.id}/files`, {
      state: {
        group,
      },
    });
  };

  return (
    <TableRow
      key={group.name}
      className="!table-row cursor-pointer last:border-0"
      onClick={handleGroupNavigate}
    >
      <TableCell>{group.name}</TableCell>
      <TableCell>
        <UserAvatar className="!h-9 !w-9 !text-base" id={users.owner} />
      </TableCell>
      <TableCell>
        <div className="flex items-center">
          {users.members.length > 0 ? (
            <AvatarGroup max={4} className="items-center">
              {users.members.map((member) => (
                <UserAvatar
                  className="!h-9 !w-9 !text-base"
                  key={member}
                  id={member}
                />
              ))}
            </AvatarGroup>
          ) : (
            <p>---</p>
          )}
        </div>
      </TableCell>
      <TableCell>
        <div className="flex items-center">
          <p>{t(`groups.table.status.${group.status}`)}</p>
        </div>
      </TableCell>
      <TableCell onClick={(e) => e.stopPropagation()}>
        <div ref={pressableIconRef}>
          <PressableIcon
            icon={<ThreeDotsIcon />}
            onClick={() => setIsContextMenuVisible(true)}
            altText={t('groups.table.options')}
          />
          <GroupsContextMenu
            group={group}
            anchorElement={pressableIconRef.current}
            isOpen={isContextMenuVisible}
            onClose={() => setIsContextMenuVisible(false)}
          />
        </div>
      </TableCell>
    </TableRow>
  );
};

export default YourGroupsTableRow;
