import { SVGProps } from 'react';

import DocIcon from '@assets/file-icons/doc.svg?react';
import FolderIcon from '@assets/file-icons/folder.svg?react';
import PdfIcon from '@assets/file-icons/pdf.svg?react';
import PptIcon from '@assets/file-icons/ppt.svg?react';
import SharedFolderIcon from '@assets/file-icons/shared_folder.svg?react';
import TxtIcon from '@assets/file-icons/txt.svg?react';

interface FileIconProps extends SVGProps<SVGSVGElement> {
  type: string;
  shared?: boolean;
}

const FileIcon = ({ type, shared, ...props }: FileIconProps) => {
  const fileIconMap = new Map([
    ['pdf', PdfIcon],
    ['folder', FolderIcon],
    ['txt', TxtIcon],
    ['doc', DocIcon],
    ['docx', DocIcon],
    ['docm', DocIcon],
    ['ppt', PptIcon],
    ['pptx', PptIcon],
  ]);

  if (type === 'folder' && shared) {
    return SharedFolderIcon({ ...props, title: type });
  }

  return fileIconMap.get(type)?.({ ...props, title: type });
};

export default FileIcon;
