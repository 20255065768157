import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { getEnv } from '@app/config/env';
import Loading from '@components/loading';
import Logo from '@components/logo';

const Login = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const {
    auth0: { domain, clientId },
  } = getEnv();

  useEffect(() => {
    const redirectUri = location.search.includes('invitation')
      ? `${window.location.origin}/login-redirect`
      : window.location.origin;

    const queryParams = location.search === '' ? '?' : location.search;
    window.location.href = `${domain}/authorize${queryParams}&response_type=code&client_id=${
      clientId
    }&redirect_uri=${redirectUri}`;
  }, [clientId, domain, location.search]);

  return (
    <div className="mt-[20vh] flex flex-col items-center">
      <Logo />
      <p className="my-md text-2xl font-medium">{t('login.title')}</p>
      <Loading />
    </div>
  );
};

export default Login;
