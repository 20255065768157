import { useQuery } from '@tanstack/react-query';

import useFilesApi from '@app/services/files/useFilesApi';
import useGroupsApi from '@app/services/groups/useGroupsApi';

const useFilePreview = (fileId: string, groupId?: string) => {
  const { getFilePreview } = useFilesApi();
  const { getFilePreview: groupGetFilePreview } = useGroupsApi();

  const query = useQuery({
    queryFn: () => {
      if (groupId) {
        return groupGetFilePreview(groupId, fileId);
      }

      return getFilePreview(fileId);
    },
    queryKey: ['file-preview', fileId, groupId],
    select: ({ data }) => data,
  });

  return query;
};

export default useFilePreview;
