import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';

import { useAppLocation } from '@app/hooks/useAppLocation';
import { useUpdateBreadcrumb } from '@app/hooks/useBreadcrumb';
import {
  useDraggingFile,
  useUpdateDraggingFile,
} from '@app/hooks/useDraggingFile';
import useMoveFile from '@app/hooks/useMoveFile';
import { File } from '@app/services/files/types';
import { getOnlyName } from '@app/utils/files';
import FileCard from '@components/file-card';
import FollowMouse from '@components/follow-mouse';
import FolderCard from '@pages/files/components/folder-card';
import { MoveableElements } from '@pages/files/hooks/useMoveableToFolder';

interface UseDragFilesOptions {
  refetch: () => Promise<void>;
  files: File[] | undefined;
}

const useDragFiles = ({ files, refetch }: UseDragFilesOptions) => {
  const { mutateAsync: moveFiles } = useMoveFile();
  const setDraggingFile = useUpdateDraggingFile();
  const navigate = useNavigate();
  const draggingFile = useDraggingFile();
  const location = useAppLocation();
  const { folderId } = useParams();
  const dispatch = useUpdateBreadcrumb();

  useEffect(() => {
    const handleMouseup = async (event: MouseEvent) => {
      const elementAtPointer = document.elementFromPoint(
        event.clientX,
        event.clientY
      );

      if (
        draggingFile?.file.id &&
        folderId &&
        (!elementAtPointer?.classList.contains('folderCard') ||
          elementAtPointer?.id === draggingFile.file.id)
      ) {
        setDraggingFile(null);
        await moveFiles({
          fileId: draggingFile.file.id,
          newFolderId: folderId,
        });
        await refetch();
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    document.addEventListener('mouseup', handleMouseup);

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      document.removeEventListener('mouseup', handleMouseup);
    };
  }, [
    draggingFile?.file.id,
    draggingFile?.file.fileName,
    folderId,
    location.state?.folderId,
    moveFiles,
    refetch,
    setDraggingFile,
  ]);

  const handleFileMove = async (elements: MoveableElements) => {
    setDraggingFile(null);
    await moveFiles({
      fileId: elements.fileId,
      newFolderId: elements.folderId,
    });
    await refetch();
  };

  const handleNavigate = async ({
    folderId: navigateFolderId,
    fileId,
    clientX,
    clientY,
    folderPath,
  }: MoveableElements) => {
    const fileToDrag = files?.find((file) => file.id === fileId);
    if (fileToDrag && clientX && clientY) {
      setDraggingFile({
        initialX: clientX,
        initialY: clientY,
        file: fileToDrag,
      });
    }
    dispatch({
      type: 'PUSH_FOLDER',
      payload: {
        path: folderPath,
        id: navigateFolderId,
        name: getOnlyName(folderPath) ?? '',
      },
    });
    await navigate(`/files/${navigateFolderId}`, {
      state: { folderId: navigateFolderId },
    });
  };

  const DraggingFile = () => {
    if (!draggingFile || !draggingFile.file) {
      return null;
    }
    if (draggingFile.file.type === 'folder') {
      return (
        <FollowMouse
          initialX={draggingFile.initialX}
          initialY={draggingFile.initialY}
        >
          <FolderCard folder={draggingFile?.file} />
        </FollowMouse>
      );
    }

    return (
      <FollowMouse
        initialX={draggingFile.initialX}
        initialY={draggingFile.initialY}
      >
        <FileCard file={draggingFile?.file} />
      </FollowMouse>
    );
  };

  return {
    onFileMove: handleFileMove,
    onNavigate: handleNavigate,
    DraggingFile,
  };
};

export default useDragFiles;
