import React from 'react';
import ReactDOM from 'react-dom/client';
import { pdfjs } from 'react-pdf';

import MainAppRouter from '@pages/routes.tsx';

import App from './App.tsx';
import { initSentry } from './config/sentry.ts';
import { isRunningOnElectron } from './utils/environment.ts';

import './config/i18n.ts';
import './index.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

initSentry();
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App>
      <MainAppRouter />
    </App>
  </React.StrictMode>
);

if (isRunningOnElectron()) {
  window.appWindow.maximize();
  window.appWindow.removeLoading();
}
