import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';

import { UserResponse } from '@app/services/users/types';
import useUsersApi from '@app/services/users/useUsersApi';
import { SuccessfulResponse } from '@filot/types/api';
import { RequestError } from '@filot/types/errors';

const useUserByEmail = (
  email: string,
  options?: Omit<
    UndefinedInitialDataOptions<
      SuccessfulResponse<UserResponse | undefined>,
      Error,
      UserResponse,
      string[]
    >,
    'queryKey'
  >
) => {
  const { getUserByEmail } = useUsersApi();

  const query = useQuery({
    queryFn: () => getUserByEmail(email),
    queryKey: ['users-by-email', email],
    enabled: false,
    retry: (failureCount, error) => {
      if (error instanceof RequestError) {
        const { message } = error.getResponseError();

        return failureCount < 3 && message !== 'User not found!';
      }

      return failureCount < 3;
    },
    select: ({ data }) => data,
    ...options,
  });

  return query;
};

export default useUserByEmail;
