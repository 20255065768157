import { useMutation, useQueryClient } from '@tanstack/react-query';

import { FileResponse } from '@app/services/files/types';
import useTagsApi from '@app/services/tags/useTagsApi';
import { SuccessfulResponse } from '@filot/types/api';

const useRemoveTag = () => {
  const { removeTag } = useTagsApi();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: removeTag,
    mutationKey: ['tag-remove'],
    onSuccess: (_, variables) => {
      queryClient.setQueryData(
        ['file-details', variables.fileId, undefined],
        (oldData: SuccessfulResponse<FileResponse>) => {
          if (!oldData) {
            return undefined;
          }

          const newTags = oldData.data.tags.filter(
            ({ id }) => id !== variables.tagId
          );

          return {
            data: { ...oldData.data, tags: newTags },
          };
        }
      );
    },
  });

  return mutation;
};

export default useRemoveTag;
