import { Button, Typography } from '@mui/material';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Group, Member } from '@app/services/groups/types';
import GroupsMemberTable from '@pages/group-members/components/group-members-table';

interface GroupDetailsTableProps {
  details: Group;
  members: Member[];
}

const GroupDetailsTable = ({ details, members }: GroupDetailsTableProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const formMethods = useForm();

  const navigateMembers = useCallback(
    () =>
      navigate(`/groups/${details?.id}/members`, {
        state: { group: details },
      }),
    [details, navigate]
  );

  return (
    <>
      <div className="gap-x-md mt-lg flex items-center">
        <Typography className="!text-2xl">
          {t('groups.groupDetails.members.title', {
            total: members.length,
          })}
        </Typography>
        <Button variant="outlined" onClick={navigateMembers}>
          {t('groups.groupDetails.members.all')}
        </Button>
      </div>
      <div
        onClick={navigateMembers}
        className="cursor-pointer duration-200 hover:bg-slate-50"
      >
        <FormProvider {...formMethods}>
          <GroupsMemberTable members={members} group={details} readOnly />
        </FormProvider>
      </div>
    </>
  );
};

export default GroupDetailsTable;
