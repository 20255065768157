import { useAuth0 } from '@auth0/auth0-react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Group, Member } from '@app/services/groups/types';
import TablePagination from '@components/table-pagination';

import GroupMembersTableRow from './group-members-table-row';

interface GroupMembersTableReadOnlyProps {
  members: Member[];
  isPlaceholderData?: undefined;
  page?: undefined;
  onPageChange?: undefined;
  total?: undefined;
  readOnly: true;
  group: Group;
}

interface GroupMembersTableEditableProps {
  members: Member[];
  isPlaceholderData: boolean;
  page: number;
  onPageChange: (page: number) => void;
  total: number;
  readOnly?: false;
  group: Group;
}

type GroupMembersTableProps =
  | GroupMembersTableReadOnlyProps
  | GroupMembersTableEditableProps;

const GroupMembersTable = ({
  members,
  isPlaceholderData,
  page,
  onPageChange,
  total,
  readOnly,
  group,
}: GroupMembersTableProps) => {
  const { t } = useTranslation();
  const { user } = useAuth0();

  return (
    <TableContainer className="!mt-md">
      <Table aria-label="members-table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell className="!text-black/50">
              {t('groups.groupMembers.table.header.name')}
            </TableCell>
            <TableCell className="!text-black/50">
              {t('groups.groupMembers.table.header.email')}
            </TableCell>
            {!readOnly ? (
              <>
                <TableCell className="!text-black/50">
                  {t('groups.groupMembers.table.header.permissionLevel')}
                </TableCell>
                <TableCell />
              </>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {members.map((member) =>
            user?.sub ? (
              <GroupMembersTableRow
                key={member.id}
                member={member}
                readOnly={readOnly}
                userPermission={group.users[user.sub]}
              />
            ) : null
          )}
        </TableBody>
        {!readOnly ? (
          <TableFooter>
            <TablePagination
              onPageChange={onPageChange}
              page={page}
              total={total}
              isPlaceholderData={isPlaceholderData}
            />
          </TableFooter>
        ) : null}
      </Table>
    </TableContainer>
  );
};

export default GroupMembersTable;
