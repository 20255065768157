import { Box, Button, Modal, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import useAlert from '@app/hooks/useAlert';
import useCloseRequestModal from '@app/hooks/useCloseRequestModal';
import ErrorIcon from '@assets/groups/bulk-edit-error.svg?react';
import WarningIcon from '@assets/groups/leave-group-warn.svg?react';
import Close from '@assets/shared/close.svg?react';
import ErrorHelpReport from '@components/error-help-report';
import Loading from '@components/loading';
import PressableIcon from '@components/pressable-icon';

import { GroupDetailsContext } from '../group-details';
import useLeaveGroup from '../hooks/useLeaveGroup';

interface LeavingGroupWarningModalProps {
  isOpen: boolean;
  onClose: () => void;
  newOwnerId?: string;
}

const LeavingGroupWarningModal = ({
  onClose,
  newOwnerId,
  isOpen,
}: LeavingGroupWarningModalProps) => {
  const { t } = useTranslation();
  const group = useContext(GroupDetailsContext);
  const navigate = useNavigate();

  const { mutateAsync: leave, isPending, isError, reset } = useLeaveGroup();
  const withCanClose = useCloseRequestModal(isPending, reset);
  const { displayGlobal } = useAlert();
  const queryClient = useQueryClient();

  const handleSubmit = async () => {
    await leave({
      id: group!.id,
      newOwnerId,
    });
    displayGlobal({
      text: t(
        'groups.groupDetails.dangerZone.leaveGroup.leavingWarnModal.success'
      ),
      type: 'success',
    });
    await queryClient.invalidateQueries({ queryKey: ['groups'] });
    navigate('/groups');
    onClose();
  };

  const Content = () => {
    if (!group) {
      return null;
    }

    if (isPending) {
      return (
        <div className="flex flex-1 flex-col items-center justify-center p-16">
          <Loading />
        </div>
      );
    }

    if (isError) {
      return (
        <div className="flex flex-1 flex-col items-center justify-center p-16">
          <ErrorIcon height={120} />
          <p className="my-md text-center text-lg">
            <Trans i18nKey="groups.groupDetails.dangerZone.leaveGroup.leavingWarnModal.error">
              Oops! There was an error and you didn't leave the group.
              <span className="cursor-pointer underline" onClick={reset}>
                Try again?
              </span>
            </Trans>
          </p>
          <ErrorHelpReport />
        </div>
      );
    }

    return (
      <>
        <div className="flex flex-col items-center">
          <WarningIcon width={180} height={180} />
          <p className="mt-md text-center text-base">
            {t(
              'groups.groupDetails.dangerZone.leaveGroup.leavingWarnModal.description',
              {
                groupName: group.name,
              }
            )}
          </p>
        </div>
        <div className="mt-md gap-x-sm flex self-end">
          <Button variant="outlined" onClick={withCanClose(onClose)}>
            {t(
              'groups.groupDetails.dangerZone.leaveGroup.leavingWarnModal.cancel'
            )}
          </Button>
          <Button variant="contained" onClick={handleSubmit}>
            {t(
              'groups.groupDetails.dangerZone.leaveGroup.leavingWarnModal.leave'
            )}
          </Button>
        </div>
      </>
    );
  };

  return (
    <Modal className="flex" open={isOpen} onClose={withCanClose(onClose)}>
      <Box className="p-screen mx-auto my-auto !flex w-4/12 flex-col rounded bg-white shadow-md">
        <PressableIcon
          onClick={withCanClose(onClose)}
          icon={<Close title="close" />}
          className="!absolute self-end !p-0"
        />
        <Typography className="!mb-md !text-2xl !font-medium">
          {t(
            'groups.groupDetails.dangerZone.leaveGroup.leavingWarnModal.title'
          )}
        </Typography>
        <Content />
      </Box>
    </Modal>
  );
};

export default LeavingGroupWarningModal;
