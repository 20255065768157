import { useAuth0 } from '@auth0/auth0-react';
import { alpha, Chip, Tooltip, useTheme } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import WarningIcon from '@assets/shared/warning.svg?react';
import { useUserInputContext } from '@pages/group-members/hooks/useUserInputContext';
import useOrganization from '@pages/organizations/hooks/useOrganization';
import useUserByEmail from '@pages/organizations/hooks/useUserByEmail';

import Loading from './loading';

interface UserInputEmailChipProps {
  label: string;
  onDelete: (label: string) => void;
  onUserFromOutside: (userHasAccount: boolean, label: string) => void;
  onLoad?: (id: string) => void;
}

const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

const UserInputEmailChip = ({
  label,
  onDelete,
  onUserFromOutside,
  onLoad,
}: UserInputEmailChipProps) => {
  const { palette } = useTheme();
  const { t } = useTranslation();
  const { user } = useAuth0();

  const { setIsAvailable } = useUserInputContext();

  const {
    isLoading,
    isFetched,
    error,
    data: userData,
  } = useUserByEmail(label, {
    enabled: emailRegex.test(label.trim()),
  });
  const { data, isLoading: isOrganizationLoading } = useOrganization(user?.sub);

  useEffect(() => {
    if (isFetched) {
      setIsAvailable(true);
    }
  }, [isFetched, setIsAvailable]);

  useEffect(() => {
    if (userData?.user_id) {
      onLoad?.(userData.user_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.user_id]);

  useEffect(() => {
    if (error?.message === 'User not found!') {
      onUserFromOutside(false, label);
    } else if (label.split('@')[1] && label.split('@')[1] !== data?.domain) {
      onUserFromOutside(true, label);
    }
  }, [data?.domain, error?.message, label, onUserFromOutside]);

  const userStatus = useMemo(() => {
    if (isLoading || isOrganizationLoading) {
      return {
        background: undefined,
        message: undefined,
        icon: <Loading size={12} className="!ml-2" />,
      };
    }

    if (error?.message === 'User not found!') {
      return {
        background: alpha(palette.warning.main, 0.5),
        message: t('components.usersInputEmailChip.userWithoutAccount'),
        icon: (
          <WarningIcon
            title="warningIcon"
            width={12}
            height={12}
            className="!ml-2"
            color={alpha(palette.warning.contrastText, 0.5)}
          />
        ),
      };
    }

    if (label.split('@')[1] && label.split('@')[1] !== data?.domain) {
      return {
        background: alpha(palette.danger.main, 0.5),
        message: t('components.usersInputEmailChip.userFromOutside'),
        icon: (
          <WarningIcon
            title="warningIcon"
            width={12}
            height={12}
            className="!ml-2"
            color={alpha(palette.warning.contrastText, 0.5)}
          />
        ),
      };
    }

    return { background: undefined, message: undefined, icon: undefined };
  }, [
    data?.domain,
    error?.message,
    isLoading,
    isOrganizationLoading,
    label,
    palette,
    t,
  ]);

  return (
    <Tooltip title={userStatus.message} arrow>
      <Chip
        key={label}
        label={label}
        onDelete={() => onDelete(label)}
        size="small"
        icon={userStatus.icon}
        className="!mr-sm !my-0.5"
        sx={{
          bgcolor: userStatus.background,
        }}
      />
    </Tooltip>
  );
};

export default UserInputEmailChip;
