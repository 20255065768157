import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import NonOrganized from '@assets/tags/non-organized.svg?react';
import PlatformOnly from '@components/platform-only';

const NonOrganizedTags = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <NonOrganized width={456} height={248} />
      <p className="mb-lg text-2xl font-medium">
        {t('tags.nonOrganized.title')}
      </p>
      <PlatformOnly platform="app">
        <Link to="/organizations">
          <Button variant="outlined" className="!px-md">
            {t('tags.nonOrganized.button')}
          </Button>
        </Link>
      </PlatformOnly>
    </div>
  );
};

export default NonOrganizedTags;
