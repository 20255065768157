import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

import useAlert from '@app/hooks/useAlert';
import { BreadcrumbContextProvider } from '@app/hooks/useBreadcrumb';
import useErrorHandler from '@app/hooks/useErrorHandler';
import { File, Tag } from '@app/services/files/types';
import Loading from '@components/loading';
import FileDetails from '@pages/files/components/file-details';
import FilePreviewModal from '@pages/files/components/file-preview-modal';
import FolderDetails from '@pages/files/components/folder-details';
import TagsEditorModal from '@pages/files/components/tags-editor-modal';
import useEditTags from '@pages/files/hooks/useEditTags';
import useRemoveTag from '@pages/files/hooks/useRemoveTag';

import useGroupDetails from '../group-details/hooks/useGroupDetails';

import GroupFileList from './components/group-file-list';
import GroupFilesHeader from './components/group-files-header';
import { GroupContext } from './hooks/useGroupContext';

const GroupFiles = () => {
  const { t } = useTranslation();
  const { groupId: id = '' } = useParams();
  const navigate = useNavigate();

  const { data, isError, isLoading } = useGroupDetails(id);
  const { display: displayEditTagsAlert, Alert: AlertEditTags } = useAlert();
  const deleteTagMutation = useRemoveTag();
  const {
    mutateAsync: editTags,
    isError: isEditTagError,
    reset: resetEditTags,
  } = useEditTags();
  const { display, Alert } = useAlert();
  useErrorHandler(isEditTagError, {
    callback: () => {
      displayEditTagsAlert();
      resetEditTags();
    },
  });
  const ref = useRef<HTMLDivElement>(null);

  const [fileDetail, setFileDetail] = useState<File | null>(null);
  const [previewOpen, setPreviewOpen] = useState<File | null>(null);
  const [isTagEditorOpen, setIsTagEditorOpen] = useState(false);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useErrorHandler(isError, {
    callback: () => {
      display(goBack);
    },
  });

  const handleBulkEdit = useCallback(() => {
    navigate('bulk-edit');
  }, [navigate]);

  const handleAddFiles = useCallback(() => {
    navigate('add', { state: { group: data } });
  }, [data, navigate]);

  const handleFileDoubleClick = useCallback((file: File) => {
    setFileDetail(null);
    setPreviewOpen(file);
  }, []);

  const content = useMemo(() => {
    if (isLoading) {
      return (
        <div className="flex h-72 flex-1 items-center justify-center">
          <Loading />
        </div>
      );
    }

    if (data) {
      const handleAddTag = () => {
        setTimeout(() => {
          setIsTagEditorOpen(true);
        }, 200);
      };

      const handleEditTags = async ({
        tags,
        fileId,
      }: {
        fileId: string;
        tags: Tag[];
      }) => {
        setIsTagEditorOpen(false);
        await editTags({ tagNames: tags.map((tag) => tag.name), fileId });
      };

      const FileOrFolderDetails = ({ file }: { file: File }) =>
        file.type === 'folder' ? (
          <FolderDetails id={file.id} onClose={() => setFileDetail(null)} />
        ) : (
          <FileDetails
            id={file.id}
            onClose={() => setFileDetail(null)}
            deleteTagControls={deleteTagMutation}
            onAddTag={handleAddTag}
          />
        );

      return (
        <GroupContext.Provider value={data}>
          <BreadcrumbContextProvider>
            <div className="flex flex-1 flex-row overflow-x-hidden">
              <div
                className="p-screen flex flex-1 flex-col overflow-y-auto"
                ref={ref}
              >
                <GroupFilesHeader
                  onBack={goBack}
                  onBulkEdit={handleBulkEdit}
                  onAddFilesClick={handleAddFiles}
                  group={data}
                  groupsPageRef={ref}
                />
                <GroupFileList
                  group={data}
                  onFileClick={setFileDetail}
                  onFileDoubleClick={handleFileDoubleClick}
                />
              </div>
              {fileDetail ? <FileOrFolderDetails file={fileDetail} /> : null}
              {fileDetail && fileDetail.type !== 'folder' ? (
                <TagsEditorModal
                  isOpen={isTagEditorOpen}
                  onClose={() => setIsTagEditorOpen(false)}
                  fileId={fileDetail.id}
                  onSubmit={({ tags }) =>
                    handleEditTags({ fileId: fileDetail.id, tags })
                  }
                />
              ) : null}
            </div>
          </BreadcrumbContextProvider>
        </GroupContext.Provider>
      );
    }

    return null;
  }, [
    data,
    deleteTagMutation,
    editTags,
    fileDetail,
    goBack,
    handleAddFiles,
    handleBulkEdit,
    handleFileDoubleClick,
    isLoading,
    isTagEditorOpen,
  ]);

  return (
    <div className="flex w-full overflow-x-auto">
      {content}
      <Alert severity="error">{t('groups.groupFiles.error')}</Alert>
      <AlertEditTags severity="error">
        {t('fileList.fileDetails.tagsEditorModal.error')}
      </AlertEditTags>
      {previewOpen ? (
        <FilePreviewModal
          file={previewOpen}
          isOpen={!!previewOpen}
          onClose={() => setPreviewOpen(null)}
        />
      ) : null}
    </div>
  );
};

export default GroupFiles;
