import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useContext, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import usePagination from '@app/hooks/usePagination';
import { Note } from '@app/services/files/types';
import ExpandMoreIcon from '@assets/files/expand-more.svg?react';
import ErrorIcon from '@assets/organizations/error-bubble.svg?react';
import ErrorHelpReport from '@components/error-help-report';
import Loading from '@components/loading';
import TablePagination from '@components/table-pagination';

import useCreateNote from '../hooks/useCreateNote';
import useNotes from '../hooks/useNotes';

import { FileDetailsContext } from './file-details';
import FileDetailsNewNoteForm from './file-details-new-note-form';
import FileDetailsNoteCard from './file-details-note-card';

const FileDetailsNote = () => {
  const { t } = useTranslation();
  const {
    mutateAsync: createNote,
    isPending,
    isError,
    reset,
  } = useCreateNote();
  const file = useContext(FileDetailsContext);
  const {
    onPageChange,
    page,
    rowsPerPage,
    total,
    data: notes,
    isError: isNotesError,
    isLoading,
    isRefetching,
    isPlaceholderData,
    refetch,
  } = usePagination({ rowsPerPage: 3, page: 1 }, useNotes, file!.id);
  const queryClient = useQueryClient();

  const handleSubmit = async ({ content }: Pick<Note, 'content'>) => {
    if (file) {
      await createNote({ content, fileId: file.id });
      await queryClient.invalidateQueries({ queryKey: ['notes'] });
    }
  };

  const notesList = useMemo(() => {
    if (isLoading || (isRefetching && isNotesError)) {
      return (
        <div className="my-md flex items-center justify-center">
          <Loading size={18} data-testid="notes-loading" />
        </div>
      );
    }

    if (isNotesError) {
      return (
        <div className="flex flex-col items-center justify-center">
          <ErrorIcon width={48} height={48} />
          <p className="mt-sm">
            <Trans i18nKey="fileList.fileDetails.notes.error">
              Notes could not be loaded
              <span
                className="cursor-pointer underline"
                onClick={() => refetch()}
              >
                Try again
              </span>
            </Trans>
          </p>
          <ErrorHelpReport />
        </div>
      );
    }

    return (
      <>
        {notes?.map((note) => (
          <FileDetailsNoteCard note={note} key={note.id} />
        ))}
        {notes && notes.length > 0 ? (
          <table>
            <tfoot>
              <TablePagination
                onPageChange={(newPage) => onPageChange(newPage + 1)}
                page={page - 1}
                total={total}
                isPlaceholderData={isPlaceholderData}
                rowsPerPage={rowsPerPage}
              />
            </tfoot>
          </table>
        ) : null}
      </>
    );
  }, [
    isLoading,
    isNotesError,
    isRefetching,
    isPlaceholderData,
    notes,
    onPageChange,
    page,
    refetch,
    rowsPerPage,
    total,
  ]);

  const Form = () => {
    if (isPending) {
      return (
        <div className="my-md flex items-center justify-center">
          <Loading size={18} data-testid="new-note-loading" />
        </div>
      );
    }

    if (isError) {
      return (
        <div className="flex flex-col items-center justify-center">
          <ErrorIcon width={48} height={48} />
          <p className="mt-sm">
            <Trans i18nKey="fileList.fileDetails.notes.form.error">
              Note could not be created! Please
              <span className="cursor-pointer underline" onClick={reset}>
                Try again
              </span>
            </Trans>
          </p>
          <ErrorHelpReport />
        </div>
      );
    }

    return <FileDetailsNewNoteForm onSubmit={handleSubmit} />;
  };

  return (
    <div id="file-details-notes" data-tour="preview-document-step-5">
      <Accordion defaultExpanded className="!shadow-none">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="notes-content"
          id="notes-header"
          data-testid="notes-accordion"
          className="!min-h-0 !p-0"
        >
          <Typography>{t('fileList.fileDetails.notes.title')}</Typography>
        </AccordionSummary>
        <AccordionDetails className="!gap-y-md !flex !flex-col !p-0">
          {notesList}
          <Form />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default FileDetailsNote;
