import {
  GetTokenSilentlyOptions,
  MissingRefreshTokenError,
} from '@auth0/auth0-react';
import axios, { AxiosError, AxiosInstance } from 'axios';

import { getEnv } from '@app/config/env';
import { configs } from '@filot/auth';
import { ApiInstance, Range, UnsuccessfulResponse } from '@filot/types/api';
import { RequestError } from '@filot/types/errors';

import { isRunningOnElectron } from './environment';

let instance: AxiosInstance | null = null;

const {
  flags: { makeRequestsInRenderer },
} = getEnv();

export const createInstance = () => {
  instance = axios.create();
  instance.interceptors.response.use(
    (response) => response,
    (err: AxiosError<UnsuccessfulResponse<unknown>>) => {
      if (err instanceof AxiosError) {
        if (err.response && err.response.status && err.response.status > 500) {
          return Promise.reject(err);
        }

        if (err.response) {
          return Promise.reject(
            new RequestError({
              description: err.response.data.message,
              code: err.code,
              message: err.response.data.message,
              response: err.response.data,
              status: err.response.status,
              originUrl: location.href,
            })
          );
        }

        return Promise.reject(
          new AxiosError(
            'Client Axios Error',
            err.code,
            err.config,
            err.request,
            err.response
          )
        );
      }
    }
  );

  return instance;
};

export const getInstance = (): ApiInstance => {
  if (isRunningOnElectron() && !makeRequestsInRenderer) {
    return window.api;
  }

  if (!instance) {
    instance = createInstance();
  }

  return {
    get: instance.get.bind(this),
    put: instance.put.bind(this),
    post: instance.post.bind(this),
    delete: instance.delete.bind(this),
    patch: instance.patch.bind(this),
    generic: instance,
    requestWithFile: (url, file, requestData, method, config) => {
      const fileData = new File([file.file], requestData.name ?? '');
      const formData = new FormData();
      formData.append(file.property, fileData);
      Object.entries(requestData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      return instance![method](url, formData, config);
    },
  } satisfies ApiInstance;
};

export const getRangedAccessToken = async (
  range: Range,
  getAccessTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<string>
) => {
  try {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: configs[range].audience,
        scope: 'offline_access',
      },
    });

    return accessToken;
  } catch (e) {
    if (e instanceof MissingRefreshTokenError) {
      return window.auth.getAccessToken(range);
    }
  }
};
