import { Tab, Tabs, Typography } from '@mui/material';
import { ReactNode, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useOrganizationContext } from '../hooks/useOrganizationContext';

import OrganizationGroups from './organization-groups';
import OrganizationsOverview from './organization-overview';
import OrganizationsUser from './organizations-users';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
  className?: string;
}

const a11yProps = (index: number) => ({
  id: `organizations-tab-${index}`,
  'aria-controls': `organizations-tabpanel-${index}`,
});

const OrganizationTabs = () => {
  const [tab, setTab] = useState(0);
  const { t } = useTranslation();
  const organization = useOrganizationContext();

  const handleChange = (_: SyntheticEvent, newTab: number) => {
    setTab(newTab);
  };

  const TabPanel = ({ index, value, children, className }: TabPanelProps) => (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`organization-tabpanel-${index}`}
      aria-labelledby={`organization-tab-${index}`}
      className={className}
    >
      {value === index && children}
    </div>
  );

  return (
    <div className="flex flex-1 flex-col">
      <Typography variant="h2" className="!mb-md !text-3xl !font-bold">
        {organization.displayName}
      </Typography>
      <Tabs value={tab} onChange={handleChange} aria-label="organizations-tab">
        <Tab
          className="!tracking-wider"
          label={t('organization.tabs.overview')}
          {...a11yProps(0)}
        />
        <Tab
          className="!tracking-wider"
          label={t('organization.tabs.users')}
          {...a11yProps(1)}
        />
        <Tab
          className="!tracking-wider"
          label={t('organization.tabs.groups')}
          {...a11yProps(2)}
        />
        <Tab
          className="!tracking-wider"
          label={t('organization.tabs.settings')}
          {...a11yProps(3)}
        />
      </Tabs>
      <TabPanel index={0} value={tab}>
        <OrganizationsOverview />
      </TabPanel>
      <TabPanel index={1} value={tab}>
        <OrganizationsUser />
      </TabPanel>
      <TabPanel
        index={2}
        value={tab}
        className={tab === 2 ? 'flex flex-1' : undefined}
      >
        <OrganizationGroups />
      </TabPanel>
      <TabPanel
        index={3}
        value={tab}
        className={tab === 3 ? 'flex flex-1' : undefined}
      >
        <OrganizationGroups />
      </TabPanel>
    </div>
  );
};

export default OrganizationTabs;
