import { FormControl, MenuItem, Select } from '@mui/material';
import { forwardRef } from 'react';
import { ControllerRenderProps, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PermissionLevel } from '@app/services/groups/types';

const GroupMembersPermissionLevelSelector = forwardRef<
  HTMLInputElement,
  ControllerRenderProps<FieldValues, `${string}_permission`>
>((field, _) => {
  const { t } = useTranslation();

  return (
    <FormControl className="w-4/6">
      <Select
        id={`${field.name}-select`}
        variant="standard"
        size="small"
        disableUnderline
        className="!text-base"
        {...field}
      >
        <MenuItem value={PermissionLevel.CLIENT}>
          {t('groups.groupMembers.table.permissionLevels.client')}
        </MenuItem>
        <MenuItem value={PermissionLevel.MANAGER}>
          {t('groups.groupMembers.table.permissionLevels.manager')}
        </MenuItem>
        <MenuItem value={PermissionLevel.ADMIN}>
          {t('groups.groupMembers.table.permissionLevels.admin')}
        </MenuItem>
        <MenuItem value={PermissionLevel.REVIEWER}>
          {t('groups.groupMembers.table.permissionLevels.reviewer')}
        </MenuItem>
      </Select>
    </FormControl>
  );
});

export default GroupMembersPermissionLevelSelector;
