import { TableCell, TableRow } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
// import { DateTime } from 'luxon';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAlert from '@app/hooks/useAlert';
import { User } from '@app/services/organizations/types';
import MoreIcon from '@assets/shared/more-vert.svg?react';
import PressableIcon from '@components/pressable-icon';
import UserAvatar from '@components/user-avatar';

import { useOrganizationContext } from '../hooks/useOrganizationContext';
import useRemoveUserFromOrganization from '../hooks/useRemoveUserFromOrganization';

import OrganizationUsersContextMenu from './organization-users-context-menu';
import RemoveUserFromOrganizationConfirmationModal from './remove-user-from-organization-confirmation-modal';
import RemoveUsersFromOrganizationModal, {
  RemoveUsersFromOrganizationModalForm,
} from './remove-user-from-organization-modal';

interface OrganizationUsersTableRowProps {
  user: User;
}

const OrganizationUsersTableRow = ({
  user,
}: OrganizationUsersTableRowProps) => {
  const pressableIconRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();

  const organization = useOrganizationContext();
  const queryClient = useQueryClient();

  const {
    mutateAsync: removeUser,
    isError,
    isPending,
    reset,
  } = useRemoveUserFromOrganization();

  const [isContextMenuVisible, setIsContextMenuVisible] = useState(false);
  const [isRemoveUserModalVisible, setIsRemoveUserModalVisible] =
    useState(false);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false);
  const [userToBeRemoved, setUserToBeRemoved] =
    useState<RemoveUsersFromOrganizationModalForm | null>(null);
  const { display, Alert } = useAlert();

  const handleOpenConfirmModal = (
    form: RemoveUsersFromOrganizationModalForm
  ) => {
    setUserToBeRemoved(form);
    setIsConfirmationModalVisible(true);
    setIsRemoveUserModalVisible(false);
  };

  const handleOpenFormModal = () => {
    setIsConfirmationModalVisible(false);
    setIsRemoveUserModalVisible(true);
  };

  const handleTryAgain = () => {
    reset();
    handleOpenFormModal();
  };

  const handleSubmit = async () => {
    const expirationDate = userToBeRemoved!.accessExpirationDate.toISODate();

    await removeUser({
      expirationDate,
      newOwnerEmail: userToBeRemoved!.newOwner,
      user,
      organization,
    });
    await queryClient.invalidateQueries({ queryKey: ['organization-users'] });
    setIsConfirmationModalVisible(false);
    display();
  };

  return (
    <TableRow key={user.user_id}>
      <TableCell className="!py-2">
        <UserAvatar className="!h-9 !w-9 !text-base" user={user} />
      </TableCell>
      <TableCell className="!py-2">
        <p>{user.name}</p>
      </TableCell>
      <TableCell className="!py-2">
        <p>{user.email}</p>
      </TableCell>
      {/* <TableCell className="!py-2">
        <p className="capitalize">
          {DateTime.fromISO(user.lastLoginDate).toRelativeCalendar()}
        </p>
      </TableCell> */}
      <TableCell className="!py-2">
        <p>Active</p>
      </TableCell>
      <TableCell className="!py-2">
        <div ref={pressableIconRef}>
          <PressableIcon
            icon={<MoreIcon title="more" />}
            onClick={() => setIsContextMenuVisible(true)}
          />
          <OrganizationUsersContextMenu
            onDeleteUser={() => setIsRemoveUserModalVisible(true)}
            anchorElement={pressableIconRef.current}
            isOpen={isContextMenuVisible}
            onClose={() => setIsContextMenuVisible(false)}
          />
          <RemoveUsersFromOrganizationModal
            isOpen={isRemoveUserModalVisible}
            onClose={() => setIsRemoveUserModalVisible(false)}
            onSubmit={handleOpenConfirmModal}
            user={user}
          />
          <RemoveUserFromOrganizationConfirmationModal
            isOpen={isConfirmationModalVisible}
            onClose={() => setIsConfirmationModalVisible(false)}
            onSubmit={handleSubmit}
            onGoBack={handleOpenFormModal}
            user={user}
            isError={isError}
            onTryAgain={handleTryAgain}
            isPending={isPending}
          />
        </div>
        <Alert severity="success">
          {t('organization.users.table.removeConfirmation.success')}
        </Alert>
      </TableCell>
    </TableRow>
  );
};

export default OrganizationUsersTableRow;
