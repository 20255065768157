import { SelectChangeEvent, TableCell } from '@mui/material';
import { useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import useAlert from '@app/hooks/useAlert';
import { Member, PermissionLevel } from '@app/services/groups/types';
import useGroupPermissions from '@pages/groups/hooks/useGroupPermissions';

import useChangeGroupMemberPermission from '../hooks/useChangeGroupMemberPermission';

import GroupMembersPermissionLevelSelector from './group-members-permission-level-selector';

interface GroupMembersPermissionCellProps {
  memberPermission: PermissionLevel;
  memberId: string;
  permission: PermissionLevel;
}

export interface UpdateMemberRoleForm {
  [key: string]: Member['permissionLevel'];
}

const GroupMembersPermissionCell = ({
  permission,
  memberId,
  memberPermission,
}: GroupMembersPermissionCellProps) => {
  const { t } = useTranslation();
  const { groupId } = useParams();
  const { setValue, control } = useFormContext<UpdateMemberRoleForm>();

  const [safeValue, setSafeValue] = useState(memberPermission);
  const [isDisabled, setIsDisabled] = useState(false);

  const { canManageMembers } = useGroupPermissions({
    userPermission: permission,
    memberPermission,
  });
  const { displayGlobal } = useAlert();

  const { mutateAsync: changeMemberPermission } =
    useChangeGroupMemberPermission(memberId);

  const getTextFromPermission = useCallback(
    (permissionLevel: PermissionLevel) => {
      const permissionLevelMap: Record<PermissionLevel, string> = {
        [PermissionLevel.REVIEWER]: t(
          'groups.groupMembers.table.permissionLevels.reviewer'
        ),
        [PermissionLevel.MANAGER]: t(
          'groups.groupMembers.table.permissionLevels.manager'
        ),
        [PermissionLevel.ADMIN]: t(
          'groups.groupMembers.table.permissionLevels.admin'
        ),
        [PermissionLevel.OWNER]: t(
          'groups.groupMembers.table.permissionLevels.owner'
        ),
        [PermissionLevel.CLIENT]: t(
          'groups.groupMembers.table.permissionLevels.client'
        ),
      };

      return permissionLevelMap[permissionLevel];
    },
    [t]
  );

  const isPermission = (value: string): value is PermissionLevel =>
    Object.values(PermissionLevel).includes(value as PermissionLevel);

  const handleUpdatePermission = async (
    event: SelectChangeEvent<PermissionLevel>
  ) => {
    const newPermission = event.target.value;
    if (isPermission(newPermission)) {
      setIsDisabled(true);
      try {
        await changeMemberPermission({
          groupId: groupId!,
          member: memberId,
          permission: newPermission,
        });
        setSafeValue(newPermission);
      } catch (_) {
        displayGlobal({
          text: t('groups.groupMembers.changeMembersPermission.error'),
          type: 'error',
        });
        setValue(`${memberId}_permission`, safeValue);
      } finally {
        setIsDisabled(false);
      }
    }
  };

  return (
    <>
      <TableCell className="!py-2">
        <Controller
          name={`${memberId}_permission`}
          control={control}
          defaultValue={memberPermission}
          render={({ field }) =>
            canManageMembers() ? (
              <GroupMembersPermissionLevelSelector
                {...field}
                onChange={(e: SelectChangeEvent<PermissionLevel>) => {
                  field.onChange(e);
                  void handleUpdatePermission(e);
                }}
                disabled={isDisabled}
              />
            ) : (
              <p className="text-base">
                {getTextFromPermission(memberPermission)}
              </p>
            )
          }
        />
      </TableCell>
    </>
  );
};

export default GroupMembersPermissionCell;
