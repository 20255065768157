import { useMutation, useQueryClient } from '@tanstack/react-query';

import useTagsApi from '@app/services/tags/useTagsApi';

const useDeleteTag = () => {
  const { deleteTag } = useTagsApi();
  const queryClient = useQueryClient();

  const query = useMutation({
    mutationFn: deleteTag,
    mutationKey: ['tag-delete'],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tags'] });
    },
  });

  return query;
};

export default useDeleteTag;
