import { useMutation } from '@tanstack/react-query';

import useFilesApi from '@app/services/files/useFilesApi';
import useGroupsApi from '@app/services/groups/useGroupsApi';

const useUpdateFile = (groupId?: string) => {
  const { updateContent } = useFilesApi();
  const { updateContent: groupUpdateContent } = useGroupsApi();

  const mutation = useMutation<
    void,
    Error,
    { fileId: string; content: globalThis.File; fileName: string }
  >({
    mutationFn: (variables) => {
      if (groupId) {
        return groupUpdateContent({ ...variables, groupId });
      }

      return updateContent(variables);
    },
    mutationKey: ['file-update-content'],
  });

  return mutation;
};

export default useUpdateFile;
