import * as colorsJs from '../../../../modules/colors.js';

export const componentToHex = (component: number) => {
  const hex = Math.round(component).toString(16);

  return hex.padStart(2, '0');
};

export const rgbToHex = (r: number, g: number, b: number, opacity = 1) =>
  `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}${componentToHex(opacity * 255)}`;

export const blend = (color: number, opacity = 1) =>
  Math.round(((color / 255) * opacity + (1 - opacity)) * 255);

export const hexToRgb = (hexWithOpacity: string) => {
  const hex = hexWithOpacity.slice(0, 7);
  const opacity = parseInt(hexWithOpacity.slice(7, 9), 16) / 255;
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return { r, g, b, opacity };
};

export const hexAddOpacity = (color: string, opacity: number) => {
  let expandedColor = color;
  if (color.includes('#')) {
    if (color.length === 4) {
      expandedColor = `#${color.at(1)}${color.at(1)}${color.at(2)}${color.at(2)}${color.at(3)}${color.at(3)}`;
    } else if (color.length !== 7) {
      throw new Error('Invalid HEX');
    }
  } else {
    if (color.length === 3) {
      expandedColor = `#${color.at(0)}${color.at(0)}${color.at(1)}${color.at(1)}${color.at(2)}${color.at(2)}`;
    } else if (color.length !== 6) {
      throw new Error('Invalid HEX');
    }
  }

  return expandedColor.concat(componentToHex(opacity * 255));
};

interface StringToColorOptions {
  opacity?: number;
}

export const stringToColor = (
  string: string,
  { opacity }: StringToColorOptions = {}
) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */
  if (opacity) {
    color = color.concat(componentToHex(opacity * 255));
  }

  const { r, g, b } = hexToRgb(color);
  let textColor: '#ffffff' | '#313131' = '#ffffff';
  if (opacity) {
    const blendedR = blend(r, opacity);
    const blendedG = blend(g, opacity);
    const blendedB = blend(b, opacity);

    // Calculate luminance
    const luminance =
      (0.2126 * blendedR + 0.7152 * blendedG + 0.0722 * blendedB) / 255;

    textColor = luminance > 0.7 ? '#313131' : '#ffffff';
  } else {
    const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;
    textColor = luminance > 0.7 ? '#313131' : '#ffffff';
  }

  return { color, contrastColor: textColor };
};

export const shadeColor = (hex: string, percent: number): string =>
  colorsJs.pSBC(percent, hex);

export const isValid = (hex: string): boolean => {
  try {
    hexAddOpacity(hex, 0);

    return true;
  } catch (e) {
    return false;
  }
};
