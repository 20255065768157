import { Box, Modal, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import useAlert from '@app/hooks/useAlert';
import useCloseRequestModal from '@app/hooks/useCloseRequestModal';
import { AccessDates } from '@app/services/groups/types';
import ErrorBubbleIcon from '@assets/organizations/error-bubble.svg?react';
import Close from '@assets/shared/close.svg?react';
import ErrorHelpReport from '@components/error-help-report';
import Loading from '@components/loading';
import PressableIcon from '@components/pressable-icon';

import useReactivateGroup from '../hooks/useReactivateGroup';
import useUpdateGroupDetails from '../hooks/useUpdateGroupDetails';

import GroupDetailsActivateEarlier from './group-details-activate-earlier';
import GroupDetailsEditDatesForm from './group-details-edit-dates-form';
import GroupDetailsReactivate from './group-details-reactivate';

interface GroupDetailsEditDateModalProps {
  isOpen: boolean;
  onClose: () => void;
  startDate: DateTime;
  expirationDate: DateTime | null;
  groupId: string;
}

const GroupDetailsEditDatesModal = ({
  isOpen,
  onClose,
  expirationDate,
  startDate,
  groupId,
}: GroupDetailsEditDateModalProps) => {
  const { t } = useTranslation();
  const {
    mutateAsync: updateGroupDetails,
    isPending,
    isError,
    reset,
  } = useUpdateGroupDetails();
  const {
    mutateAsync: reactivateGroup,
    isPending: isPendingReactivate,
    isError: isErrorReactivate,
    reset: resetReactivate,
  } = useReactivateGroup();
  const resetAll = useCallback(() => {
    reset();
    resetReactivate();
  }, [reset, resetReactivate]);
  const withCanClose = useCloseRequestModal(
    isPending || isPendingReactivate,
    resetAll
  );
  const [isEarlyAccess, setIsEarlyAccess] = useState<AccessDates | false>(
    false
  );
  const [isReactivate, setIsReactivate] = useState<AccessDates | false>(false);
  const { displayGlobal } = useAlert();

  const handleSubmit = async (
    form: AccessDates,
    accepted = false,
    restorePermissions = false
  ) => {
    if (form.startDate.diff(startDate, 'day').days < 0 && accepted !== true) {
      setIsEarlyAccess(form);

      return;
    }
    if (
      expirationDate &&
      expirationDate.diffNow('day').days < 0 &&
      accepted !== true
    ) {
      setIsReactivate(form);

      return;
    }

    const newStartDate = form.startDate.endOf('day').toISO();
    if (newStartDate) {
      if (isReactivate) {
        await reactivateGroup({
          groupId,
          startDate: newStartDate,
          expirationDate: form.expirationDate?.endOf('day').toISO() ?? null,
          restorePermissions,
        });
      } else {
        await updateGroupDetails({
          groupId,
          startDate: newStartDate,
          expirationDate: form.expirationDate?.endOf('day').toISO() ?? null,
        });
      }
      onClose();
      setIsEarlyAccess(false);
      setIsReactivate(false);
      displayGlobal({
        type: 'success',
        text: t('groups.groupDetails.editDatesModal.success'),
      });
    }
  };

  const Content = () => {
    if (isPending || isPendingReactivate) {
      return (
        <div className="flex h-64 items-center justify-center">
          <Loading />
        </div>
      );
    }

    if (isError || isErrorReactivate) {
      return (
        <div className="flex h-64 flex-col items-center justify-center">
          <ErrorBubbleIcon className="mb-md mx-auto" />
          <p className="mx-lg mb-md text-center text-xl font-medium">
            <Trans i18nKey="groups.groupDetails.editDatesModal.error">
              Oops! There was an error updating access dates
              <span className="cursor-pointer underline" onClick={resetAll}>
                Try Again?
              </span>
            </Trans>
          </p>
          <ErrorHelpReport />
        </div>
      );
    }

    if (isEarlyAccess) {
      return (
        <GroupDetailsActivateEarlier
          onCancel={() => {
            setIsEarlyAccess(false);
            onClose();
          }}
          onSubmit={handleSubmit}
          {...isEarlyAccess}
        />
      );
    }

    if (isReactivate) {
      return (
        <GroupDetailsReactivate
          onCancel={() => {
            setIsReactivate(false);
            onClose();
          }}
          onSubmit={handleSubmit}
          {...isReactivate}
        />
      );
    }

    return (
      <GroupDetailsEditDatesForm
        initialExpirationDate={expirationDate}
        initialStartDate={startDate}
        onCancel={withCanClose(onClose)}
        onSubmit={handleSubmit}
      />
    );
  };

  return (
    <Modal className="flex" open={isOpen} onClose={withCanClose(onClose)}>
      <Box className="w-medium-modal p-screen mx-auto my-auto !flex flex-col rounded bg-white shadow-md">
        <PressableIcon
          onClick={withCanClose(onClose)}
          icon={<Close title="close" />}
          className="!absolute self-end !p-0"
        />
        <Typography className="!text-2xl !font-medium">
          {t('groups.groupDetails.editDatesModal.title')}
        </Typography>
        <Content />
      </Box>
    </Modal>
  );
};

export default GroupDetailsEditDatesModal;
