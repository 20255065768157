import { getEnv } from '@app/config/env';
import {
  Group,
  GroupMemberResponse,
  GroupResponse,
} from '@app/services/groups/types';
import { getRangedAccessToken, getInstance } from '@app/utils/http-instance';
import {
  Paginated,
  ServiceOptions,
  SuccessfulResponse,
} from '@filot/types/api';

import {
  FilePreviewResponse,
  FileResponse,
  NotDeletedFileResponse,
} from '../files/types';

import getGroupsMock from './mock';
import { GroupsApi } from './types';

const getGroupsService = ({
  url,
  getAccessTokenSilently,
}: ServiceOptions): GroupsApi => {
  const {
    filot: { groups },
  } = getEnv();

  return {
    ...getGroupsMock(),
    get: async (paginationOptions) => {
      const accessToken = await getRangedAccessToken(
        'groups',
        getAccessTokenSilently
      );
      const instance = getInstance();

      const { data } = await instance.get<Paginated<GroupResponse> | undefined>(
        `${url ?? groups.domain}/api/v1/groups/get`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            page: paginationOptions.page,
            page_size: paginationOptions.rowsPerPage,
          },
        }
      );

      if (!data) {
        return {
          data: [],
          message: 'No content',
          pagination: {
            links: {
              self: '',
            },
            page: 0,
            page_size: 0,
            pages: 0,
            total: 0,
          },
          success: true,
        };
      }

      return data;
    },

    createGroup: async ({ expirationDate, name, startDate }) => {
      const accessToken = await getRangedAccessToken(
        'groups',
        getAccessTokenSilently
      );
      const instance = getInstance();

      const { data } = await instance.post<SuccessfulResponse<Group>>(
        `${url ?? groups.domain}/api/v1/groups/create`,
        {
          name,
          start_date: startDate,
          expiration_date: expirationDate,
          description: '',
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return data.data;
    },

    getDetails: async (id) => {
      const accessToken = await getRangedAccessToken(
        'groups',
        getAccessTokenSilently
      );
      const instance = getInstance();

      const { data } = await instance.get<SuccessfulResponse<GroupResponse>>(
        `${url ?? groups.domain}/api/v1/groups/${id}/get`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return data;
    },

    updateGroupDetails: async ({ groupId, ...options }) => {
      const accessToken = await getRangedAccessToken(
        'groups',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.put(
        `${url ?? groups.domain}/api/v1/groups/${groupId}/modify`,
        {
          name: options.name,
          description: options.description,
          start_date: options.startDate,
          expiration_date: options.expirationDate,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    deleteGroup: async (id) => {
      const accessToken = await getRangedAccessToken(
        'groups',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.delete(
        `${url ?? groups.domain}/api/v1/groups/${id}/delete`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    removeFile: async ({ fileId, groupId }) => {
      const accessToken = await getRangedAccessToken(
        'groups',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.delete(
        `${url ?? groups.domain}/api/v1/groups/${groupId}/files/delete`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          data: {
            files: [fileId],
          },
        }
      );
    },

    inviteUsers: async ({ emails, groupId }) => {
      const accessToken = await getRangedAccessToken(
        'groups',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.post(
        `${url ?? groups.domain}/api/v1/groups/${groupId}/users/create`,
        {
          users: emails,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    removeUser: async ({ id, groupId }) => {
      const accessToken = await getRangedAccessToken(
        'groups',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.delete(
        `${url ?? groups.domain}/api/v1/groups/${groupId}/users/delete`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          data: {
            users: [id],
          },
        }
      );
    },

    leaveGroup: async ({ id }) => {
      const accessToken = await getRangedAccessToken(
        'groups',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.delete(
        `${url ?? groups.domain}/api/v1/groups/${id}/leave/delete`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    updateGroupPicture: async ({ file, groupId }) => {
      const accessToken = await getRangedAccessToken(
        'groups',
        getAccessTokenSilently
      );
      const instance = getInstance();
      const arrayBuffer = await file.arrayBuffer();

      const { data } = await instance.requestWithFile<SuccessfulResponse>(
        `${url ?? groups.domain}/api/v1/groups/${groupId}/image`,
        { file: arrayBuffer, property: 'image' },
        {},
        'post',
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return data.message;
    },

    changeMemberPermission: async ({ groupId, member, permission }) => {
      const accessToken = await getRangedAccessToken(
        'groups',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.patch(
        `${url ?? groups.domain}/api/v1/users/${member}/groups/patch`,
        {
          group_id: groupId,
          role: permission,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    reactivateGroup: async ({
      expirationDate,
      groupId,
      restorePermissions,
    }) => {
      const accessToken = await getRangedAccessToken(
        'groups',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.put(
        `${url ?? groups.domain}/api/v1/groups/${groupId}/reactivate`,
        {
          restore_permissions: restorePermissions,
          new_expiration_date: expirationDate,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    updateFilePermissions: async ({ fileId, groupId, newPermissions }) => {
      const accessToken = await getRangedAccessToken(
        'groups',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.patch(
        `${url ?? groups.domain}/api/v1/groups/${groupId}/files/update_permissions`,
        {
          file_id: fileId,
          new_permissions: newPermissions,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    getMembers: async (groupId) => {
      const accessToken = await getRangedAccessToken(
        'groups',
        getAccessTokenSilently
      );
      const instance = getInstance();

      const { data } = await instance.get<
        SuccessfulResponse<GroupMemberResponse[]>
      >(`${url ?? groups.domain}/api/v1/groups/${groupId}/users/get`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return data;
    },

    createFolder: async ({ groupId, folderName, folderId }) => {
      const accessToken = await getRangedAccessToken(
        'groups',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.post(
        `${url ?? groups.domain}/api/v1/groups/${groupId}/folders/create`,
        {
          name: folderName,
          path: folderId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    deleteFilePreview: async (groupId, fileId) => {
      const accessToken = await getRangedAccessToken(
        'groups',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.delete(
        `${url ?? groups.domain}/api/v1/groups/${groupId}/files/${fileId}/preview/delete`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    getFilePreview: async (groupId, fileId) => {
      const accessToken = await getRangedAccessToken(
        'groups',
        getAccessTokenSilently
      );
      const instance = getInstance();

      const { data } = await instance.get<
        SuccessfulResponse<FilePreviewResponse>
      >(
        `${url ?? groups.domain}/api/v1/groups/${groupId}/files/${fileId}/preview/get`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return data;
    },

    download: async (groupId, fileId) => {
      const accessToken = await getRangedAccessToken(
        'groups',
        getAccessTokenSilently
      );
      const instance = getInstance();

      const { data } = await instance.get<ArrayBuffer>(
        `${url ?? groups.domain}/api/v1/groups/${groupId}/files/${fileId}/download`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          responseType: 'arraybuffer',
        }
      );

      return new File([data], '');
    },

    getFiles: async ({ groupId, path }) => {
      const accessToken = await getRangedAccessToken(
        'groups',
        getAccessTokenSilently
      );
      const instance = getInstance();

      const { data } = await instance.get<SuccessfulResponse<FileResponse[]>>(
        `${url ?? groups.domain}/api/v1/groups/${groupId}/files/get`,
        {
          params: {
            path: `/${path}`,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return data;
    },

    getFileDetails: async (groupId, fileId) => {
      const accessToken = await getRangedAccessToken(
        'groups',
        getAccessTokenSilently
      );
      const instance = getInstance();

      const { data } = await instance.get<SuccessfulResponse<FileResponse>>(
        `${url ?? groups.domain}/api/v1/groups/${groupId}/files/${fileId}/get`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return data;
    },

    renameFile: async ({ fileId, groupId, newName, newPath }) => {
      const accessToken = await getRangedAccessToken(
        'groups',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.put(
        `${url ?? groups.domain}/api/v1/groups/${groupId}/files/${fileId}/modify`,
        {
          rename: newName,
          new_path: newPath,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    updateContent: async ({ fileId, groupId, content, fileName }) => {
      const accessToken = await getRangedAccessToken(
        'groups',
        getAccessTokenSilently
      );
      const instance = getInstance();
      const file = await content.arrayBuffer();

      await instance.requestWithFile(
        `${url ?? groups.domain}/api/v1/groups/${groupId}/files/${fileId}/content/modify`,
        { file, property: 'file' },
        { name: fileName },
        'put',
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    moveFileToTrash: async (groupId, fileId) => {
      const accessToken = await getRangedAccessToken(
        'groups',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.delete(
        `${url ?? groups.domain}/api/v1/groups/${groupId}/files/${fileId}/delete`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    moveFolderToTrash: async (groupId, folderId) => {
      const accessToken = await getRangedAccessToken(
        'groups',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.delete(
        `${url ?? groups.domain}/api/v1/groups/${groupId}/folders/${folderId}/delete`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    getFolderDetails: async (groupId, folderId) => {
      const accessToken = await getRangedAccessToken(
        'groups',
        getAccessTokenSilently
      );
      const instance = getInstance();

      const { data } = await instance.get<SuccessfulResponse<FileResponse>>(
        `${url ?? groups.domain}/api/v1/groups/${groupId}/folders/${folderId}/get`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return data;
    },

    uploadFile: async ({ file, groupId, name, folderId }) => {
      const accessToken = await getRangedAccessToken(
        'groups',
        getAccessTokenSilently
      );
      const instance = getInstance();
      const fileBuffer = await file.arrayBuffer();

      const { data } = await instance.requestWithFile<
        SuccessfulResponse<NotDeletedFileResponse>
      >(
        `${url ?? groups.domain}/api/v1/groups/${groupId}/files/create`,
        { file: fileBuffer, property: 'file' },
        { name, folderId },
        'post',
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return data;
    },

    copyFiles: async ({ fileId, groupId }) => {
      const accessToken = await getRangedAccessToken(
        'groups',
        getAccessTokenSilently
      );
      const instance = getInstance();

      const { data } = await instance.post<SuccessfulResponse>(
        `${url ?? groups.domain}/api/v1/groups/${groupId}/files/${fileId}/copy`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return data;
    },
  };
};

export default getGroupsService;
