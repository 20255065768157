import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { GroupMemberResponse, Member } from '@app/services/groups/types';
import useGroupsApi from '@app/services/groups/useGroupsApi';
import { SuccessfulResponse } from '@filot/types/api';

const useGroupMembers = ({
  groupId,
  page,
}: {
  groupId: string;
  page: number;
}) => {
  const { getMembers } = useGroupsApi();

  const query = useQuery<
    SuccessfulResponse<GroupMemberResponse[] | undefined>,
    Error,
    Member[]
  >({
    queryFn: () => getMembers(groupId),
    queryKey: ['groups-member', groupId, page],
    placeholderData: keepPreviousData,
    select: ({ data }) =>
      data?.map(
        (member) =>
          ({
            email: member.email,
            id: member.user_id,
            name: member.name,
            permissionLevel: member.role,
            picture: member.picture,
          }) satisfies Member
      ) ?? [],
  });

  return query;
};

export default useGroupMembers;
