import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { User } from '@app/services/organizations/types';
import TablePagination from '@components/table-pagination';

import OrganizationUsersTableRow from './organization-users-table-row';

interface OrganizationUsersTableProps {
  users: User[] | undefined;
  isPlaceholderData: boolean;
  page: number;
  onPageChange: (page: number) => void;
  total: number;
}

const OrganizationUsersTable = ({
  users,
  isPlaceholderData,
  onPageChange,
  page,
  total,
}: OrganizationUsersTableProps) => {
  const { t } = useTranslation();

  return (
    <TableContainer className="!mt-md">
      <Table aria-label="users-table">
        <colgroup>
          <col className="w-1/12" />
          <col className="w-1/3" />
          <col className="w-1/3" />
          {/* <col className="w-1/4" /> */}
          <col className="w-1/5" />
          <col className="w-1/12" />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell className="!text-black/50">
              {t('organization.users.table.header.name')}
            </TableCell>
            <TableCell className="!text-black/50">
              {t('organization.users.table.header.email')}
            </TableCell>
            {/* <TableCell className="!text-black/50">
              {t('organization.users.table.header.lastLogin')}
            </TableCell> */}
            <TableCell className="!text-black/50">
              {t('organization.users.table.header.status')}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {users?.map((user) => (
            <OrganizationUsersTableRow key={user.user_id} user={user} />
          ))}
        </TableBody>
        <TableFooter>
          <TablePagination
            colSpan={6}
            isPlaceholderData={isPlaceholderData}
            onPageChange={onPageChange}
            page={page}
            total={total}
          />
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default OrganizationUsersTable;
