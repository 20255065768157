import { useMutation, useQueryClient } from '@tanstack/react-query';

import { GroupResponse } from '@app/services/groups/types';
import useGroupsApi from '@app/services/groups/useGroupsApi';
import { SuccessfulResponse } from '@filot/types/api';

const useUpdateGroupPicture = () => {
  const { updateGroupPicture } = useGroupsApi();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: updateGroupPicture,
    mutationKey: ['groups-update-picture'],
    onSuccess: (data, { groupId }) => {
      queryClient.setQueryData(
        ['group-details', groupId],
        (oldData: SuccessfulResponse<GroupResponse>) => {
          const match = data.match(/https?:\/\/[^\s]+/);
          const newUrl = match ? match[0] : null;

          if (!newUrl) {
            return oldData;
          }

          return {
            data: { ...oldData.data, icon_image: newUrl },
          };
        }
      );
    },
  });

  return mutation;
};

export default useUpdateGroupPicture;
