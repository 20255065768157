import { useQuery } from '@tanstack/react-query';

import { DeletedFile } from '@app/services/files/types';
import useFilesApi, {
  fileResponseToFile,
} from '@app/services/files/useFilesApi';

const useDeletedFiles = () => {
  const { getDeletedFiles } = useFilesApi();

  const query = useQuery({
    queryFn: () => getDeletedFiles(),
    queryKey: ['files-deleted'],
    select: (data) => {
      if (data) {
        return data.data.map((file) => fileResponseToFile(file) as DeletedFile);
      }

      return null;
    },
  });

  return query;
};

export default useDeletedFiles;
