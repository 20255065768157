import { userCompleteMock } from '@app/test/mocks/user-mock';
import { SuccessfulResponse } from '@filot/types/api';

import { UsersApi, UserResponse } from './types';

const getUserMock = (): UsersApi => ({
  get: (id) =>
    new Promise<SuccessfulResponse<UserResponse>>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res({
            success: true,
            message: 'success',
            data: {
              ...userCompleteMock,
              user_id: id ?? userCompleteMock.user_id,
            },
          });
        }
      }, 1000);
    }),

  update: () =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    }),

  getUserByEmail: async (_email: string) =>
    new Promise<SuccessfulResponse<UserResponse>>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res({
            success: true,
            message: 'success',
            data: userCompleteMock,
          });
        }
      }, 1000);
    }),

  postLogin: async () =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    }),

  updatePicture: async () =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    }),

  removePicture: async () =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    }),
});

export default getUserMock;
