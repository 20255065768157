import { Button } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useSafeValue from '@app/hooks/useSafeValues';
import BackArrow from '@assets/groups/back_arrow.svg?react';
import EditableText from '@components/editable-text';
import PressableIcon from '@components/pressable-icon';

import useEditTag from '../hooks/useEditTag';
import { useTagContext } from '../hooks/useTagContext';

import TagDetailsChangeColorModal, {
  TagNewColor,
} from './tag-details-change-color-modal';

interface TagDetailsHeaderProps {
  onBack: () => void;
  onUpdateColor: (colors: TagNewColor) => void;
}

const TagDetailsHeader = ({ onBack, onUpdateColor }: TagDetailsHeaderProps) => {
  const { t } = useTranslation();
  const tag = useTagContext();
  const { mutateAsync: editTag, isPending } = useEditTag();
  const {
    currentValue,
    resetToSafeValue,
    updateCurrentValue,
    updateSafeValue,
  } = useSafeValue(tag.name);

  const [isChangeColorModalVisible, setIsChangeColorModalVisible] =
    useState(false);

  const handleChangeColor = () => {
    setIsChangeColorModalVisible(true);
  };

  const handleEdit = useCallback(
    async ({ value }: { value: string }) => {
      updateCurrentValue(value);
      try {
        await editTag({ name: value, id: tag.id });
        updateSafeValue(value);
      } catch (error) {
        resetToSafeValue();
      }
    },
    [editTag, resetToSafeValue, updateCurrentValue, updateSafeValue, tag.id]
  );

  return (
    <div className="p-screen flex items-start justify-between pb-0">
      <div className="flex items-center">
        <PressableIcon
          onClick={onBack}
          icon={<BackArrow title="back" className="text-black" />}
        />
        <EditableText
          className="!text-3xl"
          variant="h2"
          name="name"
          defaultValue={currentValue}
          readOnly={isPending}
          onSubmit={handleEdit}
          wrapperClass="hover:bg-black/10"
        />
        <div
          style={{ backgroundColor: tag.backgroundColor }}
          className="ml-sm flex h-6 w-6 items-center justify-center rounded-full"
        >
          <div
            style={{ backgroundColor: tag.textColor }}
            className="h-2 w-2 rounded-full"
          />
        </div>
      </div>
      <Button onClick={handleChangeColor} variant="outlined" size="large">
        {t('tags.tagDetails.header.changeColor')}
      </Button>

      <TagDetailsChangeColorModal
        isOpen={isChangeColorModalVisible}
        onClose={() => setIsChangeColorModalVisible(false)}
        onUpdateColor={onUpdateColor}
      />
    </div>
  );
};

export default TagDetailsHeader;
