import { Trans } from 'react-i18next';
import { useParams } from 'react-router';
import Split from 'react-split';

import useFileDetails from '@app/hooks/useFileDetails';
import ErrorIcon from '@assets/shared/error.svg?react';
import ErrorHelpReport from '@components/error-help-report';
import Loading from '@components/loading';

import FilePreview from './components/file-preview';
import FileVersionsDetails from './components/file-versions-details';
import { FileContext } from './hooks/useFileContext';

const FileVersions = () => {
  const { fileId } = useParams();
  const { data: file, isLoading, isError, refetch } = useFileDetails(fileId!);

  if (isLoading) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <Loading />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <div className="gap-y-lg flex flex-col items-center">
          <ErrorIcon />
          <p className="text-xl">
            <Trans i18nKey="fileVersions.error">
              Error when loading file versions! Please
              <span
                className="cursor-pointer underline"
                onClick={() => refetch()}
              >
                try again
              </span>
            </Trans>
          </p>
          <ErrorHelpReport />
        </div>
      </div>
    );
  }

  if (file) {
    return (
      <FileContext.Provider value={file}>
        <Split
          direction="horizontal"
          className="flex flex-1"
          gutterSize={10}
          sizes={[60, 40]}
          minSize={[700, 600]}
          expandToMin={false}
          gutterAlign="center"
          cursor="col-resize"
        >
          <FilePreview />
          <FileVersionsDetails />
        </Split>
      </FileContext.Provider>
    );
  }
};

export default FileVersions;
