import { Box, Button, FormControl, Modal, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Close from '@assets/shared/close.svg?react';
import PressableIcon from '@components/pressable-icon';
import UserInput from '@components/users-input';

import { useUserInputContext } from '../hooks/useUserInputContext';

interface InviteNewUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (form: {
    users: Array<{ label: string; id: string }>;
    allFromOrganization: boolean;
  }) => void;
}

const InviteNewUserModal = ({
  isOpen,
  onClose,
  onSubmit,
}: InviteNewUserModalProps) => {
  const { t } = useTranslation();

  const { isAvailable } = useUserInputContext();
  const { handleSubmit, control, reset, setValue, watch } = useForm<{
    users: Array<{ label: string; id: string }>;
    allFromOrganization: boolean;
  }>({
    defaultValues: {
      users: [],
      allFromOrganization: true,
    },
  });
  const users = watch('users');
  const [usersWithoutAccount, setUsersWithoutAccount] = useState<string[]>([]);

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  useEffect(() => {
    setUsersWithoutAccount((prev) =>
      prev.filter((userWithoutAccount) =>
        users.some(({ label }) => label === userWithoutAccount)
      )
    );
  }, [users]);

  const handleUserFromOutside = useCallback(
    (hasAccount: boolean, label: string) => {
      if (!hasAccount) {
        setUsersWithoutAccount((prev) => [...prev, label]);
      }
      setValue('allFromOrganization', false);
    },
    [setValue]
  );

  return (
    <Modal className="flex" open={isOpen} onClose={onClose}>
      <Box
        className="w-tags-editor p-screen mx-auto my-auto !flex flex-col rounded bg-white shadow-md"
        id="group-invite"
      >
        <PressableIcon
          onClick={onClose}
          icon={<Close title="close" />}
          className="!absolute self-end !p-0"
        />
        <div className="flex flex-1 items-center justify-between">
          <Typography className="!text-lg">
            {t('organization.users.inviteUsersModal.title')}
          </Typography>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth className="!my-md">
            <Controller
              name="users"
              control={control}
              render={({ field }) => (
                <UserInput
                  {...field}
                  onUserFromOutside={handleUserFromOutside}
                />
              )}
            />
          </FormControl>
          <Button
            variant="contained"
            type="submit"
            className="float-right"
            disabled={
              users.length <= 0 ||
              !isAvailable ||
              usersWithoutAccount.length > 0
            }
          >
            {t('organization.users.inviteUsersModal.invite')}
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default InviteNewUserModal;
