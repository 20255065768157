import {
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

import Discard from '@assets/groups/cancel.svg?react';
import Save from '@assets/groups/save.svg?react';

import PressableIcon from './pressable-icon';

type EditableTextProps = Omit<
  TextFieldProps,
  'variant' | 'onSubmit' | 'color'
> & {
  onSubmit: (form: { value: string; name: string }) => void;
  className?: string;
  defaultValue: string;
  variant?: TypographyProps['variant'];
  name: string;
  readOnly?: boolean;
  color?: string;
  wrapperClass?: string;
  submitOnBlur?: boolean;
};

const EditableText = ({
  defaultValue,
  onSubmit,
  variant,
  name,
  readOnly = false,
  wrapperClass,
  color,
  submitOnBlur = true,
  ...props
}: EditableTextProps) => {
  const { handleSubmit, control, reset, setValue } = useForm({
    defaultValues: { value: defaultValue, name },
  });
  const { t } = useTranslation();

  const [editing, setEditing] = useState(false);
  const shouldSubmitOnBlur = useRef(true);

  useEffect(() => {
    setValue('value', defaultValue);
  }, [defaultValue, setValue]);

  const inputClasses = useMemo(
    () => twMerge('!m-0 !p-2 !font-light !overflow-auto', props.className),
    [props.className]
  );

  const fieldClasses = useMemo(
    () => twMerge('!m-0 !p-0', props.className),
    [props.className]
  );

  const textClasses = useMemo(
    () => twMerge(defaultValue === '' && '!text-black/60', props.className),
    [defaultValue, props.className]
  );

  const textWrapperClasses = useMemo(
    () =>
      twMerge(
        'flex-1 cursor-pointer rounded-sm p-sm duration-200 hover:bg-slate-50',
        wrapperClass
      ),
    [wrapperClass]
  );

  const handleDiscard = () => {
    shouldSubmitOnBlur.current = false;
    reset({ value: defaultValue });
    setEditing(false);
  };

  const handleSave = useCallback(
    (form: { value: string; name: string }) => {
      setEditing(false);
      onSubmit(form);
    },
    [onSubmit]
  );

  const handleTextClick = () => {
    if (!readOnly) {
      shouldSubmitOnBlur.current = true;
      setEditing(true);
    }
  };

  const handleBlur = useCallback(() => {
    setTimeout(() => {
      setEditing(false);
      if (shouldSubmitOnBlur.current && submitOnBlur) {
        void handleSubmit(handleSave)();
      }
    }, 100);
  }, [handleSave, handleSubmit, submitOnBlur]);

  return editing ? (
    <form onSubmit={handleSubmit(handleSave)} className="flex-1">
      <Controller name="name" control={control} render={() => <></>} />
      <Controller
        control={control}
        name="value"
        render={({ field }) => (
          <TextField
            {...field}
            {...props}
            autoFocus
            className={fieldClasses}
            onBlur={handleBlur}
            InputProps={{
              endAdornment: (
                <>
                  <PressableIcon
                    icon={<Save title="save" color={color} />}
                    onClick={handleSubmit(handleSave)}
                    altText={t('components.editableText.save')}
                  />
                  <PressableIcon
                    icon={<Discard title="discard" color={color} />}
                    onClick={handleDiscard}
                    altText={t('components.editableText.discard')}
                  />
                </>
              ),
            }}
            inputProps={{
              className: inputClasses,
              style: { color },
            }}
          />
        )}
      />
    </form>
  ) : (
    <div className={textWrapperClasses} onClick={handleTextClick}>
      <Typography variant={variant} className={textClasses} color={color}>
        {defaultValue === '' ? props.placeholder : defaultValue}
      </Typography>
    </div>
  );
};

export default EditableText;
