import { Card, Typography } from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import { useCallback } from 'react';
import { Link } from 'react-router';

import { useUpdateBreadcrumb } from '@app/hooks/useBreadcrumb';
import { File } from '@app/services/files/types';
import FolderIcon from '@assets/file-icons/shared_folder.svg?react';

interface GroupFolderProps<T> {
  onClick?: (folder: T) => void;
  folder: T;
}

const GroupFolder = <T extends File>({
  folder,
  onClick,
}: GroupFolderProps<T>) => {
  const dispatch = useUpdateBreadcrumb();

  const handleFolderClick = useCallback(() => {
    dispatch({
      type: 'PUSH_FOLDER',
      payload: {
        id: folder.id,
        name: folder.fileName,
        path: folder.path,
      },
    });
    onClick?.(folder);
  }, [dispatch, folder, onClick]);

  return (
    <Link to={`/files/${folder.id}`} onDragStart={(e) => e.preventDefault()}>
      <AnimatePresence>
        <div className="folderCard" id={folder.id} onClick={handleFolderClick}>
          <Card data-testid="group-folder" className="flex h-14">
            <div
              className={`p-sm flex w-full flex-1 cursor-pointer items-center gap-3
              bg-gray-100 transition duration-300 hover:bg-gray-300`}
            >
              <span>
                <FolderIcon width={24} height={24} title="folder" />
              </span>
              <Typography className="overflow-hidden text-ellipsis whitespace-nowrap !text-base !font-medium">
                {folder.fileName}
              </Typography>
            </div>
          </Card>
        </div>
      </AnimatePresence>
    </Link>
  );
};

export default GroupFolder;
