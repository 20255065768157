import { useTranslation } from 'react-i18next';

import { UsersWithAccess } from '@app/services/files/types';

import ManageAccessPeoplePerson from './manage-access-people-person';

interface ManageAccessPeopleProps {
  emails: string[];
  users: UsersWithAccess;
  usersToRender: UsersWithAccess;
  ownerId: string;
  onLoad: (email: string, id: string) => void;
  onRemoveUserById: (id: string) => void;
  onRemoveUserByEmail: (id: string) => void;
}

const ManageAccessPeople = ({
  emails,
  users,
  ownerId,
  usersToRender,
  onLoad,
  onRemoveUserById,
  onRemoveUserByEmail,
}: ManageAccessPeopleProps) => {
  const { t } = useTranslation();

  return (
    <div className="mt-md">
      <p className="px-screen text-xl">
        {t('fileList.fileDetails.manageAccessModal.form.people.title')}
      </p>
      <ul className="gap-y-sm my-sm flex max-h-72 flex-col overflow-y-auto">
        {Object.keys(usersToRender).map((userId) => (
          <ManageAccessPeoplePerson
            userId={userId}
            key={userId}
            accessLevel={users[userId]}
            isOwner={userId === ownerId}
            users={users}
            onRemoveUser={() => onRemoveUserById(userId)}
          />
        ))}
        {emails.map((email) => (
          <ManageAccessPeoplePerson
            onLoad={onLoad}
            email={email}
            key={email}
            accessLevel={null}
            onRemoveUser={() => onRemoveUserByEmail(email)}
          />
        ))}
      </ul>
    </div>
  );
};

export default ManageAccessPeople;
