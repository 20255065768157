import { useMutation } from '@tanstack/react-query';

import { PermissionLevel } from '@app/services/groups/types';
import useGroupsApi from '@app/services/groups/useGroupsApi';

const useLeaveGroup = () => {
  const { leaveGroup, changeMemberPermission } = useGroupsApi();
  const mutation = useMutation<
    void,
    Error,
    { newOwnerId?: string; id: string }
  >({
    mutationFn: async ({ id, newOwnerId }) => {
      if (newOwnerId) {
        await changeMemberPermission({
          groupId: id,
          member: newOwnerId,
          permission: PermissionLevel.OWNER,
        });
      }
      await leaveGroup({ id });
    },
    mutationKey: ['groups-leave-group'],
  });

  return mutation;
};

export default useLeaveGroup;
