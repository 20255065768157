import { TextField, FormControl, Button, Typography } from '@mui/material';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FileToUpload } from '@app/services/files/types';
import TagsInput from '@components/tags-input';
import useTags from '@pages/tags/hooks/useTags';

interface UploadPreviewFormProps {
  onSubmit: SubmitHandler<FileToUpload>;
  document: Omit<FileToUpload, 'tags'>;
}

const UploadPreviewForm = ({ onSubmit, document }: UploadPreviewFormProps) => {
  const { handleSubmit, control, register } = useForm<FileToUpload>({
    defaultValues: {
      name: document.name,
      tags: [],
      mimeType: document.mimeType,
    },
  });
  const { t } = useTranslation();
  const { data } = useTags({ page: 0, rowsPerPage: 10 });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-[0.7] flex-col justify-between"
    >
      <div>
        <Controller
          name="name"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <TextField
              fullWidth
              variant="standard"
              {...field}
              label={t('layout.uploadPreview.fileName')}
              placeholder={t('layout.uploadPreview.placeholders.fileName')}
            />
          )}
        />
        <input {...register('mimeType')} className="hidden" />
        <Typography className="!mt-md">
          {t('layout.uploadPreview.tags')}
        </Typography>
        <div>
          <FormControl fullWidth className="mb-4">
            <Controller
              name="tags"
              control={control}
              render={({ field }) => (
                <TagsInput
                  options={data?.data ?? []}
                  {...field}
                  tagClass="text-sm"
                  inputClass="text-base"
                />
              )}
            />
          </FormControl>
        </div>
      </div>
      <Button
        variant="contained"
        color="primary"
        className="float-right self-end"
        type="submit"
      >
        {t('layout.uploadPreview.upload')}
      </Button>
    </form>
  );
};

export default UploadPreviewForm;
