import { Folder } from '@app/hooks/useBreadcrumb';
import {
  DeletedFile,
  DeletedFileResponse,
  FileCardResponse,
  FilePreviewResponse,
  FileResponse,
  SharedFileResponse,
} from '@app/services/files/types';
import { File, Note } from '@app/services/files/types';
import { TagResponse, TagWithFiles } from '@app/services/tags/types';

export const fileCardResponseMock: FileCardResponse = {
  name: 'test.pdf',
  path: 'fake/path',
  organization_id: 'organization1',
  owner: 'ownerName',
  users_with_access: {
    userA: ['write', 'read'],
  },
  groups_with_access: {},
  extension: '.pdf',
  type: 'document',
  content_id: 'test_id',
  id: 'test_id',
  creation: { created_by: 'system', created_at: '1970-01-01T06:23:42.930Z' },
  modifications: ['1970-01-01T06:28:52.432Z'],
};

export const fileResponseMock: FileResponse = {
  name: 'test.pdf',
  path: 'fake/path',
  organization_id: 'organization1',
  owner: 'ownerName',
  users_with_access: {
    userA: ['write', 'read'],
  },
  tags: [],
  groups_with_access: {},
  extension: '.pdf',
  type: 'document',
  content_id: 'test_id',
  id: 'test_id',
  creation: { created_by: 'system', created_at: '1970-01-01T06:23:42.930Z' },
  modifications: ['1970-01-01T06:28:52.432Z'],
  locked: false,
  size: 123,
  lock_state: null,
};

export const folderResponseMock: FileResponse = {
  name: 'test',
  path: 'fake/path',
  organization_id: 'organization1',
  owner: 'ownerName',
  users_with_access: {
    userA: ['write', 'read'],
  },
  tags: [],
  groups_with_access: {},
  extension: '.folder',
  type: 'document',
  content_id: 'test_id',
  id: 'test_id',
  creation: { created_by: 'system', created_at: '1970-01-01T06:23:42.930Z' },
  modifications: ['1970-01-01T06:28:52.432Z'],
  locked: false,
  size: 123,
  lock_state: null,
};

export const tagResponseMock: TagResponse = {
  id: 'tag1',
  name: 'tag1',
  styling: {
    background_color: '#000',
    text_color: '#fff',
  },
  description: null,
  files: ['test_id', 'test_id'],
  files_count: 3,
  latest_use: '2024-10-23',
  organization_id: 'org_id',
};

export const fileMock: File = {
  id: 'test_id',
  users: { userA: ['write', 'read'], userB: ['create_notes', 'delete'] },
  groups: {},
  fileName: 'test.pdf',
  path: 'fake/path/test.pdf',
  kind: 'REGULAR',
  type: 'pdf',
  content: 'dfasf',
  creation: '2024-10-10T01:46:45.283000',
  keys: { key1: 'test' },
  lastModified: '2024-10-10T01:46:45.283000',
  organizationName: 'organization1',
  tags: [
    {
      id: 'tags',
      name: 'tags',
      backgroundColor: '#968DFF',
      textColor: '#fff',
      description: null,
    },
  ],
  owner: 'Owner Id',
  locked: false,
  size: 123,
  lock: null,
};

export const file2Mock: File = {
  id: 'test2_id',
  users: { userA: ['write', 'read'] },
  groups: {},
  fileName: 'test2.pdf',
  path: 'other/path/test2.pdf',
  kind: 'REGULAR',
  type: 'pdf',
  content: 'dfasf',
  creation: '2024-10-10T01:46:45.283000',
  keys: { key1: 'test' },
  lastModified: '2024-10-10T01:46:45.283000',
  organizationName: 'organization1',
  tags: [
    {
      id: 'tags',
      name: 'tags',
      backgroundColor: '#5000ed',
      textColor: '#fff',
      description: null,
    },
  ],
  owner: 'Owner Id',
  locked: false,
  size: 123,
  lock: null,
};

export const file3Mock: File = {
  id: 'test3_id',
  users: { userA: ['write', 'read'] },
  groups: {},
  fileName: 'test.pdf',
  path: 'fake/path/test.pdf',
  kind: 'REGULAR',
  type: 'pdf',
  content: 'dfasf',
  creation: '2024-10-10T01:46:45.283000',
  keys: { key1: 'test' },
  lastModified: '2024-10-10T01:46:45.283000',
  organizationName: 'A organization',
  tags: [
    {
      id: 'tags',
      name: 'tags',
      backgroundColor: '#5000ed',
      textColor: '#fff',
      description: null,
    },
  ],
  owner: 'Owner Id',
  locked: false,
  size: 123,
  lock: null,
};

export const file4Mock: File = {
  id: 'test4_id',
  users: { userA: ['write', 'read'] },
  groups: {},
  fileName: 'test.pdf',
  path: 'fake/path/test.pdf',
  kind: 'REGULAR',
  type: 'pdf',
  content: 'dfasf',
  creation: '2024-10-10T01:46:45.283000',
  keys: { key1: 'test' },
  lastModified: '2024-10-10T01:46:45.283000',
  organizationName: 'A organization',
  tags: [
    {
      id: 'tag2321',
      name: 'tags',
      backgroundColor: '#5000ed',
      textColor: '#fff',
      description: null,
    },
  ],
  owner: 'Owner Id',
  locked: true,
  size: 123,
  lock: {
    lockedAt: '2025-01-16T20:35:54.000Z',
    lockedBy: 'user|fdajij32i42u34',
    unlocksAt: '2025-01-16T23:35:54.000Z',
  },
};

export const file5Mock: File = {
  id: 'test5_id',
  users: { userA: ['write', 'read'] },
  groups: {},
  fileName: 'test.pdf',
  path: 'fake/path/test.pdf',
  kind: 'REGULAR',
  type: 'another type',
  content: 'dfasf',
  creation: '2024-10-10T01:46:45.283000',
  keys: { key1: 'test' },
  lastModified: '2024-10-10T01:46:45.283000',
  organizationName: 'A organization',
  tags: [
    {
      id: 'tags',
      name: 'tags',
      backgroundColor: '#5000ed',
      textColor: '#fff',
      description: null,
    },
  ],
  owner: 'Owner Id',
  locked: false,
  size: 123,
  lock: null,
};

export const file6Mock: File = {
  id: 'test6_id',
  users: { userA: ['write', 'read'] },
  groups: {},
  fileName: 'test.pdf',
  path: 'fake/path/test.pdf',
  kind: 'REGULAR',
  type: 'another type',
  content: 'dfasf',
  creation: '2024-10-10T01:46:45.283000',
  keys: { key1: 'test' },
  lastModified: '2024-10-10T01:46:45.283000',
  organizationName: 'A organization',
  tags: [
    {
      id: 'tag file 6',
      name: 'tag of file 6',
      backgroundColor: '#ff0ff0',
      textColor: '#fff',
      description: null,
    },
  ],
  owner: 'Owner Id',
  locked: false,
  size: 123,
  lock: null,
};

export const folderMock: File = {
  id: 'folder_id',
  users: { userA: ['write', 'read'] },
  groups: {},
  fileName: 'test',
  path: 'fake/path/test',
  type: 'folder',
  kind: 'folder',
  content: 'dfasf',
  creation: '2024-01-10T01:46:45.283000',
  keys: { key1: 'test' },
  lastModified: '2024-10-10T01:46:45.283000',
  organizationName: 'organization2',
  tags: [
    {
      id: 'tags',
      name: 'tags',
      backgroundColor: '#5000ed',
      textColor: '#fff',
      description: null,
    },
  ],
  owner: 'Owner Id',
  locked: false,
  size: 123,
  lock: null,
};

export const systemFolderMock: File = {
  id: 'folder_id',
  users: { userA: ['write', 'read'] },
  groups: {},
  fileName: 'test',
  path: '/fake/path/test',
  type: 'SYSTEM_FOLDER',
  kind: 'SYSTEM_FOLDER',
  content: 'dfasf',
  creation: '2024-10-10T01:46:45.283000',
  keys: { key1: 'test' },
  lastModified: '2024-10-10T01:46:45.283000',
  organizationName: 'organization2',
  tags: [
    {
      id: 'tags',
      name: 'tags',
      backgroundColor: '#5000ed',
      textColor: '#fff',
      description: null,
    },
  ],
  owner: 'Owner Id',
  locked: false,
  size: 123,
  lock: null,
};

export const folder2Mock: File = {
  id: 'folder2_id',
  users: { userA: ['write', 'read'] },
  groups: {},
  fileName: 'test',
  path: 'fake/path/test',
  type: 'folder',
  kind: 'folder',
  content: 'dfasf',
  creation: '2024-01-10T01:46:45.283000',
  keys: { key1: 'test' },
  lastModified: '2024-10-10T01:46:45.283000',
  organizationName: 'organization2',
  tags: [
    {
      id: 'tags',
      name: 'tags',
      backgroundColor: '#5000ed',
      textColor: '#fff',
      description: null,
    },
  ],
  owner: 'Owner Id',
  locked: false,
  size: 123,
  lock: null,
};

export const folder3Mock: File = {
  id: 'folder3_id',
  users: { userA: ['write', 'read'] },
  groups: {},
  fileName: 'test',
  path: 'fake/path/test',
  type: 'folder',
  kind: 'folder',
  content: 'dfasf',
  creation: '2024-10-10T01:46:45.283000',
  keys: { key1: 'test' },
  lastModified: '2024-10-10T01:46:45.283000',
  organizationName: 'organization2',
  tags: [
    {
      id: 'tags',
      name: 'tags',
      backgroundColor: '#5000ed',
      textColor: '#fff',
      description: null,
    },
  ],
  owner: 'Owner Id',
  locked: false,
  size: 123,
  lock: null,
};

export const tagMock: TagWithFiles = {
  id: 'tag1',
  name: 'tag1',
  backgroundColor: '#000',
  description: null,
  textColor: '#fff',
  files: ['test2_id', 'test_id'],
};

export const tag2Mock: TagWithFiles = {
  id: 'tag2',
  name: 'tag2',
  backgroundColor: '#f0f',
  description: null,
  textColor: '#000',
  files: ['test2_id', 'test_id'],
};

export const noteMock: Note = {
  id: 'note_id',
  content: 'note mock',
  created_at: '2024-04-25',
  user_id: 'User from test',
  file_id: 'file-ID',
};

export const sharedFileMock: SharedFileResponse = {
  file_id: 'file_id',
  groups_with_access: [],
  users_with_access: {
    'user 1': ['write', 'read'],
    'user 2': ['write', 'read'],
  },
};

export const filePreviewMock: FilePreviewResponse = {
  file_id: 'file_id',
  users_viewing: [],
  link: 'https://preview.com',
};

export const deletedFileResponseMock: DeletedFileResponse = {
  name: 'test.pdf',
  path: 'fake/path/test.pdf',
  organization_id: 'organization1',
  owner: 'ownerName',
  users_with_access: {
    userA: ['write', 'read'],
  },
  tags: [],
  groups_with_access: {},
  extension: '.pdf',
  type: 'document',
  content_id: 'test_id',
  id: 'test_id',
  creation: { created_by: 'system', created_at: '1970-01-01T06:23:42.930Z' },
  modifications: ['1970-01-01T06:28:52.432Z'],
  deletion: { deleted_at: '2024-10-23', deleted_by: 'User Name' },
  locked: false,
  size: 123,
  lock_state: null,
};

export const deletedFileMock: DeletedFile = {
  id: 'deleted_test_id',
  users: { userA: ['write', 'read'] },
  groups: {},
  fileName: 'test.pdf',
  path: 'fake/path/test.pdf',
  kind: 'REGULAR',
  type: 'pdf',
  content: 'dfasf',
  creation: '2024-10-10T01:46:45.283000',
  keys: { key1: 'test' },
  lastModified: '2024-10-10T01:46:45.283000',
  organizationName: 'A organization',
  tags: [],
  owner: 'Owner Id',
  deletion: { deletedAt: '2024-10-23', deletedBy: 'User Name' },
  locked: false,
  size: 123,
  lock: null,
};

export const rootFolder: Folder = {
  name: 'root',
  id: 'root',
  path: '/',
};

export const otherFolder: Folder = {
  name: 'other',
  id: 'other',
  path: '/other',
};

export const lastFolder: Folder = {
  name: 'last',
  id: 'last',
  path: '/other/last',
};
