import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import getDocumentRenderer from '@app/document-renderers/document';
import getImageRenderer from '@app/document-renderers/image';
import getTextRenderer from '@app/document-renderers/text';
import Sync from '@assets/files/cloud-sync.svg?react';
import Download from '@assets/files/download.svg?react';
import Back from '@assets/groups/back_arrow.svg?react';
import FileIcon from '@components/file-icon';
import NoPreview from '@components/no-preview';
import PressableIcon from '@components/pressable-icon';

import useFileContext from '../hooks/useFileContext';

const FilePreview = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const file = useFileContext();

  const handleBack = () => {
    navigate(-1);
  };

  const handleDownload = () => {};

  const handleSync = () => {};

  if (!file) {
    return null;
  }

  return (
    <div className="py-md bg-gray-200">
      <div className="px-screen flex items-center justify-between">
        <div className="gap-x-sm flex flex-row items-center">
          <PressableIcon
            onClick={handleBack}
            icon={<Back title="back" className="text-black" />}
          />
          <FileIcon type={file.type} width={32} height={32} />
          <h1 className="text-2xl">{file.fileName}</h1>
        </div>
        <div>
          <PressableIcon
            onClick={handleDownload}
            icon={
              <Download
                height={20}
                width={20}
                title="download"
                className="text-gray-200"
              />
            }
            altText={t('fileVersions.preview.download')}
          />
          <PressableIcon
            onClick={handleSync}
            icon={
              <Sync
                height={20}
                width={20}
                title="sync"
                className="text-gray-200"
              />
            }
            altText={t('fileVersions.preview.sync')}
          />
        </div>
      </div>
      <div
        className={`scrollbar-thumb-rounded-md scrollbar-thin scrollbar-thumb-gray-300
                    px-sm mx-screen scrollbar-track-black/1 h-[83vh] overflow-auto`}
      >
        <div className="pb-screen m-auto w-fit" data-testid="preview-viewer">
          <DocViewer
            className="mt-md !bg-transparent"
            config={{
              header: { disableHeader: true },
              noRenderer: {
                overrideComponent: NoPreview,
              },
            }}
            documents={[{ uri: '' }]}
            pluginRenderers={[
              ...DocViewerRenderers.filter(
                (docRenderer) => !docRenderer.fileTypes.includes('txt')
              ),
              getTextRenderer(),
              getImageRenderer('overflow-hidden'),
              getDocumentRenderer('', { allPages: true }),
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default FilePreview;
