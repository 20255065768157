import { GenericError, useAuth0 } from '@auth0/auth0-react';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';

import * as Auth from '@filot/auth';

import useGetUser, { UserKeyValue } from './useGetUser';

const useGetTokens = () => {
  const navigate = useNavigate();

  const getUser = useGetUser();
  const { getAccessTokenSilently } = useAuth0();

  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const [user, setUser] = useState<UserKeyValue | null>(null);

  const getToken = useCallback(
    async (callback: () => void) => {
      setLoading(true);
      try {
        const tokenGot = await getAccessTokenSilently({
          authorizationParams: { audience: Auth.configs.files.audience },
        });
        setToken(tokenGot);
        setUser(await getUser());
        setTimeout(() => {
          callback();
        }, 500);
      } catch (e) {
        if (e instanceof GenericError && e.error === 'login_required') {
          navigate('/load-login');
        }
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently, getUser, navigate]
  );

  return {
    getToken,
    loading,
    token,
    user,
  };
};

export default useGetTokens;
