import { Modal, Box } from '@mui/material';
import { SubmitHandler } from 'react-hook-form';

import { AdvancedSearchFormInputsFormatted } from '@app/services/files/types';
import Close from '@assets/shared/close.svg?react';
import PressableIcon from '@components/pressable-icon';

import AdvancedSearchForm from './advanced-search-form';

interface AdvancedSearchModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSearch: SubmitHandler<AdvancedSearchFormInputsFormatted>;
}

const AdvancedSearchModal = ({
  isOpen,
  onClose,
  onSearch,
}: AdvancedSearchModalProps) => {
  const handleSearch: SubmitHandler<AdvancedSearchFormInputsFormatted> = (
    params
  ) => {
    onSearch(params);
    onClose();
  };

  return (
    <Modal
      className="flex"
      open={isOpen}
      onClose={onClose}
      disableEscapeKeyDown
    >
      <Box className="p-screen max-h-3/4 mx-auto my-auto !flex w-3/5 flex-col overflow-auto rounded bg-white shadow-md">
        <PressableIcon
          onClick={onClose}
          icon={<Close title="close" />}
          className="self-end"
        />
        <AdvancedSearchForm onSubmit={handleSearch} />
      </Box>
    </Modal>
  );
};

export default AdvancedSearchModal;
