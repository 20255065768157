import { ButtonBase, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import useAlert from '@app/hooks/useAlert';
import DeleteForever from '@assets/trash/delete-forever.svg?react';
import RestoreIcon from '@assets/trash/restore.svg?react';
import StopClickPropagation from '@components/stop-click-propagation';

import useRestoreFile from '../hooks/useRestoreFile';

import { DeletedFileDetailsContext } from './deleted-file-details';

interface DeletedFileDetailsButtonsProps {
  onDeleteForever: () => void;
}

const ICON_SIZE = 16;

const DeletedFileDetailsButtons = ({
  onDeleteForever,
}: DeletedFileDetailsButtonsProps) => {
  const { t } = useTranslation();
  const { mutateAsync: restoreFile, isPending } = useRestoreFile();
  const file = useContext(DeletedFileDetailsContext);
  const { displayGlobal } = useAlert();
  const queryClient = useQueryClient();

  const handleRestore = async () => {
    try {
      await restoreFile({ fileId: file!.id });
      await queryClient.invalidateQueries({ queryKey: ['files'] });
      await queryClient.invalidateQueries({ queryKey: ['files-deleted'] });
    } catch (e) {
      displayGlobal({
        text: t('trash.deletedFileDetails.restoreFile.error'),
        type: 'error',
      });
    }
  };

  return (
    <div className="mt-md flex flex-col" data-tour="preview-document-step-3">
      <StopClickPropagation>
        <ButtonBase
          focusRipple
          onClick={handleRestore}
          disabled={isPending}
          className="!p-sm !justify-start"
          data-tour="preview-document-step-2"
        >
          <RestoreIcon
            className="mr-sm text-black"
            width={ICON_SIZE}
            height={ICON_SIZE}
          />
          <Typography>
            {t('trash.deletedFileDetails.buttons.restore')}
          </Typography>
        </ButtonBase>
      </StopClickPropagation>
      <StopClickPropagation>
        <ButtonBase
          focusRipple
          onClick={onDeleteForever}
          className="!p-sm !justify-start"
          data-tour="preview-document-step-4"
        >
          <DeleteForever
            className="mr-sm"
            width={ICON_SIZE}
            height={ICON_SIZE}
          />
          <Typography>
            {t('trash.deletedFileDetails.buttons.deleteForever')}
          </Typography>
        </ButtonBase>
      </StopClickPropagation>
    </div>
  );
};

export default DeletedFileDetailsButtons;
