import { useLocation } from 'react-router';

const useGetFolderCreationLocation = () => {
  const location = useLocation();

  return () => {
    const match = location.pathname.match(/^\/files\/(.*)?$/);
    const matchGroups = location.pathname.match(
      /^\/groups\/(\w+)\/files\/(.*)?$/
    );

    let folderId: string | undefined;
    let groupId: string | undefined;
    if (match) {
      folderId = match.at(1);
    }

    if (matchGroups) {
      folderId = matchGroups?.at(2);
      groupId = matchGroups.at(1);
    }

    return {
      folderId,
      groupId,
    };
  };
};

export default useGetFolderCreationLocation;
