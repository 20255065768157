import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';

import { isRunningOnElectron } from '@app/utils/environment';
import * as Storage from '@app/utils/storage';
import useLockFile from '@pages/files/hooks/useLockFile';

import { useLockedFiles, useUpdateLockedFiles } from './useLockedFiles';

interface LoginOptions {
  redirectUri?: string;
  web?: boolean;
}

interface LogoutOptions {
  redirectUri?: string;
}

const useAuth = () => {
  const { logout: auth0Logout, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutateAsync: lockFile } = useLockFile();
  const dispatch = useUpdateLockedFiles();
  const { lockedFileIds } = useLockedFiles();

  /* v8 ignore start */
  const login = async ({ redirectUri, web = false }: LoginOptions = {}) => {
    if (isRunningOnElectron()) {
      await window.auth.login();
    } else {
      const redirectToApp = redirectUri ? `&redirectUri=${redirectUri}` : '';
      const client = web ? 'client=web' : '';
      await loginWithRedirect({
        authorizationParams: {
          redirect_uri: `${location.origin}/login-redirect?${client}${redirectToApp}`,
        },
      });
    }
  };

  const logout = useCallback(
    async ({ redirectUri }: LogoutOptions = {}) => {
      await Promise.all(
        lockedFileIds.map((lockedFileId) =>
          lockFile({ fileId: lockedFileId, lock: false })
        )
      );
      dispatch({ type: 'RESET_FILES' });
      queryClient.clear();
      if (isRunningOnElectron()) {
        window.auth.resetTokens();
        await Storage.deleteUser();
      }
      const redirect = redirectUri ? `redirectToApp=${redirectUri}` : '';
      await auth0Logout({
        logoutParams: {
          returnTo: isRunningOnElectron()
            ? 'https://electron-app.filot.io/logout-callback'
            : `${location.origin}?${redirect}`,
          federated: false,
        },
        openUrl: async (url) => {
          if (isRunningOnElectron()) {
            await window.auth.logout(url);
            void navigate('/');
          } else {
            window.location.replace(url);
          }
        },
      });
    },
    [auth0Logout, dispatch, lockFile, lockedFileIds, navigate, queryClient]
  );
  /* v8 ignore stop */

  return {
    login,
    logout,
  };
};

export default useAuth;
