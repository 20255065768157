import { useAuth0 } from '@auth0/auth0-react';

import { getEnv } from '@app/config/env';

import { tagResponseToTag, tagToTagResponse } from '../tags/useTagsApi';

import getFilesMock from './mock';
import { getFilesService } from './service';
import { File, FileResponse, FilesApi } from './types';

const useFilesApi = (): FilesApi => {
  const { getAccessTokenSilently } = useAuth0();
  if (getEnv().mockApiCalls) {
    return getFilesMock();
  }

  return getFilesService({ getAccessTokenSilently });
};

const fileResponseToFile = (fileResponse: FileResponse): File =>
  ({
    id: fileResponse.id,
    path: fileResponse.path,
    fileName: fileResponse.name,
    tags: fileResponse.tags.map((tag) =>
      typeof tag === 'object' ? tagResponseToTag(tag) : ''
    ),
    organizationName: fileResponse.organization_id,
    type: fileResponse.extension.replace('.', ''),
    kind: fileResponse.type,
    owner: fileResponse.owner,
    lastModified:
      fileResponse.modifications.at(0) ?? fileResponse.creation.created_at,
    creation: fileResponse.creation.created_at,
    content: '',
    keys: {},
    users: fileResponse.users_with_access,
    groups: fileResponse.groups_with_access,
    deletion:
      fileResponse.deletion !== undefined
        ? {
            deletedAt: fileResponse.deletion.deleted_at,
            deletedBy: fileResponse.deletion.deleted_by,
          }
        : null,
    size: fileResponse.size,
    locked: fileResponse.locked,
    lock: fileResponse.lock_state
      ? {
          lockedAt: fileResponse.lock_state.locked_at,
          lockedBy: fileResponse.lock_state.locked_by,
          unlocksAt: fileResponse.lock_state.unlocks_at,
        }
      : null,
  }) as File;

const fileToFileResponse = (file: File): FileResponse => {
  const fileNameParts = file.fileName.split('.');
  const extension = fileNameParts.length > 1 ? fileNameParts.at(-1) : '';
  const name = fileNameParts.join('.');

  return {
    name,
    path: file.path,
    organization_id: file.organizationName,
    owner: file.owner,
    users_with_access: file.users,
    groups_with_access: file.groups,
    extension: `.${extension}` || '',
    type: file.kind,
    content_id: file.id,
    id: file.id,
    creation: {
      created_by: 'system',
      created_at: file.creation,
    },
    modifications: [],
    deletion: file.deletion
      ? {
          deleted_at: file.deletion.deletedAt,
          deleted_by: file.deletion.deletedBy,
        }
      : null,
    locked: file.locked,
    lock_state: file.lock
      ? {
          locked_at: file.lock.lockedAt,
          locked_by: file.lock.lockedBy,
          unlocks_at: file.lock.unlocksAt,
        }
      : null,
    size: file.size,
    tags: file.tags.map((tag) => tagToTagResponse(tag)),
  } as FileResponse;
};

export { fileResponseToFile, fileToFileResponse };

export default useFilesApi;
