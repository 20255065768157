import { useMutation } from '@tanstack/react-query';

import useFilesApi from '@app/services/files/useFilesApi';
import useGroupsApi from '@app/services/groups/useGroupsApi';

const useMoveFileToTrash = (groupId?: string) => {
  const { moveFileToTrash } = useFilesApi();
  const { moveFileToTrash: groupMoveFileToTrash } = useGroupsApi();

  const mutation = useMutation<void, Error, string>({
    mutationFn: (variables) => {
      if (groupId) {
        return groupMoveFileToTrash(groupId, variables);
      }

      return moveFileToTrash(variables);
    },
    mutationKey: ['file-move-to-trash'],
  });

  return mutation;
};

export default useMoveFileToTrash;
