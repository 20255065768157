import { Button, Typography } from '@mui/material';
import { Context, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { File } from '@app/services/files/types';
import UsersAvatar from '@components/users-avatar';

import { FileDetailsContext } from './file-details';
import ManageAccessModal from './manage-access-modal';

interface FileDetailsPeopleProps {
  usersId: string[];
  ownerId: string;
  fileDetailsContext?: Context<File | null>;
}

const FileDetailsPeople = ({
  usersId,
  ownerId,
  fileDetailsContext = FileDetailsContext,
}: FileDetailsPeopleProps) => {
  const { t } = useTranslation();
  const file = useContext(fileDetailsContext);
  const [isManageAccessModalVisible, setIsManageAccessModalVisible] =
    useState(false);

  return (
    <>
      <div>
        <Typography className="!mb-sm">
          {t('fileList.fileDetails.users.peopleWithAccess')}
        </Typography>
        <div className="flex">
          <UsersAvatar
            usersId={usersId}
            ownerId={ownerId}
            onAdd={() => setIsManageAccessModalVisible(true)}
          />
        </div>
        <Button
          variant="outlined"
          color="primary"
          className="!mt-sm !px-md"
          onClick={() => setIsManageAccessModalVisible(true)}
        >
          {t('fileList.fileDetails.users.manageAccess')}
        </Button>
      </div>
      <ManageAccessModal
        isOpen={isManageAccessModalVisible}
        onClose={() => setIsManageAccessModalVisible(false)}
        file={file}
        ownerId={ownerId}
      />
    </>
  );
};

export default FileDetailsPeople;
