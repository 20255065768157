import { useQuery } from '@tanstack/react-query';

import {
  AdvancedSearchFormInputsFormatted,
  SortOption,
} from '@app/services/files/types';
import useFilesApi from '@app/services/files/useFilesApi';

interface UseFilesSearchOptions {
  filters: Partial<AdvancedSearchFormInputsFormatted>;
  sortBy?: SortOption;
  sortDirection?: 'asc' | 'desc';
}

const useFilesSearch = ({ filters }: UseFilesSearchOptions) => {
  const { advancedSearch } = useFilesApi();

  const query = useQuery({
    queryFn: () => advancedSearch(filters),
    queryKey: ['files-search', ...Object.values(filters)],
    select: (data) => (!data ? [] : data.data),
    enabled: false,
  });

  return query;
};

export default useFilesSearch;
