import { useEffect } from 'react';

import { useAppLocation } from '@app/hooks/useAppLocation';
import { useBreadcrumb, useUpdateBreadcrumb } from '@app/hooks/useBreadcrumb';
import { File } from '@app/services/files/types';

const useFolderIdToBreadcrumb = () => {
  const dispatch = useUpdateBreadcrumb();
  const { folders } = useBreadcrumb();
  const location = useAppLocation<{ file: File }>();
  const file = location.state?.file;

  useEffect(() => {
    if (location.pathname === '/files') {
      dispatch({
        type: 'SET_FOLDERS',
        payload: [],
      });
    }
    if (file && folders.length === 0) {
      dispatch({
        type: 'SET_FOLDERS',
        payload: [{ id: file.id, name: file.fileName, path: file.path }],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useFolderIdToBreadcrumb;
