import { Button } from '@mui/material';
import { HttpStatusCode } from 'axios';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useRouteError } from 'react-router';

import useAuth from '@app/hooks/useAuth';
import NotFoundImage from '@assets/error/not-found.svg?react';
import ErrorHelpReport from '@components/error-help-report';
import Logo from '@components/logo';
import { RequestError } from '@filot/types/errors';

const Error = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const error = useRouteError();
  const { t } = useTranslation();

  useEffect(() => {
    if (
      error instanceof RequestError &&
      error.status === HttpStatusCode.Unauthorized
    ) {
      void logout({ redirectUri: error.originUrl });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <div className="py-screen flex flex-1 flex-col">
      <header className="flex justify-center">
        <Logo />
      </header>
      <div className="gap-x-lg py-lg flex flex-1 items-center justify-between px-[max(15vw,40px)]">
        <div className="flex-1">
          <h1 className="mb-lg text-5xl font-bold">{t('error.title')}</h1>
          <p className="text-3xl font-medium">{t('error.text')}</p>
          <div className="my-lg gap-x-sm flex">
            <Button
              variant="contained"
              onClick={() => navigate('/files')}
              className="!w-32"
            >
              {t('error.notFound.buttons.home')}
            </Button>
          </div>
          <ErrorHelpReport />
        </div>
        <div className="flex flex-1 flex-col items-end">
          <NotFoundImage width={380} height={380} />
        </div>
      </div>
    </div>
  );
};

export default Error;
