import { DateTime } from 'luxon';
import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTimer } from 'react-timer-hook';

import { LockState } from '@app/services/files/types';
import { isRunningOnElectron } from '@app/utils/environment';

import { FileDetailsContext } from './file-details';

interface LockRemainingTimeProps {
  lock: LockState;
}

const LockRemainingTime = ({ lock }: LockRemainingTimeProps) => {
  const { t } = useTranslation();
  const file = useContext(FileDetailsContext);
  const unlocksAt = useMemo(
    () => DateTime.fromISO(lock.unlocksAt),
    [lock.unlocksAt]
  );
  const { seconds, minutes, hours, start, pause } = useTimer({
    expiryTimestamp: unlocksAt.toJSDate(),
    onExpire: () => {},
  });

  useEffect(() => {
    if (unlocksAt.diffNow('seconds').seconds > 0) {
      start();
      if (isRunningOnElectron()) {
        const alertTime = unlocksAt.minus({ hours: 1, minutes: 30 });

        void window.notification.schedule(
          {
            title: t('fileList.fileDetails.buttons.lock.notification.title'),
            body: t('fileList.fileDetails.buttons.lock.notification.body', {
              file: file!.fileName,
            }),
          },
          alertTime.toJSDate()
        );
      }
    }

    return () => {
      pause();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pause, unlocksAt]);

  return unlocksAt.diffNow('seconds').seconds > 0 ? (
    <p className="ml-md text-center text-sm">
      {t('fileList.fileDetails.buttons.lock.remainingTime', {
        time: `${hours
          .toString()
          .padStart(
            2,
            '0'
          )}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`,
      })}
    </p>
  ) : null;
};

export default LockRemainingTime;
