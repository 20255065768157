import { useQuery, useQueryClient } from '@tanstack/react-query';

import { File } from '@app/services/files/types';
import useFilesApi, {
  fileResponseToFile,
} from '@app/services/files/useFilesApi';

interface UseFilesOptions {
  folderId?: string;
}

const ROOT_NAME = 'root';

const useFiles = ({ folderId }: UseFilesOptions = {}) => {
  const { get, getRootContent } = useFilesApi();
  const queryClient = useQueryClient();

  const query = useQuery({
    queryFn: async () => {
      if (folderId === ROOT_NAME) {
        let rootFolderId = (
          queryClient.getQueryData(['root-folder']) as File | null
        )?.id;

        if (!rootFolderId) {
          const rootResponse = await getRootContent();
          if (rootResponse) {
            queryClient.setQueryData(['root-folder'], () =>
              fileResponseToFile(rootResponse.data)
            );
          }
          rootFolderId = rootResponse?.data.id;
        }

        if (rootFolderId) {
          return get({ folderId: rootFolderId });
        }

        return undefined;
      }

      return get({ folderId });
    },
    queryKey: ['files', folderId],
    select: (data) =>
      !data ? [] : data.data.map((file) => fileResponseToFile(file)),
  });

  return query;
};

export default useFiles;
