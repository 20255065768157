import { DateTime } from 'luxon';

import {
  Paginated,
  PaginationOptions,
  SuccessfulResponse,
} from '@filot/types/api';

import {
  FileAccessLevel,
  FilePreviewResponse,
  FileResponse,
  NotDeletedFileResponse,
  UploadFileRequest,
} from '../files/types';

export enum PermissionLevel {
  CLIENT = 'CLIENT',
  REVIEWER = 'REVIEWER',
  MANAGER = 'MANAGER',
  ADMIN = 'ADMIN',
  OWNER = 'OWNER',
}

export type UserRoles = Record<string, PermissionLevel>;

export type Status = 'active' | 'inactive';

export interface AccessDates {
  startDate: DateTime;
  expirationDate: DateTime | null;
}

export interface NewGroup {
  name: string;
  startDate: DateTime;
  expirationDate: DateTime | null;
}

export type Group = {
  id: string;
  name: string;
  description: string | null;
  picture?: string;
  users: UserRoles;
  totalFiles: number;
  startDate: string;
  expirationDate?: string;
  status: Status;
};

export type GroupResponse = {
  name: string;
  description: string;
  icon_image: string;
  users: Partial<UserRoles>;
  total_files: number;
  start_date: string;
  expiration_date?: string;
  id: string;
  status: Status;
};

export interface NewGroupRequest {
  name: string;
  startDate: string;
  expirationDate: string | null;
}

export interface Member {
  id: string;
  name: string;
  email: string;
  permissionLevel: PermissionLevel;
  picture?: string;
}

export interface NewGroupOwner {
  userId: string;
}

export interface GroupMemberResponse {
  /** ISO date */
  created_at: string;
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  name: string;
  nickname: string;
  picture?: string;
  updated_at: string;
  user_id: string;
  last_login: string;
  role: PermissionLevel;
}

export interface GroupsApi {
  get: (
    paginationOptions: PaginationOptions,
    userId?: string
  ) => Promise<Paginated<GroupResponse>>;
  getDetails: (id: string) => Promise<SuccessfulResponse<GroupResponse>>;
  updateFilePermissions: (options: {
    groupId: string;
    fileId: string;
    newPermissions: FileAccessLevel[];
  }) => Promise<void>;
  removeFile: (options: { groupId: string; fileId: string }) => Promise<void>;
  createGroup: (form: NewGroupRequest) => Promise<Group>;
  getFiles: (options: {
    groupId: string;
    path: string;
  }) => Promise<SuccessfulResponse<FileResponse[]>>;
  bulkEdit: (options: {
    groupId: string;
    fileIds: string[];
    permissions: FileAccessLevel[];
  }) => Promise<void>;
  getMembers: (
    groupId: string
  ) => Promise<SuccessfulResponse<GroupMemberResponse[]>>;
  changeMemberPermission: (params: {
    member: string;
    groupId: string;
    permission: PermissionLevel;
  }) => Promise<void>;
  inviteUsers: (params: { emails: string[]; groupId: string }) => Promise<void>;
  removeUser: (params: { id: string; groupId: string }) => Promise<void>;
  deleteGroup: (groupId: string) => Promise<void>;
  updateGroupDetails: (options: {
    groupId: string;
    name?: string;
    description?: string;
    startDate?: string;
    expirationDate?: string | null;
  }) => Promise<void>;
  reactivateGroup: (params: {
    groupId: string;
    startDate: string;
    expirationDate: string | null;
    restorePermissions: boolean;
  }) => Promise<void>;
  leaveGroup: (params: { id: string }) => Promise<void>;
  updateGroupPicture: (params: {
    file: globalThis.File;
    groupId: string;
  }) => Promise<string>;
  getFileDetails: (
    groupId: string,
    fileId: string
  ) => Promise<SuccessfulResponse<FileResponse>>;
  createFolder: (params: {
    groupId: string;
    folderName: string;
    folderId: string | undefined;
  }) => Promise<void>;
  renameFile: (params: {
    groupId: string;
    fileId: string;
    newName: string;
    newPath: string;
  }) => Promise<void>;
  uploadFile: (
    params: UploadFileRequest & { groupId: string }
  ) => Promise<SuccessfulResponse<NotDeletedFileResponse>>;
  copyFiles: (params: {
    groupId: string;
    fileId: string;
  }) => Promise<SuccessfulResponse>;
  download: (groupId: string, fileId: string) => Promise<globalThis.File>;
  moveFileToTrash: (groupId: string, fileId: string) => Promise<void>;
  moveFolderToTrash: (groupId: string, folderId: string) => Promise<void>;
  getFilePreview: (
    groupId: string,
    fileId: string
  ) => Promise<SuccessfulResponse<FilePreviewResponse>>;
  deleteFilePreview: (groupId: string, fileId: string) => Promise<void>;
  getFolderDetails: (
    groupId: string,
    folderId: string
  ) => Promise<SuccessfulResponse<FileResponse>>;
  updateContent: (params: {
    groupId: string;
    fileId: string;
    content: globalThis.File;
    fileName: string;
  }) => Promise<void>;
  extendLock: (params: { groupId: string; fileId: string }) => Promise<void>;
}
