import { keepPreviousData, useQuery } from '@tanstack/react-query';

import useGroupsApi, {
  groupResponseToGroup,
} from '@app/services/groups/useGroupsApi';

const useGroupDetails = (id: string) => {
  const { getDetails } = useGroupsApi();

  const query = useQuery({
    queryFn: () => getDetails(id),
    queryKey: ['group-details', id],
    placeholderData: keepPreviousData,
    select: ({ data }) => groupResponseToGroup(data),
  });

  return query;
};

export default useGroupDetails;
