import { useNavigate } from 'react-router';

import useAppJoyride from '@app/hooks/useAppJoyride';
import { Tag } from '@app/services/files/types';
import { TagWithFiles } from '@app/services/tags/types';
import TablePagination from '@components/table-pagination';

import TagListItem from './tag-list-item';

interface TagsListViewProps {
  tags: TagWithFiles[];
  onOpenMergeTag?: (tag: Tag) => void;
  page: number;
  onPageChange: (newPage: number) => void;
  total: number;
  isPlaceholderData?: boolean;
}

const TagsListView = ({
  tags,
  onOpenMergeTag,
  page,
  onPageChange,
  total,
  isPlaceholderData,
}: TagsListViewProps) => {
  const navigate = useNavigate();
  const { updateStageConfig } = useAppJoyride();
  updateStageConfig(6, 3, {
    onNext: () => {
      if (tags.at(0)) {
        navigate(`/tags/${tags.at(0)!.id}`, { state: { tag: tags.at(0) } });
      }
    },
  });

  return (
    <div className="mt-lg flex flex-col shadow-md">
      <ol role="list">
        {tags.map((tag) => (
          <TagListItem key={tag.id} tag={tag} onOpenMergeTag={onOpenMergeTag} />
        ))}
      </ol>
      <table>
        <tfoot>
          <TablePagination
            onPageChange={onPageChange}
            rowsPerPage={5}
            page={page}
            total={total}
            isPlaceholderData={isPlaceholderData}
          />
        </tfoot>
      </table>
    </div>
  );
};

export default TagsListView;
