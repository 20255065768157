import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { Box, Button, Modal, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import getDocumentRenderer from '@app/document-renderers/document';
import getHtmlRenderer from '@app/document-renderers/html';
import getImageRenderer from '@app/document-renderers/image';
import getTextRenderer from '@app/document-renderers/text';
import useAlert from '@app/hooks/useAlert';
import useCloseRequestModal from '@app/hooks/useCloseRequestModal';
import ErrorUploading from '@assets/layout/error-uploading.svg?react';
import Close from '@assets/shared/close.svg?react';
import ErrorHelpReport from '@components/error-help-report';
import Loading from '@components/loading';
import NoPreview from '@components/no-preview';
import PressableIcon from '@components/pressable-icon';
import useGroupContext from '@pages/group-files/hooks/useGroupContext';

import useUpdateFile from '../hooks/useUpdateFile';

import { FileDetailsContext } from './file-details';

interface UpdateFileModalProps {
  isOpen: boolean;
  document: File | null;
  onClose: () => void;
}

const UpdateFileModal = ({
  isOpen,
  onClose,
  document,
}: UpdateFileModalProps) => {
  const { t } = useTranslation();
  const group = useGroupContext();
  const {
    isError,
    isPending,
    mutateAsync: updateContent,
    reset,
  } = useUpdateFile(group?.id);
  const fileDetails = useContext(FileDetailsContext);
  const withCanClose = useCloseRequestModal(isPending, reset);
  const queryClient = useQueryClient();
  const { displayGlobal } = useAlert();

  const handleUpdate = async () => {
    if (document && fileDetails) {
      await updateContent({
        fileId: fileDetails.id,
        content: document,
        fileName: fileDetails.fileName,
      });
      await queryClient.invalidateQueries({
        queryKey: ['file-details', fileDetails.id, group?.id],
      });
      displayGlobal({
        text: t('fileList.fileDetails.buttons.upload.modal.success'),
        type: 'success',
      });
      onClose();
    }
  };

  const Content = () => {
    if (isPending) {
      return (
        <div className="flex flex-1 items-center justify-center">
          <Loading />
        </div>
      );
    }

    if (isError) {
      return (
        <div className="flex flex-1 flex-col items-center justify-center">
          <ErrorUploading className="flex-3 mb-lg -mt-20" />
          <Typography className="!mb-sm !text-2xl !font-medium">
            {t('fileList.fileDetails.buttons.upload.modal.error')}
          </Typography>
          <div className="mb-md flex">
            <Trans i18nKey="layout.uploadPreview.fail.text">
              <Typography className="!mr-1 !text-lg">Please</Typography>
              <Typography
                className="cursor-pointer !text-lg underline"
                onClick={reset}
              >
                try again
              </Typography>
              <Typography className="!text-lg">.</Typography>
            </Trans>
          </div>
          <ErrorHelpReport />
        </div>
      );
    }

    if (document) {
      return (
        <div
          className="gap-x-md flex flex-1 overflow-auto"
          data-testid="document-preview"
        >
          <DocViewer
            className="flex-1"
            config={{
              header: { disableHeader: true },
              noRenderer: {
                overrideComponent: NoPreview,
              },
            }}
            documents={[{ uri: window.URL.createObjectURL(document) }]}
            pluginRenderers={[
              ...DocViewerRenderers.filter(
                (docRenderer) => !docRenderer.fileTypes.includes('txt')
              ),
              getImageRenderer(
                'justify-center self-center overflow-hidden flex'
              ),
              getDocumentRenderer('w-full justify-center flex'),
              getHtmlRenderer(),
              getTextRenderer('!min-w-0'),
            ]}
          />
          <div className="flex items-end justify-end">
            <Button onClick={handleUpdate} variant="contained">
              {t('fileList.fileDetails.buttons.upload.modal.update')}
            </Button>
          </div>
        </div>
      );
    }
  };

  return (
    <Modal
      className="flex"
      open={isOpen}
      onClose={withCanClose(onClose)}
      onClick={(e) => e.stopPropagation()}
    >
      <Box className="p-screen mx-auto my-auto !flex h-3/4 w-3/5 flex-col rounded bg-white shadow-md">
        <PressableIcon
          onClick={withCanClose(onClose)}
          icon={<Close title="close" />}
          className="!absolute self-end !p-0"
        />
        <Typography variant="h2" className="!mb-md !text-2xl">
          {t('fileList.fileDetails.buttons.upload.modal.title', {
            fileName: fileDetails!.fileName,
          })}
        </Typography>
        <Content />
      </Box>
    </Modal>
  );
};

export default UpdateFileModal;
