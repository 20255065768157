import { Box, Button, Modal, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import useCloseRequestModal from '@app/hooks/useCloseRequestModal';
import WarningIcon from '@assets/groups/warning.svg?react';
import Close from '@assets/shared/close.svg?react';
import ErrorIcon from '@assets/shared/error.svg?react';
import ErrorHelpReport from '@components/error-help-report';
import Loading from '@components/loading';
import PressableIcon from '@components/pressable-icon';

import useInviteToGroup from '../hooks/useInviteToGroup';

interface WarningModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  users: string[];
  onTryAgain: () => void;
  shouldDisplayWarn: boolean;
}

const WarningModal = ({
  onClose,
  isOpen,
  onSubmit,
  onTryAgain,
  users,
  shouldDisplayWarn,
}: WarningModalProps) => {
  const { t } = useTranslation();
  const { groupId } = useParams();
  const { mutateAsync: invite, isError, isPending, reset } = useInviteToGroup();
  const withCanClose = useCloseRequestModal(isPending, reset);

  useEffect(() => {
    (async () => {
      if (!shouldDisplayWarn && isOpen) {
        await invite({ emails: users, groupId: groupId! });
        onSubmit();
      }
    })().catch((_) => {});
  }, [groupId, invite, isOpen, onSubmit, shouldDisplayWarn, users]);

  const handleSubmit = async () => {
    await invite({ emails: users, groupId: groupId! });
    onSubmit();
  };

  const handleTryAgain = () => {
    reset();
    onTryAgain();
  };

  const Content = () => {
    if (isPending) {
      return (
        <div className="flex flex-1 flex-col items-center justify-center p-16">
          <Loading />
        </div>
      );
    }

    if (isError) {
      return (
        <div className="flex flex-1 flex-col items-center justify-center p-16">
          <ErrorIcon height={120} />
          <p className="my-md text-center text-lg">
            <Trans i18nKey="groups.groupMembers.warningModal.error">
              Oops! We could not invite users to the group.
              <span
                className="cursor-pointer underline"
                onClick={handleTryAgain}
              >
                Try again?
              </span>
            </Trans>
          </p>
          <ErrorHelpReport />
        </div>
      );
    }

    if (shouldDisplayWarn) {
      return (
        <>
          <div className="flex flex-1 flex-col items-center justify-between px-16">
            <WarningIcon height={120} />
            <p className="mt-md text-center text-lg font-medium">
              {t('groups.groupMembers.warningModal.text')}
            </p>
          </div>
          <Button
            variant="contained"
            onClick={handleSubmit}
            className="!mt-lg self-end"
          >
            {t('groups.groupMembers.warningModal.button')}
          </Button>
        </>
      );
    }

    return null;
  };

  return (
    <Modal className="flex" open={isOpen} onClose={withCanClose(onClose)}>
      <Box className="w-tags-editor p-screen mx-auto my-auto !flex flex-col rounded bg-white shadow-md">
        <PressableIcon
          onClick={withCanClose(onClose)}
          icon={<Close title="close" />}
          className="!absolute self-end !p-0"
        />
        <Typography className="!text-xl !font-medium">
          {t('organization.users.invite')}
        </Typography>
        <Content />
      </Box>
    </Modal>
  );
};

export default WarningModal;
