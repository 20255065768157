import { Typography } from '@mui/material';
import { RefObject, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router';
import { twMerge } from 'tailwind-merge';

import { useBreadcrumb } from '@app/hooks/useBreadcrumb';
import { Group } from '@app/services/groups/types';
import RightChevron from '@assets/files/right_chevron.svg?react';
import SettingsIcon from '@assets/groups/settings.svg?react';
import ThreeDotsIcon from '@assets/shared/three-dots.svg?react';
import HoverTimeout from '@components/hover-timeout';
import PressableIcon from '@components/pressable-icon';

import useClipped from '../hooks/useClipped';

import GroupFolderList from './group-folder-list';

interface GroupFilesBreadcrumbProps {
  group: Group;
  groupsPageRef: RefObject<HTMLDivElement>;
  buttonsRef: RefObject<HTMLDivElement>;
}

const GroupFilesBreadcrumb = ({
  group,
  groupsPageRef,
  buttonsRef,
}: GroupFilesBreadcrumbProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { folders } = useBreadcrumb();
  const ref = useRef<HTMLDivElement>(null);

  const [isExpanded, setIsExpanded] = useState(false);
  const isClipped = useClipped({
    clipRef: ref,
    parentsRef: [groupsPageRef, buttonsRef],
  });

  const currentFolder = useMemo(() => folders.at(-1) ?? '', [folders]);
  const hasMidRoutes = useMemo(() => folders.length > 1, [folders]);

  const handleSettingsClick = () => {
    navigate(`/groups/${group.id}`, {
      state: { group },
    });
  };

  return (
    <nav className="flex items-center" ref={ref}>
      {!isClipped ? (
        <Link to={`/groups/${group.id}`}>
          <Typography
            className={twMerge(
              '!ml-sm !text-3xl',
              currentFolder !== '' ? '!text-gray-950/60' : ''
            )}
            variant={currentFolder === '' ? 'h1' : 'h2'}
          >
            {group.name}
          </Typography>
        </Link>
      ) : null}
      {hasMidRoutes ? (
        <div className="relative flex items-center">
          {!isClipped ? <RightChevron className="ml-md" /> : null}
          <HoverTimeout onHover={() => setIsExpanded(true)}>
            <PressableIcon
              icon={
                <ThreeDotsIcon
                  className="self-end !text-gray-950/60"
                  title="..."
                />
              }
              className="!ml-md !pt-md relative z-[90] flex cursor-pointer items-center"
              onClick={() => setIsExpanded(true)}
            />
          </HoverTimeout>
          {isExpanded ? (
            <GroupFolderList
              onClose={() => setIsExpanded(false)}
              folders={folders}
            />
          ) : null}
        </div>
      ) : null}
      {currentFolder ? (
        <>
          <RightChevron className="ml-md" />
          <h1
            className="ml-md overflow-hidden text-ellipsis text-nowrap text-4xl font-medium"
            onClick={handleSettingsClick}
          >
            {currentFolder.name}
          </h1>
        </>
      ) : null}
      <PressableIcon
        className="!ml-md"
        icon={<SettingsIcon />}
        onClick={handleSettingsClick}
        altText={t('groups.groupFiles.settings')}
      />
    </nav>
  );
};

export default GroupFilesBreadcrumb;
