import { useQuery } from '@tanstack/react-query';

import useUsersApi from '@app/services/users/useUsersApi';

const useUser = (userId?: string, enabled = true) => {
  const { get } = useUsersApi();

  const query = useQuery({
    queryFn: () => get(userId),
    queryKey: ['user', userId],
    retry: (failureCount, error) =>
      error.message !== 'User ID not provided' && failureCount < 3,
    enabled,
    select: ({ data }) => data,
  });

  return query;
};

export default useUser;
