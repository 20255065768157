import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import PendingCreation from '@assets/organizations/pending-creation.svg?react';

const OrganizationGroups = () => {
  const { t } = useTranslation();

  return (
    <div className="gap-y-md flex flex-1 flex-col items-center justify-center">
      <PendingCreation />
      <Typography>{t('organization.comingSoon')}</Typography>
    </div>
  );
};

export default OrganizationGroups;
