/* eslint-disable @typescript-eslint/require-await */
import {
  AdvancedSearchFormInputsFormatted,
  DeletedFileResponse,
  FilePreviewResponse,
  NotDeletedFileResponse,
  ProposedVersion,
  SharedFileResponse,
  WorkingClosedVersion,
} from '@app/services/files/types';
import { fileResponseMock } from '@app/test/mocks/file-mocks';
import { getOnlyPath } from '@app/utils/files';
import { SuccessfulResponse } from '@filot/types/api';

import mockDeletedFiles from '../../../../../mock/deleted-files.json';
import mock from '../../../../../mock/files.json';

import {
  ClosedVersionsResponse,
  FileResponse,
  FilesApi,
  UploadFileRequest,
} from './types';

const getFilesMock = (): FilesApi => {
  const mockFiles: FileResponse[] = mock as FileResponse[];
  const get = async (): Promise<SuccessfulResponse<FileResponse[]>> => ({
    success: true,
    message: '',
    data: mock as FileResponse[],
  });

  const getRootContent = async (): Promise<
    SuccessfulResponse<FileResponse>
  > => ({
    success: true,
    message: '',
    data: {
      name: '',
      path: '/',
      organization_id: 'client',
      owner: 'auth0|67c6f69cdb5aa60c3b6fba49',
      users_with_access: {
        'auth0|67c6f69cdb5aa60c3b6fba49': [],
      },
      groups_with_access: {},
      extension: '.folder',
      type: 'SYSTEM_FOLDER',
      content_id: '67c7042b2cf3933ff269b447',
      id: '67c7042c2cf3933ff269b448',
      creation: {
        created_by: 'auth0|67c6f69cdb5aa60c3b6fba49',
        created_at: '2025-03-04T13:46:20.012000',
      },
      modifications: [],
      tags: [],
      lock_state: null,
      locked: false,
      size: 0,
    },
  });

  const getFileDetails = (fileId: string) =>
    new Promise<SuccessfulResponse<FileResponse>>((res, rej) => {
      const foundFile = (mock as FileResponse[]).find(
        (file) => file.id === fileId
      );
      if (foundFile) {
        res({
          success: true,
          message: 'success',
          data: foundFile,
        });
      } else {
        rej(new Error('MOCK_ERROR'));
      }
    });

  const createFolder = ({
    folderName,
    folderId,
  }: {
    folderName: string;
    folderId: string | undefined;
  }) =>
    new Promise<void>((res, rej) => {
      if (folderName.includes('error')) {
        rej(new Error('MOCK_ERROR'));
      } else {
        mockFiles.push({
          name: folderName,
          path: folderId ?? '',
          organization_id: 'OrganizationA',
          owner: 'ownerName',
          users_with_access: {
            userA: ['write', 'read'],
          },
          groups_with_access: {
            groupA: ['write', 'read'],
          },
          tags: [],
          extension: '',
          type: 'document',
          content_id: `new-folder-${folderName}`,
          id: `new-folder-${folderName}`,
          creation: {
            created_by: 'system',
            created_at: '2022-02-05T01:40:00.000Z',
          },
          modifications: ['2022-02-04T01:40:00.000Z'],
          locked: false,
          size: 1234,
          lock_state: null,
        });
        res();
      }
    });

  const renameFile = ({
    fileId,
    newName,
  }: {
    fileId: string;
    newName: string;
  }) =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (newName.includes('error')) {
          rej(new Error('MOCK_ERROR'));
        } else {
          const fileToEdit = mockFiles.find((file) => file.id === fileId);
          if (fileToEdit) {
            fileToEdit.name = `${newName}${fileToEdit.extension}`;
            res();
          }
        }
      }, 1000);
    });

  const moveFile = ({
    fileId,
    newFolderId,
  }: {
    fileId: string;
    newFolderId: string;
  }) =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (newFolderId.includes('error')) {
          rej(new Error('MOCK_ERROR'));
        } else {
          const fileToEdit = mockFiles.find((file) => file.id === fileId);
          if (fileToEdit) {
            fileToEdit.path = newFolderId;
            res();
          }
        }
      }, 1000);
    });

  const syncFile = async ({ fileId }: { fileId: string }) =>
    new Promise<string>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res(fileId);
        }
      }, 5000);
    });

  const shareFile = async () =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const uploadFile = async (file: UploadFileRequest) =>
    new Promise<SuccessfulResponse<NotDeletedFileResponse>>((res, rej) =>
      setTimeout(() => {
        if (file.name.includes('error')) {
          return rej(new Error('MOCK_ERROR'));
        } else {
          return res({
            data: { ...fileResponseMock, ...file, deletion: undefined },
            success: true,
            message: 'success',
          });
        }
      }, 2000)
    );

  const getProposedVersions = async (_fileId: string) =>
    new Promise<ProposedVersion[]>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res([
            {
              id: '2319283',
              acceptedBy: ['Michael Jackson', 'Mr Test'],
              name: 'file_v2_proposed',
            },
          ]);
        }
      }, 1000);
    });

  const getWorkingVersions = async (_fileId: string) =>
    new Promise<WorkingClosedVersion[]>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res([
            {
              id: '2319283',
              lastModified: '2024-05-03',
              name: 'file_v2_working',
            },
          ]);
        }
      }, 1000);
    });

  const getClosedVersions = async (_fileId: string, page: number) =>
    new Promise<ClosedVersionsResponse>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          if (page === 0) {
            res({
              closedVersions: [
                {
                  id: '2319283',
                  lastModified: '2024-07-03',
                  name: 'file_v0',
                },
                {
                  id: '324fdas',
                  lastModified: '2024-07-02',
                  name: 'file_v1',
                },
                {
                  id: 'fasdgsda',
                  lastModified: '2024-07-01',
                  name: 'file_v1.1',
                },
                {
                  id: 'gsdags',
                  lastModified: '2024-05-03',
                  name: 'file_v1.2',
                },
                {
                  id: 'asfdsafdgsd',
                  lastModified: '2024-01-03',
                  name: 'file_v1.3',
                },
              ],
              total: 7,
            });
          } else {
            res({
              closedVersions: [
                {
                  id: 'gasdgdsag',
                  lastModified: '2023-12-03',
                  name: 'file_v1.4',
                },
                {
                  id: 'gglllglg',
                  lastModified: '2023-05-03',
                  name: 'file_v1.5',
                },
              ],
              total: 7,
            });
          }
        }
      }, 1000);
    });

  const createVersion = () =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const moveFileToTrash = () =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const moveFolderToTrash = () =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const download = () =>
    new Promise<File>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res(new File([], 'downloaded_file'));
        }
      }, 1000);
    });

  const lockFile = () =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const unlockFile = () =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const getSharedFile = () =>
    new Promise<SuccessfulResponse<SharedFileResponse>>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res({
            success: true,
            message: 'Shared file found with success!',
            data: {
              file_id: '671bf9c641991f63dee0f733',
              users_with_access: {
                'google-oauth2|105172225577520527444': ['write', 'read'],
                'auth0|6697f174969140973b068d7a': ['write', 'read'],
              },
              groups_with_access: [],
            },
          });
        }
      }, 1000);
    });

  const revokeSharingPermissions = () =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const updateSharingPermissions = () =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const getFilePreview = (fileId: string) =>
    new Promise<SuccessfulResponse<FilePreviewResponse>>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res({
            data: {
              file_id: fileId,
              link: 'https://filot-demo-documents.s3.amazonaws.com/Arbitration+Agreement+Document.pdf',
              users_viewing: [],
            },
            message: 'success',
            success: true,
          });
        }
      }, 1000);
    });

  const deleteFilePreview = () =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const getDeletedFiles = () =>
    new Promise<SuccessfulResponse<DeletedFileResponse[]>>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res({
            data: mockDeletedFiles as DeletedFileResponse[],
            message: 'success',
            success: true,
          });
        }
      }, 1000);
    });

  const getFolderDetails = (folderId: string) =>
    new Promise<SuccessfulResponse<FileResponse>>((res, rej) => {
      const foundFile = (mock as FileResponse[]).find(
        (file) => file.id === folderId
      );
      if (foundFile) {
        res({
          success: true,
          message: 'success',
          data: foundFile,
        });
      } else {
        rej(new Error('MOCK_ERROR'));
      }
    });

  const updateContent = () =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const extendLock = () =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const deleteForever = () =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const restoreFile = () =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const advancedSearch = (
    filters: Partial<AdvancedSearchFormInputsFormatted>
  ) =>
    new Promise<SuccessfulResponse<string[]>>((res, rej) => {
      const filterFiles = (file: FileResponse) => {
        const filePath = getOnlyPath(file.path);

        if (filters?.path) {
          return (
            `${filters.path.replace(/"/g, '')}` === filePath ||
            `${filters.path.replace(/"/g, '')}/` === filePath
          );
        }

        return (
          (!filters.path && filePath === '/') ||
          filters.path === filePath ||
          encodeURI(filters.path ?? '') === filePath ||
          `${filters.path}/` === filePath
        );
      };
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res({
            success: true,
            message: '',
            data: (mock as FileResponse[])
              .filter(filterFiles)
              .map(({ id }) => id),
          });
        }
      }, 1000);
    });

  return {
    get,
    getRootContent,
    getFileDetails,
    createFolder,
    renameFile,
    moveFile,
    syncFile,
    shareFile,
    uploadFile,
    getProposedVersions,
    getClosedVersions,
    getWorkingVersions,
    createVersion,
    moveFileToTrash,
    moveFolderToTrash,
    download,
    lockFile,
    getSharedFile,
    revokeSharingPermissions,
    updateSharingPermissions,
    getFilePreview,
    deleteFilePreview,
    getDeletedFiles,
    getFolderDetails,
    updateContent,
    extendLock,
    unlockFile,
    deleteForever,
    restoreFile,
    advancedSearch,
  };
};

export default getFilesMock;
