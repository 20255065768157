import { Card, Typography } from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import { MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router';
import { twMerge } from 'tailwind-merge';

import { useUpdateBreadcrumb } from '@app/hooks/useBreadcrumb';
import useClickAndDrag from '@app/hooks/useClickAndDrag';
import { File } from '@app/services/files/types';
import FolderIcon from '@assets/file-icons/folder.svg?react';
import MoveableToFolder from '@components/moveable-to-folder';

import { MoveableElements } from '../hooks/useMoveableToFolder';

import FolderCardContextMenu from './folder-card-context-menu';

interface FolderCardProps<T> {
  folder: T;
  onFileMove?: (elements: MoveableElements) => void;
  onFolderHover?: (elements: MoveableElements) => void;
  onClick?: (folder: T) => void;
  tourId?: string;
}

const FolderCard = <T extends File>({
  folder,
  onFileMove,
  onFolderHover,
  onClick,
  tourId,
}: FolderCardProps<T>) => {
  const { handleMouseDown, handleMouseUp, handleClick, dragging } =
    useClickAndDrag();
  const [folderCardRef, setFolderCardRef] = useState<HTMLDivElement | null>(
    null
  );
  const dispatch = useUpdateBreadcrumb();

  const cardClasses = useMemo(
    () =>
      twMerge(
        'p-sm flex w-full flex-1 cursor-pointer items-center gap-3',
        'bg-gray-100 transition duration-300 hover:bg-gray-300'
      ),
    []
  );

  const folderName = useMemo(
    () => folder.fileName.split('.')[0],
    [folder.fileName]
  );

  const handleFolderClick: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      if (dragging) {
        e.preventDefault();
      }
      dispatch({
        type: 'PUSH_FOLDER',
        payload: {
          id: folder.id,
          name: folder.fileName,
          path: folder.path,
        },
      });
    },
    [dispatch, dragging, folder.fileName, folder.id, folder.path]
  );

  const folderRaw = useMemo(
    () => (
      <Link
        to={`/files/${folder.id}`}
        state={{ file: folder }}
        onDragStart={(e) => e.preventDefault()}
        onClick={handleFolderClick}
      >
        <AnimatePresence>
          <div
            className="folderCard"
            data-tour={tourId}
            id={folder.id}
            title={folderName}
            onClick={handleClick(() => onClick?.(folder))}
            ref={setFolderCardRef}
          >
            <Card data-testid="folder-card" className="flex h-14">
              <div className={cardClasses}>
                <span>
                  <FolderIcon title="folder" />
                </span>
                <Typography className="overflow-hidden text-ellipsis whitespace-nowrap !text-base !font-medium">
                  {folderName}
                </Typography>
              </div>
            </Card>
          </div>
        </AnimatePresence>
      </Link>
    ),
    [
      cardClasses,
      folder,
      folderName,
      handleClick,
      handleFolderClick,
      onClick,
      tourId,
    ]
  );

  if (onFileMove && onFolderHover) {
    return (
      <>
        <MoveableToFolder
          onFolderHover={onFolderHover}
          onDragStart={handleMouseDown}
          onDragEnd={handleMouseUp}
          onFileMove={onFileMove}
          elementId={folder.id}
          elementName={folderName}
        >
          {folderRaw}
        </MoveableToFolder>
        <FolderCardContextMenu folder={folder} anchorElement={folderCardRef} />
      </>
    );
  }

  return (
    <>
      {folderRaw}
      <FolderCardContextMenu folder={folder} anchorElement={folderCardRef} />
    </>
  );
};

export default FolderCard;
