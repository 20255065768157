import { RefObject, useEffect, useState } from 'react';

interface UseClippedOptions {
  clipRef: RefObject<HTMLDivElement>;
  parentsRef: Array<RefObject<HTMLDivElement>>;
}

const useClipped = ({ clipRef, parentsRef }: UseClippedOptions) => {
  const [isClipped, setIsClipped] = useState(false);

  useEffect(() => {
    const updateSize = () => {
      if (!clipRef.current) {
        return;
      }

      const { width } = clipRef.current.getBoundingClientRect();
      let parentWidth = 0;
      parentsRef.forEach((ref, index) => {
        if (index === 0) {
          parentWidth = ref.current?.getBoundingClientRect().width ?? 0;
        } else {
          parentWidth -= ref.current?.getBoundingClientRect().width ?? 0;
        }
      });

      if (isClipped) {
        setIsClipped(width + 300 > parentWidth - 110);
      } else {
        setIsClipped(width > parentWidth - 110);
      }
    };

    window.addEventListener('resize', updateSize);

    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, [clipRef, isClipped, parentsRef]);

  return isClipped;
};

export default useClipped;
