import { Button } from '@mui/material';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import { DeletedFile } from '@app/services/files/types';
import EmptyIcon from '@assets/files/empty.svg?react';
import ErrorUploading from '@assets/layout/error-uploading.svg?react';
import Breadcrumb from '@components/breadcrumb';
import ErrorHelpReport from '@components/error-help-report';
import Loading from '@components/loading';
import FileList from '@pages/files/components/file-list';

import DeletedFileDetails from './components/deleted-file-details';
import useDeletedFiles from './hooks/useDeletedFiles';

const Trash = () => {
  const { t } = useTranslation();
  const [fileDetail, setFileDetail] = useState<DeletedFile | null>(null);
  const { isLoading, data, isError, refetch } = useDeletedFiles();

  const handleDoubleClick = () => {};

  if (isLoading) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <Loading color="primary" />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex flex-1 flex-col items-center justify-center">
        <ErrorUploading className="flex-3 mb-lg -mt-20" />
        <p className="mb-sm text-2xl font-medium">{t('trash.error.title')}</p>
        <div className="mb-md flex">
          <Trans i18nKey="trash.error.text">
            <p className="mr-1 text-lg">Please</p>
            <p
              className="cursor-pointer text-lg underline"
              onClick={() => refetch()}
            >
              try again
            </p>
            <p className="text-lg">.</p>
          </Trans>
        </div>
        <ErrorHelpReport />
      </div>
    );
  }

  if (data === null) {
    return (
      <div className="flex flex-1 flex-col items-center justify-center">
        <EmptyIcon />
        <p className="my-sm text-2xl font-medium">{t('trash.empty.title')}</p>
        <Link to="/files">
          <Button variant="contained">{t('trash.empty.back')}</Button>
        </Link>
      </div>
    );
  }

  return (
    <div className="flex flex-1 flex-row overflow-x-hidden" data-tour="trash">
      <div className="flex flex-1 flex-col overflow-y-auto">
        <div className="flex justify-between">
          <Breadcrumb />
        </div>
        <div className="gap-md p-md flex flex-1 flex-col">
          <FileList
            files={data}
            onFileClick={setFileDetail}
            onFileDoubleClick={handleDoubleClick}
          />
        </div>
      </div>
      {fileDetail ? (
        <DeletedFileDetails
          key={fileDetail.id}
          file={fileDetail}
          onClose={() => setFileDetail(null)}
        />
      ) : null}
    </div>
  );
};

export default Trash;
