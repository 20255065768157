import { useAuth0 } from '@auth0/auth0-react';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';

import useHandleAppLogin from '@app/hooks/useHandleAppLogin';
import useUsersApi from '@app/services/users/useUsersApi';
import { isRunningOnElectron } from '@app/utils/environment';

const PostLogin = () => {
  const { updateAuth0UserState } = useAuth0();
  const { postLogin } = useUsersApi();
  const { mutateAsync } = useMutation({
    mutationFn: postLogin,
    mutationKey: ['post-login'],
  });
  const handleAppLogin = useHandleAppLogin();

  useEffect(() => {
    void mutateAsync();
  }, [mutateAsync]);

  useEffect(() => {
    if (isRunningOnElectron()) {
      window.auth.onLogin((url) => {
        /* v8 ignore next 3 */
        void handleAppLogin(url).then(() => {
          void updateAuth0UserState();
        });
      });
    }

    return () => {
      if (isRunningOnElectron()) {
        window.auth.removeLoginListener();
      }
    };
  }, [handleAppLogin, updateAuth0UserState]);

  return <></>;
};

export default PostLogin;
