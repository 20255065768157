import { useState } from 'react';

const useClickAndDrag = () => {
  const [dragging, setDragging] = useState(false);

  const handleMouseDown = () => {
    setDragging(true);
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleClick = (onClickCallback: () => void) => () => {
    if (!dragging) {
      onClickCallback();
    }
  };

  return { handleMouseDown, handleMouseUp, handleClick, dragging };
};

export default useClickAndDrag;
