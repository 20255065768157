import { useCallback, useState } from 'react';

import { decodeFromBase64 } from '@app/utils/base64';
import { isRunningOnElectron } from '@app/utils/environment';
import * as Storage from '@app/utils/storage';

import useUser from './useUser';

const useHandleAppLogin = () => {
  const [userId, setUserId] = useState<string | null>(null);
  const { refetch, data } = useUser(userId ?? undefined, false);

  return useCallback(
    (url: string) =>
      new Promise<void>((res, rej) => {
        if (!isRunningOnElectron()) {
          res();

          return;
        }
        const params = new URLSearchParams(url.split('?').at(1));

        const token = params.get('token')
          ? decodeURIComponent(params.get('token')!)
          : null;
        const userIdParam = params.get('user');
        setUserId(userIdParam ? decodeURIComponent(userIdParam) : null);
        const userKey = params.get('key')
          ? decodeURIComponent(params.get('key')!)
          : null;

        if (!userKey || !token || !userIdParam) {
          rej();

          return;
        }
        void Storage.setItem(
          userKey,
          JSON.stringify({
            decodedToken: {
              user: { sub: decodeURIComponent(userIdParam) },
            },
          })
        ).then(() => {
          window.auth.setToken(decodeFromBase64(token));

          if (data) {
            void Storage.setItem(
              userKey,
              JSON.stringify({
                decodedToken: { user: { ...data, sub: userIdParam } },
              })
            );
            res();

            return;
          }

          void refetch().then(({ data: user }) => {
            void Storage.setItem(
              userKey,
              JSON.stringify({
                decodedToken: { user: { ...user, sub: userIdParam } },
              })
            );
            res();
          });
        });
      }),
    [data, refetch]
  );
};

export default useHandleAppLogin;
