import { useMutation, useQueryClient } from '@tanstack/react-query';

import useTagsApi from '@app/services/tags/useTagsApi';

const useEditTags = () => {
  const { upsertTags } = useTagsApi();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: upsertTags,
    mutationKey: ['tags-upsert'],
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['file-details', variables.fileId, undefined],
      });
    },
  });

  return mutation;
};

export default useEditTags;
