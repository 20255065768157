import { useAuth0 } from '@auth0/auth0-react';
import { Skeleton } from '@mui/material';
import { DateTime } from 'luxon';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useUser from '@app/hooks/useUser';
import { FileAccessLevel, UsersWithAccess } from '@app/services/files/types';
import Loading from '@components/loading';
import UserAvatar from '@components/user-avatar';
import useUserByEmail from '@pages/organizations/hooks/useUserByEmail';

import useRevokeSharingPermissions from '../hooks/useRevokeSharingPermissions';
import useShareFiles from '../hooks/useShareFile';
import useUpdateSharingPermissions from '../hooks/useUpdateSharingPermissions';

import { FileDetailsContext } from './file-details';
import ManageAccessPeoplePersonError from './manage-access-people-person-error';
import ManageAccessPeoplePersonInput from './manage-access-people-person-input';

interface ManageAccessPeoplePersonEmailProps {
  email: string;
  users?: undefined;
  /** User ID to get the information */
  userId?: undefined;
  accessLevel: FileAccessLevel[] | null;
  isOwner?: boolean;
  onLoad: (email: string, id: string) => void;
  onRemoveUser: () => void;
}

interface ManageAccessPeoplePersonUserIdProps {
  email?: undefined;
  users: UsersWithAccess;
  /** User ID to get the information */
  userId: string;
  accessLevel: FileAccessLevel[] | null;
  isOwner?: boolean;
  onLoad?: undefined;
  onRemoveUser: () => void;
}

type ManageAccessPeoplePersonProps =
  | ManageAccessPeoplePersonUserIdProps
  | ManageAccessPeoplePersonEmailProps;

const ManageAccessPeoplePerson = ({
  email,
  userId,
  accessLevel,
  isOwner,
  onLoad,
  users,
  onRemoveUser,
}: ManageAccessPeoplePersonProps) => {
  const file = useContext(FileDetailsContext);
  const { t } = useTranslation();
  const [isRevoking, setIsRevoking] = useState(false);
  const { data, isLoading, isError, isSuccess } = useUserByEmail(email!, {
    enabled: email !== undefined,
    staleTime: 60 * 5 * 1000,
    retryOnMount: false,
  });
  const {
    data: user,
    isLoading: isLoadingUser,
    isError: isErrorUser,
  } = useUser(userId, userId !== undefined);
  const { user: currentUser } = useAuth0();
  const { mutateAsync: revokeSharingPermissions, isPending: isRevokePending } =
    useRevokeSharingPermissions(file?.type);
  const { mutateAsync: updateSharingPermissions, isPending: isUpdatePending } =
    useUpdateSharingPermissions();
  const {
    mutateAsync: shareFile,
    isError: isShareError,
    isPending: isSharePending,
    reset,
  } = useShareFiles(file?.type);
  const [currentPermission, setCurrentPermission] = useState<FileAccessLevel[]>(
    accessLevel ?? []
  );
  const userData = user ?? data;

  const shareFileWithUser = useCallback(() => {
    void shareFile({
      access: ['read'],
      expirationDate: null,
      fileId: file!.id,
      startDate: DateTime.now().toISO(),
      userId: user!.user_id,
    }).then(() => setCurrentPermission(['read']));
  }, [file, shareFile, user]);

  useEffect(() => {
    const isSharedWithUser =
      users &&
      Object.keys(users).some((sharedUserId) => sharedUserId === user?.user_id);

    if (user && !isSharedWithUser && !isRevoking && file) {
      shareFileWithUser();
    }
  }, [file, isRevoking, shareFileWithUser, user, users]);

  useEffect(() => {
    if (data && email) {
      onLoad(email, data.user_id);
    }
  }, [data, email, isSuccess, onLoad]);

  const handleRetry = () => {
    reset();
    shareFileWithUser();
  };

  const renderName = useCallback(
    (name: string, id: string) => {
      if (id === currentUser?.sub) {
        return t('fileList.fileDetails.manageAccessModal.form.people.youUser', {
          name,
        });
      }

      return name;
    },
    [t, currentUser?.sub]
  );

  if (isLoading || isLoadingUser) {
    return (
      <div className="py-sm px-screen flex flex-1 items-center">
        <Skeleton
          variant="circular"
          width={42}
          height={42}
          role="progressbar"
        />
        <div className="ml-sm flex flex-1 flex-col">
          <Skeleton className="!max-w-[40%]" role="progressbar" />
          <Skeleton className="!max-w-[60%] !text-sm" role="progressbar" />
        </div>
      </div>
    );
  }

  if (isError || isErrorUser) {
    return (
      <ManageAccessPeoplePersonError
        onRemoveUser={onRemoveUser}
        email={email}
        userId={userId}
      />
    );
  }

  if (userData) {
    const Action = () => {
      if (isSharePending) {
        return <Loading size={24} />;
      }

      if (isShareError) {
        return (
          <ManageAccessPeoplePersonError
            user={userData}
            onRetry={handleRetry}
          />
        );
      }

      if (isOwner) {
        return null;
      }

      return (
        <ManageAccessPeoplePersonInput
          loading={isRevokePending || isUpdatePending}
          onChangePermission={handleChangePermission}
          currentPermission={currentPermission}
        />
      );
    };

    const handleChangePermission = async (
      newPermission: FileAccessLevel[] | 'revoke'
    ) => {
      if (!file) {
        return;
      }
      if (newPermission === 'revoke') {
        setIsRevoking(true);
        await revokeSharingPermissions({
          fileId: file.id,
          userId: userData.user_id,
        });
      } else {
        await updateSharingPermissions({
          fileId: file.id,
          permission: newPermission,
          userId: userData.user_id,
        });
        setCurrentPermission(newPermission);
      }
    };

    return (
      <div className="px-screen flex justify-between">
        <div className="gap-x-sm flex items-center">
          <UserAvatar user={userData} />
          <div className="flex flex-col justify-between">
            <p className="font-medium">
              {renderName(userData.name, userData.user_id)}
            </p>
            <p className="text-sm text-gray-950/60">{userData.email}</p>
          </div>
        </div>
        <Action />
      </div>
    );
  }
};

export default ManageAccessPeoplePerson;
