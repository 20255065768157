import { useInfiniteQuery } from '@tanstack/react-query';

import { GroupMemberResponse } from '@app/services/groups/types';
import useGroupsApi from '@app/services/groups/useGroupsApi';

const useGroupsInfiniteMembers = (groupsId: string) => {
  const { getMembers } = useGroupsApi();

  const query = useInfiniteQuery({
    queryFn: () => getMembers(groupsId),
    queryKey: ['groups-infinite-members', groupsId],
    getNextPageParam: () => null,
    initialPageParam: 1,
    refetchOnWindowFocus: false,
    select: (data) => {
      const members: GroupMemberResponse[] = [];
      data.pages.forEach((page) => {
        members.push(...page.data);
      });

      return {
        ...data,
        members,
      };
    },
  });

  return query;
};

export default useGroupsInfiniteMembers;
