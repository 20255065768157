import { Menu, MenuItem } from '@mui/material';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Group } from '@app/services/groups/types';

interface GroupsContextMenuProps {
  group: Group;
  anchorElement: HTMLElement | null;
  onClose: MouseEventHandler<HTMLElement>;
  isOpen: boolean;
}

const GroupsContextMenu = ({
  group,
  anchorElement,
  onClose,
  isOpen,
}: GroupsContextMenuProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const withClose =
    (callback: () => void): MouseEventHandler<HTMLElement> =>
    (e) => {
      onClose(e);
      callback();
    };

  const handleManageFilesClick = () => {
    navigate(`${group.id}/files`);
  };

  const handleManageMembersClick = () => {
    navigate(`${group.id}/members`, {
      state: {
        group,
      },
    });
  };

  const handleManageDetailsClick = () => {
    navigate(group.id, {
      state: {
        group,
      },
    });
  };

  return (
    <Menu
      id="groups-context-menu"
      anchorEl={anchorElement}
      open={isOpen}
      onClose={onClose}
      MenuListProps={{
        'aria-labelledby': 'groups-context-menu',
      }}
    >
      <MenuItem onClick={withClose(handleManageFilesClick)}>
        {t('groups.contextMenu.files')}
      </MenuItem>
      <MenuItem onClick={withClose(handleManageDetailsClick)}>
        {t('groups.contextMenu.details')}
      </MenuItem>
      <MenuItem onClick={withClose(handleManageMembersClick)}>
        {t('groups.contextMenu.members')}
      </MenuItem>
    </Menu>
  );
};

export default GroupsContextMenu;
