import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import useAlert from '@app/hooks/useAlert';
import { useAppLocation } from '@app/hooks/useAppLocation';
import { File } from '@app/services/files/types';
import { Group } from '@app/services/groups/types';
import BackArrow from '@assets/groups/back_arrow.svg?react';
import ErrorIcon from '@assets/shared/error.svg?react';
import ErrorHelpReport from '@components/error-help-report';
import Loading from '@components/loading';
import PressableIcon from '@components/pressable-icon';

import AddFilesConfirmationModal from './components/add-files-confimation-modal';
import GroupAddFilesSelectFiles from './components/group-add-files-select-files';
import SelectedFilesList from './components/selected-files-list';
import UnsavedWarningModal from './components/unsaved-warning-modal';
import useAddFiles from './hooks/useAddFiles';
import {
  SelectedFilesContext,
  SelectedFilesDispatchContext,
  useSelectedFilesReducer,
} from './hooks/useSelectedFiles';

const GroupAddFiles = () => {
  const { t } = useTranslation();
  const { state } = useAppLocation<{
    group: Group;
    files?: File[];
  }>();
  const navigate = useNavigate();

  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false);
  const [isWarningModalVisible, setIsWarningModalVisible] = useState(false);
  const [selectedFiles, dispatch] = useSelectedFilesReducer();
  const { mutateAsync: addFiles, isPending, isError, reset } = useAddFiles();
  const queryClient = useQueryClient();
  const { displayGlobal } = useAlert();

  useEffect(() => {
    if (state?.files && state.files.length > 0) {
      dispatch({
        type: 'SET_FILES',
        payload: state.files,
      });
      setIsConfirmationModalVisible(true);
    }
  }, [dispatch, state?.files]);

  const navigateBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleGoBack = () => {
    if (selectedFiles.length > 0) {
      setIsWarningModalVisible(true);
    } else {
      navigateBack();
    }
  };

  const handleDeselectFile = (fileId: string) => {
    dispatch({
      type: 'REMOVE_FILE',
      payload: fileId,
    });
  };

  const handleAddFiles = async () => {
    if (!state?.group || isPending) {
      return;
    }
    setIsConfirmationModalVisible(false);
    await addFiles({
      files: selectedFiles.map(({ id, fileName }) => ({ id, name: fileName })),
      groupId: state.group.id,
    });
    await queryClient.invalidateQueries({
      queryKey: ['group-files', '', state.group.id],
    });
    navigateBack();
    displayGlobal({ text: t('groups.addFiles.success'), type: 'success' });
  };

  const content = useMemo(() => {
    if (isPending) {
      return (
        <div>
          <div className="flex h-72 items-center justify-center">
            <Loading />
          </div>
        </div>
      );
    }

    if (isError) {
      return (
        <div>
          <div className="flex h-72 flex-col items-center justify-center">
            <ErrorIcon />
            <p className="!mt-lg !mr-1 !text-lg">
              <Trans i18nKey="groups.addFiles.errorAdding">
                Oops! Files could not be added
                <span
                  className="cursor-pointer text-lg underline"
                  onClick={() => reset()}
                >
                  Try again?
                </span>
              </Trans>
            </p>
            <ErrorHelpReport />
          </div>
        </div>
      );
    }

    return <GroupAddFilesSelectFiles />;
  }, [isError, isPending, reset]);

  if (!state?.group) {
    return <></>;
  }

  return (
    <SelectedFilesContext.Provider value={selectedFiles}>
      <SelectedFilesDispatchContext.Provider value={dispatch}>
        <div className="flex flex-1">
          <div className="pt-screen flex w-full flex-1 flex-col overflow-auto overflow-x-hidden">
            <div className="px-screen flex items-center">
              <PressableIcon
                icon={<BackArrow title="back" className="text-black" />}
                onClick={handleGoBack}
              />
              <h1 className="ml-sm mr-20 text-3xl font-light">
                {t('groups.addFiles.title', {
                  groupName: state.group.name,
                })}
              </h1>
            </div>
            {content}
          </div>
          {selectedFiles.length > 0 ? (
            <SelectedFilesList
              onContinue={() => setIsConfirmationModalVisible(true)}
              onDeselectFile={handleDeselectFile}
            />
          ) : null}
        </div>
        <AddFilesConfirmationModal
          group={state.group}
          isOpen={isConfirmationModalVisible}
          onClose={() => setIsConfirmationModalVisible(false)}
          onSubmit={handleAddFiles}
        />
        <UnsavedWarningModal
          isOpen={isWarningModalVisible}
          onClose={() => setIsWarningModalVisible(false)}
          onDiscard={navigateBack}
        />
      </SelectedFilesDispatchContext.Provider>
    </SelectedFilesContext.Provider>
  );
};

export default GroupAddFiles;
