import {
  Box,
  Button,
  Chip,
  FormControl,
  Modal,
  Typography,
} from '@mui/material';
import { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import useAlert from '@app/hooks/useAlert';
import ErrorIcon from '@assets/organizations/error-bubble.svg?react';
import Close from '@assets/shared/close.svg?react';
import ColorInput from '@components/color-input';
import ErrorHelpReport from '@components/error-help-report';
import Loading from '@components/loading';
import PressableIcon from '@components/pressable-icon';

import useChangeTagColors from '../hooks/useChangeTagColor';
import { useTagContext } from '../hooks/useTagContext';

export interface TagNewColor {
  background: string;
  text: string;
}

interface TagDetailsChangeColorModalProps {
  isOpen: boolean;
  onClose: () => void;
  onUpdateColor: (colors: TagNewColor) => void;
}

const TagDetailsChangeColorModal = ({
  isOpen,
  onClose,
  onUpdateColor,
}: TagDetailsChangeColorModalProps) => {
  const { t } = useTranslation();
  const tag = useTagContext();
  const { handleSubmit, control, formState, watch } = useForm<TagNewColor>({
    defaultValues: {
      background: tag.backgroundColor,
      text: tag.textColor,
    },
  });

  const background = watch('background');
  const text = watch('text');
  const {
    mutateAsync: changeColors,
    isPending,
    isError,
    reset,
  } = useChangeTagColors();
  const { displayGlobal } = useAlert();

  const submitForm = useCallback(
    async (form: TagNewColor) => {
      await changeColors({ ...form, id: tag.id });
      onUpdateColor(form);
      displayGlobal({
        type: 'success',
        text: t('tags.tagDetails.changeColorModal.success'),
      });
      onClose();
    },
    [changeColors, displayGlobal, onClose, onUpdateColor, t, tag.id]
  );

  const content = useMemo(() => {
    if (isPending) {
      return (
        <div className="my-24 self-center">
          <Loading />
        </div>
      );
    }

    if (isError) {
      return (
        <div className="my-lg flex flex-col items-center">
          <ErrorIcon />
          <Typography className="!mt-md !text-lg !font-medium">
            <Trans i18nKey="tags.tagDetails.changeColorModal.error">
              Oops! There's was an error updating colors.
              <span className="cursor-pointer underline" onClick={reset}>
                Try again?
              </span>
            </Trans>
          </Typography>
          <ErrorHelpReport />
        </div>
      );
    }

    return (
      <>
        <Chip
          className="!mb-md !mt-sm !w-fit"
          sx={{ backgroundColor: background, color: text }}
          label={t('tags.tagDetails.changeColorModal.tag')}
        />
        <form onSubmit={handleSubmit(submitForm)}>
          <FormControl fullWidth className="!my-md">
            <Controller
              name="background"
              control={control}
              render={({ field }) => (
                <ColorInput
                  {...field}
                  variant="standard"
                  label={t('tags.tagDetails.changeColorModal.background')}
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth className="!my-md">
            <Controller
              name="text"
              control={control}
              render={({ field }) => (
                <ColorInput
                  {...field}
                  variant="standard"
                  label={t('tags.tagDetails.changeColorModal.text')}
                />
              )}
            />
          </FormControl>
          <div className="gap-x-sm flex justify-end">
            <Button
              variant="outlined"
              className="float-right"
              onClick={onClose}
            >
              {t('tags.tagDetails.changeColorModal.cancel')}
            </Button>
            <Button
              variant="contained"
              type="submit"
              className="float-right"
              disabled={!formState.isDirty}
            >
              {t('tags.tagDetails.changeColorModal.apply')}
            </Button>
          </div>
        </form>
      </>
    );
  }, [
    background,
    control,
    formState.isDirty,
    handleSubmit,
    isError,
    isPending,
    onClose,
    reset,
    submitForm,
    t,
    text,
  ]);

  return (
    <Modal className="flex" open={isOpen} onClose={onClose}>
      <Box className="w-medium-modal p-screen mx-auto my-auto !flex flex-col rounded bg-white shadow-md">
        <PressableIcon
          onClick={onClose}
          icon={<Close title="close" />}
          className="!absolute self-end !p-0"
        />
        <Typography className="!text-2xl !font-medium">
          {t('tags.tagDetails.changeColorModal.title')}
        </Typography>
        {content}
      </Box>
    </Modal>
  );
};

export default TagDetailsChangeColorModal;
