import { Typography } from '@mui/material';
import { PropsWithChildren, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import useUser from '@app/hooks/useUser';
import { getOnlyPath } from '@app/utils/files';

import { FolderDetailsContext } from './folder-details';

const InfoTitle = ({ children }: PropsWithChildren) => (
  <Typography className="!mt-sm !text-sm !font-bold">{children}</Typography>
);
const InfoValue = ({ children }: PropsWithChildren) => (
  <Typography className="!max-w-full overflow-hidden overflow-ellipsis !text-sm">
    {children}
  </Typography>
);

const FolderDetailsInfo = () => {
  const { t } = useTranslation();
  const details = useContext(FolderDetailsContext)!;
  const { data } = useUser(details.owner);

  return (
    <div className="mb-md">
      <Typography>{t('fileList.folderDetails.info.title')}</Typography>
      <InfoTitle>{t('fileList.folderDetails.info.owner')}</InfoTitle>
      <InfoValue>{data?.name ?? details.owner}</InfoValue>
      <InfoTitle>{t('fileList.folderDetails.info.location')}</InfoTitle>
      <Link
        className="!max-w-full overflow-hidden overflow-ellipsis !text-sm"
        to={`/files${getOnlyPath(details.path)}`}
      >
        <InfoValue>{getOnlyPath(details.path)}</InfoValue>
      </Link>
    </div>
  );
};

export default FolderDetailsInfo;
