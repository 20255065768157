import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import useAlert from '@app/hooks/useAlert';
import useAppJoyride from '@app/hooks/useAppJoyride';
import { useAppLocation } from '@app/hooks/useAppLocation';
import useErrorHandler from '@app/hooks/useErrorHandler';
import useSafeValue from '@app/hooks/useSafeValues';
import { Tag } from '@app/services/files/types';
import { TagWithFiles } from '@app/services/tags/types';
import EditableText from '@components/editable-text';
import FileDetails from '@pages/files/components/file-details';
import TagsEditorModal from '@pages/files/components/tags-editor-modal';
import useEditTags from '@pages/files/hooks/useEditTags';
import useRemoveTag from '@pages/files/hooks/useRemoveTag';

import { TagNewColor } from './components/tag-details-change-color-modal';
import TagDetailsFilesTable from './components/tag-details-files-table';
import TagDetailsHeader from './components/tag-details-header';
import TagsDangerZone from './components/tags-danger-zone';
import useEditTag from './hooks/useEditTag';
import { TagContext } from './hooks/useTagContext';

const TagDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useAppLocation<{ tag: TagWithFiles }>();
  const { mutateAsync: editTag, isPending } = useEditTag();
  const {
    currentValue,
    resetToSafeValue,
    updateCurrentValue,
    updateSafeValue,
  } = useSafeValue(state?.tag.description);
  const [fileDetailId, setFileDetailId] = useState<string | null>(null);
  const [isTagEditorOpen, setIsTagEditorOpen] = useState(false);
  const deleteTagMutation = useRemoveTag();
  const {
    mutateAsync: editTags,
    isError: isEditTagError,
    reset: resetEditTags,
  } = useEditTags();
  const { display: displayEditTagsAlert, Alert: AlertEditTags } = useAlert();
  useErrorHandler(isEditTagError, {
    callback: () => {
      displayEditTagsAlert();
      resetEditTags();
    },
  });
  const [tag, setTag] = useState(state?.tag);

  const [page, setPage] = useState(0);
  const { updateStageConfig, pause } = useAppJoyride();
  updateStageConfig(6, 4, {
    /* v8 ignore next 4 */
    onNext: () => {
      pause();
      navigate('/files');
    },
  });

  const handleBack = () => {
    navigate(-1);
  };

  const handleEdit = useCallback(
    async ({ value }: { value: string }) => {
      if (!state?.tag) {
        return;
      }
      updateCurrentValue(value);
      try {
        await editTag({ description: value, id: state.tag.id });
        updateSafeValue(value);
      } catch (error) {
        resetToSafeValue();
      }
    },
    [editTag, resetToSafeValue, updateCurrentValue, updateSafeValue, state?.tag]
  );

  const handleAddTag = () => {
    setTimeout(() => {
      setIsTagEditorOpen(true);
    }, 200);
  };

  const handleEditTags = async ({
    tags: newTags,
    fileId,
  }: {
    fileId: string;
    tags: Tag[];
  }) => {
    setIsTagEditorOpen(false);
    await editTags({ tagNames: newTags.map((newTag) => newTag.name), fileId });
  };

  const handleUpdateColor = ({ background, text }: TagNewColor) => {
    setTag((currentTag) =>
      currentTag
        ? {
            ...currentTag,
            backgroundColor: background,
            textColor: text,
          }
        : undefined
    );
  };

  if (!tag) {
    return <></>;
  }

  return (
    <>
      <TagContext.Provider value={tag}>
        <div
          className="flex flex-1 flex-col overflow-auto"
          data-tour="tags-step-5"
        >
          <TagDetailsHeader
            onBack={handleBack}
            onUpdateColor={handleUpdateColor}
          />
          <div className="p-screen flex flex-col">
            <EditableText
              name="description"
              placeholder={t('tags.tagDetails.description')}
              multiline
              maxRows={4}
              readOnly={isPending}
              className="!m-0 !w-[min(100%,60rem)] !p-0 !font-normal"
              defaultValue={currentValue ?? ''}
              onSubmit={handleEdit}
            />
            <div className="mt-lg">
              <TagDetailsFilesTable
                fileIds={tag.files}
                isPlaceholderData={false}
                onPageChange={setPage}
                page={page}
                total={5}
                onFileClick={(id) => setFileDetailId(id)}
              />
            </div>
            <TagsDangerZone />
          </div>
        </div>
      </TagContext.Provider>
      {fileDetailId ? (
        <FileDetails
          key={fileDetailId}
          id={fileDetailId}
          onClose={() => setFileDetailId(null)}
          deleteTagControls={deleteTagMutation}
          onAddTag={handleAddTag}
        />
      ) : null}
      {fileDetailId ? (
        <>
          <TagsEditorModal
            isOpen={isTagEditorOpen}
            onClose={() => setIsTagEditorOpen(false)}
            fileId={fileDetailId}
            onSubmit={({ tags: newTags }) =>
              handleEditTags({ fileId: fileDetailId, tags: newTags })
            }
          />
        </>
      ) : null}
      <AlertEditTags severity="error">
        {t('fileList.fileDetails.tagsEditorModal.error')}
      </AlertEditTags>
    </>
  );
};

export default TagDetails;
