import {
  TextField,
  MenuItem,
  FormControl,
  Select,
  Button,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AdvancedSearchFormInputsFormatted } from '@app/services/files/types';
import TagsInput from '@components/tags-input';
import useTags from '@pages/tags/hooks/useTags';

interface AdvancedSearchFormProps {
  onSubmit: SubmitHandler<AdvancedSearchFormInputsFormatted>;
}

const AdvancedSearchForm = ({ onSubmit }: AdvancedSearchFormProps) => {
  const { t } = useTranslation();
  const { handleSubmit, control, formState } =
    useForm<AdvancedSearchFormInputsFormatted>({
      defaultValues: {
        fileName: '',
        after: null,
        before: null,
        path: '',
        type: 'any',
        tags: [],
      },
    });
  const { data } = useTags({ page: 0, rowsPerPage: 10 });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-1 flex-col justify-between"
    >
      <div className="gap-lg grid grid-cols-4">
        <Typography className="row-start-1 row-end-2 !my-auto">
          {t('layout.header.advancedSearch.type')}
        </Typography>
        <FormControl
          fullWidth
          className="col-start-2 col-end-4 row-start-1 row-end-2 flex justify-center"
        >
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <Select {...field} variant="standard">
                <MenuItem value="any">
                  {t('layout.header.advancedSearch.options.types.any')}
                </MenuItem>
                <MenuItem value="pdf">PDF</MenuItem>
                <MenuItem value="xml">XML</MenuItem>
              </Select>
            )}
          />
        </FormControl>
        <Typography className="row-start-2 row-end-3 !my-auto">
          {t('layout.header.advancedSearch.fileName')}
        </Typography>
        <Controller
          name="fileName"
          control={control}
          render={({ field }) => (
            <TextField
              variant="standard"
              fullWidth
              {...field}
              placeholder={t(
                'layout.header.advancedSearch.placeholders.fileName'
              )}
              className="col-start-2 col-end-4 row-start-2 row-end-3 flex justify-center"
            />
          )}
        />
        <Typography className="row-start-3 row-end-4 !my-auto">
          {t('layout.header.advancedSearch.tags')}
        </Typography>
        <FormControl
          fullWidth
          className="col-start-2 col-end-4 row-start-3 row-end-4"
        >
          <Controller
            name="tags"
            control={control}
            render={({ field }) => (
              <TagsInput
                onlySearch
                options={data?.data ?? []}
                {...field}
                onChange={(value) =>
                  field.onChange(value.map((item) => item.id))
                }
                value={
                  data?.data.filter((tag) => field.value.includes(tag.id)) ?? []
                }
                tagClass="text-sm"
                inputClass="text-md px-0"
              />
            )}
          />
        </FormControl>
        <Typography className="row-start-4 row-end-5 !my-auto">
          {t('layout.header.advancedSearch.path')}
        </Typography>
        <div className="col-start-2 col-end-4 row-start-4 row-end-5">
          <FormControl
            fullWidth
            className="col-start-2 col-end-4 flex justify-center"
          >
            <Controller
              name="path"
              control={control}
              render={({ field }) => (
                <TextField
                  variant="standard"
                  fullWidth
                  {...field}
                  placeholder={t(
                    'layout.header.advancedSearch.placeholders.path'
                  )}
                  className="col-start-2 col-end-4 row-start-3 row-end-4 flex justify-center !text-sm"
                />
              )}
            />
          </FormControl>
        </div>
        <Typography className="row-start-5 row-end-6 !my-auto">
          {t('layout.header.advancedSearch.modificationDate')}
        </Typography>
        <div className="gap-x-md col-start-2 col-end-4 flex flex-1">
          <div className="col-start-2 col-end-3 row-start-5 row-end-6 flex flex-1 justify-center">
            <Controller
              name="after"
              control={control}
              rules={{
                validate: (value) =>
                  value === null ||
                  (value.isValid &&
                    value.diffNow('days').days < 0 &&
                    value.year > 1899),
              }}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DatePicker
                    slotProps={{
                      textField: {
                        variant: 'standard',
                      },
                    }}
                    {...field}
                    disableFuture
                    label={t(
                      'layout.header.advancedSearch.placeholders.dates.after'
                    )}
                    className="flex-1"
                  />
                </LocalizationProvider>
              )}
            />
          </div>
          <div className="col-start-3 col-end-4 row-start-5 row-end-6 flex flex-1 justify-center">
            <Controller
              name="before"
              control={control}
              rules={{
                validate: (value) =>
                  value === null ||
                  (value.isValid &&
                    value.diffNow('days').days < 0 &&
                    value.year > 1899),
              }}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DatePicker
                    slotProps={{
                      textField: {
                        variant: 'standard',
                      },
                    }}
                    {...field}
                    disableFuture
                    label={t(
                      'layout.header.advancedSearch.placeholders.dates.before'
                    )}
                    className="flex-1"
                  />
                </LocalizationProvider>
              )}
            />
          </div>
        </div>
      </div>
      <Button
        variant="contained"
        color="primary"
        className="float-right self-end"
        type="submit"
        disabled={!formState.isValid}
      >
        {t('layout.header.advancedSearch.searchButton')}
      </Button>
    </form>
  );
};

export default AdvancedSearchForm;
