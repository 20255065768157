import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';

import { Group, PermissionLevel } from '@app/services/groups/types';

interface UseGroupPermissionsByUserOptions {
  group: Group;
  userPermission?: undefined;
  memberPermission?: undefined;
}

interface UseGroupPermissionsByGroupOptions {
  group?: undefined;
  userPermission: PermissionLevel;
  memberPermission: PermissionLevel;
}

type UseGroupPermissionsOptions =
  | UseGroupPermissionsByUserOptions
  | UseGroupPermissionsByGroupOptions;

const useGroupPermissions = ({
  group,
  memberPermission,
  userPermission,
}: UseGroupPermissionsOptions) => {
  const { user } = useAuth0();
  let currentUserPermission = userPermission;
  if (!userPermission && user?.sub) {
    currentUserPermission = group.users[user.sub];
  }

  const canManageMembers = useCallback(() => {
    if (currentUserPermission === PermissionLevel.OWNER) {
      return memberPermission !== PermissionLevel.OWNER;
    }
    switch (memberPermission) {
      case PermissionLevel.OWNER:
        return false;
      case PermissionLevel.ADMIN:
        return false;
      case PermissionLevel.MANAGER:
        return currentUserPermission === PermissionLevel.ADMIN;
      default:
        return (
          currentUserPermission === PermissionLevel.ADMIN ||
          currentUserPermission === PermissionLevel.MANAGER
        );
    }
  }, [memberPermission, currentUserPermission]);

  const canDeleteGroup = useCallback(
    () => currentUserPermission === PermissionLevel.OWNER,
    [currentUserPermission]
  );

  const canEditGroup = useCallback(
    () =>
      currentUserPermission === PermissionLevel.OWNER ||
      currentUserPermission === PermissionLevel.ADMIN ||
      currentUserPermission === PermissionLevel.MANAGER,
    [currentUserPermission]
  );

  const canEditFiles = useCallback(
    () =>
      currentUserPermission === PermissionLevel.OWNER ||
      currentUserPermission === PermissionLevel.ADMIN ||
      currentUserPermission === PermissionLevel.MANAGER,
    [currentUserPermission]
  );

  const canAddFiles = useCallback(
    () =>
      currentUserPermission === PermissionLevel.OWNER ||
      currentUserPermission === PermissionLevel.ADMIN ||
      currentUserPermission === PermissionLevel.MANAGER,
    [currentUserPermission]
  );

  const canReadNotes = useCallback(
    () =>
      currentUserPermission === PermissionLevel.OWNER ||
      currentUserPermission === PermissionLevel.ADMIN ||
      currentUserPermission === PermissionLevel.MANAGER ||
      currentUserPermission === PermissionLevel.REVIEWER,
    [currentUserPermission]
  );

  const canWriteNotes = useCallback(
    () =>
      currentUserPermission === PermissionLevel.OWNER ||
      currentUserPermission === PermissionLevel.REVIEWER,
    [currentUserPermission]
  );

  const canTransferOwnership = useCallback(
    () => currentUserPermission === PermissionLevel.OWNER,
    [currentUserPermission]
  );

  return {
    canManageMembers,
    canDeleteGroup,
    canEditGroup,
    canAddFiles,
    canEditFiles,
    canReadNotes,
    canWriteNotes,
    canTransferOwnership,
  };
};

export default useGroupPermissions;
